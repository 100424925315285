import React, {useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'

const MerchantListPage_EditMerchantFee: React.FC = () => {
  const navigate = useNavigate()
  const location: any = useLocation()
  const [loading, setLoading] = useState(false)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  const initialValues = {
    id: String(location.state.id),
    merchantName: String(location.state.merchantName),
    merchantCd: String(location.state.merchantCd),
    status: String(location.state.status).toLowerCase(),
    withdrawalSettFeeType: String(location.state.withdrawalSettFeeType),
    withdrawalSettFeeValue: String(location.state.withdrawalSettFeeVal),
    depositSettFeeType: String(location.state.depositSettFeeType),
    depositSettFeeValue: String(location.state.depositSettFeeVal),
    custWithdrawalFeeType: String(location.state.custWithdrawalFeeType),
    custWithdrawalFeeValue: String(location.state.custWithdrawalFeeVal),
    custMinWithdrawal: String(location.state.custMinWithdrawal),
    custMaxWithdrawal: String(location.state.custMaxWithdrawal),
    custDepositFeeType: String(location.state.custDepositFeeType),
    custDepositFeeValue: String(location.state.custDepositFeeVal),
    custMinDeposit: String(location.state.custMinDeposit),
    custMaxDeposit: String(location.state.custMaxDeposit),
  }

  const addUserSchema = Yup.object().shape({
    merchantCd: Yup.string().max(50, 'Maximum 50 symbols').required('Merchant Name is required'),
    status: Yup.string().max(50, 'Maximum 50 symbols').required('Status is required'),
    withdrawalSettFeeType: Yup.string().required('Withdrawal Fee Type is required'),
    withdrawalSettFeeValue: Yup.string()
      .matches(/^[0-9]+(\.[0-9][0-9]?)?$/, 'Maximum 2 decimals')
      .required('Wtihdrawal Fee Value is required'),
    depositSettFeeType: Yup.string().required('Deposit Fee Type is required'),
    depositSettFeeValue: Yup.string()
      .matches(/^[0-9]+(\.[0-9][0-9]?)?$/, 'Maximum 2 decimals')
      .required('Deposit Fee Value is required'),
    custWithdrawalFeeType: Yup.string().required('Withdrawal Fee Type is required'),
    custWithdrawalFeeValue: Yup.string()
      .matches(/^[0-9]+(\.[0-9][0-9]?)?$/, 'Maximum 2 decimals')
      .required('Customer Withdrawal Fee Value is required'),
    custMinWithdrawal: Yup.string()
      .matches(/^[0-9]+(\.[0-9][0-9]?)?$/, 'Maximum 2 decimals')
      .required('Customer Minimum Withdrawal is required'),
    custMaxWithdrawal: Yup.string()
      .matches(/^[0-9]+(\.[0-9][0-9]?)?$/, 'Maximum 2 decimals')
      .required('Customer Maximum Withdrawal is required'),
    custDepositFeeType: Yup.string()
      .max(50, 'Maximum 50 symbols')
      .required('Withdrawal Fee Type is required'),
    custDepositFeeValue: Yup.string()
      .matches(/^[0-9]+(\.[0-9][0-9]?)?$/, 'Maximum 2 decimals')
      .required('Customer Deposit Fee Value is required'),
    custMinDeposit: Yup.string()
      .matches(/^[0-9]+(\.[0-9][0-9]?)?$/, 'Maximum 2 decimals')
      .required('Customer Minimum Deposit is required'),
    custMaxDeposit: Yup.string()
      .matches(/^[0-9]+(\.[0-9][0-9]?)?$/, 'Maximum 2 decimals')
      .required('Customer Maximum Deposit is required'),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        axios
          .post(
            `${SISPAY_API}/merchant/fee/update`,
            {
              request: {
                id: values.id,
                merchantCd: values.merchantCd,
                status: values.status,
                withdrawalSettFeeType: values.withdrawalSettFeeType,
                withdrawalSettFeeValue: values.withdrawalSettFeeValue,
                depositSettFeeType: values.depositSettFeeType,
                depositSettFeeValue: values.depositSettFeeValue,
                custWithdrawalFeeType: values.custWithdrawalFeeType,
                custWithdrawalFeeValue: values.custWithdrawalFeeValue,
                custMinWithdrawal: values.custMinWithdrawal,
                custMaxWithdrawal: values.custMaxWithdrawal,
                custDepositFeeType: values.custDepositFeeType,
                custDepositFeeValue: values.custDepositFeeValue,
                custMinDeposit: values.custMinDeposit,
                custMaxDeposit: values.custMaxDeposit,
                session: session,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response) {
            setLoading(false)
            navigate(-1)
            alert('Mechant Fees Updated!')
          })
          .catch(function (error) {})
      } catch (error) {}
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
        <span className='text-gray-800 fs-1 fw-bolder ms-5 d-flex align-items-center'>
          Edit Merchant Fee : {String(location.state.merchantCd)}
        </span>
      </div>{' '}
      <form onSubmit={formik.handleSubmit} className='m-10'>
        <div className='card card-custom card-flush mb-5'>
          <div className='card-header'>
            <h3 className='card-title'>Basic Information</h3>
          </div>
          <div className='card-body py-5'>
            {/* begin::Form group Merchant Name */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>Merchant Name</label>
              <input
                placeholder='Enter Merchant Name'
                type='text'
                autoComplete='off'
                disabled={true}
                {...formik.getFieldProps('merchantName')}
                className={clsx(
                  'form-control ',
                  {
                    'is-invalid': formik.touched.merchantName && formik.errors.merchantName,
                  },
                  {
                    'is-valid': formik.touched.merchantName && !formik.errors.merchantName,
                  }
                )}
              />
              {formik.touched.merchantName && formik.errors.merchantName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.merchantName}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
            {/* begin::Form group Merchant Code */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>Merchant Code</label>
              <input
                placeholder='Enter Merchant Code'
                type='text'
                autoComplete='off'
                disabled={true}
                {...formik.getFieldProps('merchantCd')}
                className={clsx(
                  'form-control ',
                  {
                    'is-invalid': formik.touched.merchantCd && formik.errors.merchantCd,
                  },
                  {
                    'is-valid': formik.touched.merchantCd && !formik.errors.merchantCd,
                  }
                )}
              />
              {formik.touched.merchantCd && formik.errors.merchantCd && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.merchantCd}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
            {/* begin::Form group Status */}
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6 required'>Status</label>
              <select
                {...formik.getFieldProps('status')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.status && formik.errors.status,
                  },
                  {
                    'is-valid': formik.touched.status && !formik.errors.status,
                  }
                )}
                data-placeholder=''
              >
                <option value=''>Please Select</option>
                <option value='active'>Active</option>
                <option value='inactive'>Inactive</option>
              </select>
            </div>{' '}
            {/* end::Form group */}
          </div>
        </div>
        <div className='card card-custom card-flush mb-5'>
          <div className='card-header'>
            <h3 className='card-title'>Withdrawal</h3>
          </div>
          <div className='card-body py-5'>
            <div className='row'>
              <div className='col'>
                {/* begin::Form group Withdrawal Fee Type */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6 required'>
                    Withdrawal Fee Type
                  </label>
                  <select
                    {...formik.getFieldProps('withdrawalSettFeeType')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.withdrawalSettFeeType &&
                          formik.errors.withdrawalSettFeeType,
                      },
                      {
                        'is-valid':
                          formik.touched.withdrawalSettFeeType &&
                          !formik.errors.withdrawalSettFeeType,
                      }
                    )}
                    data-placeholder=''
                  >
                    <option value=''>Please Select</option>
                    <option value='percentage'>Percentage(%)</option>
                    <option value='fixed'>Fixed</option>
                  </select>
                </div>{' '}
                {/* end::Form group */}
              </div>
              <div className='col'>
                {/* begin::Form group Withdrawal Fee Value */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    Withdrawal Fee Value
                  </label>
                  <input
                    placeholder='Enter Withdrawal Fee Value'
                    type='number'
                    onWheel={(event) => event.currentTarget.blur()}
                    autoComplete='off'
                    {...formik.getFieldProps('withdrawalSettFeeValue')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.withdrawalSettFeeValue &&
                          formik.errors.withdrawalSettFeeValue,
                      },
                      {
                        'is-valid':
                          formik.touched.withdrawalSettFeeValue &&
                          !formik.errors.withdrawalSettFeeValue,
                      }
                    )}
                  />
                  {formik.touched.withdrawalSettFeeValue && formik.errors.withdrawalSettFeeValue && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.withdrawalSettFeeValue}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </div>
            </div>
          </div>
        </div>
        <div className='card card-custom card-flush mb-5'>
          <div className='card-header'>
            <h3 className='card-title'>Deposit</h3>
          </div>
          <div className='card-body py-5'>
            <div className='row'>
              <div className='col'>
                {/* begin::Form group Deposit Fee Type */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6 required'>
                    Deposit Fee Type
                  </label>
                  <select
                    {...formik.getFieldProps('depositSettFeeType')}
                    className={clsx('form-control bg-transparent')}
                    data-placeholder=''
                  >
                    <option value=''>Please Select</option>
                    <option value='percentage'>Percentage(%)</option>
                    <option value='fixed'>Fixed</option>
                  </select>
                </div>{' '}
                {/* end::Form group */}
              </div>
              <div className='col'>
                {/* begin::Form group Deposit Fee Value */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>Deposit Fee Value</label>
                  <input
                    placeholder='Enter Deposit Fee Value'
                    type='number'
                    onWheel={(event) => event.currentTarget.blur()}
                    autoComplete='off'
                    {...formik.getFieldProps('depositSettFeeValue')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.depositSettFeeValue && formik.errors.depositSettFeeValue,
                      },
                      {
                        'is-valid':
                          formik.touched.depositSettFeeValue && !formik.errors.depositSettFeeValue,
                      }
                    )}
                  />
                  {formik.touched.depositSettFeeValue && formik.errors.depositSettFeeValue && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.depositSettFeeValue}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </div>
            </div>
          </div>
        </div>
        <div className='card card-custom card-flush mb-5'>
          <div className='card-header'>
            <h3 className='card-title'>Customer Withdrawal</h3>
          </div>
          <div className='card-body py-5'>
            <div className='row'>
              <div className='col'>
                {/* begin::Form group Customer Withdrawal Fee Type */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6 required'>
                    Customer Withdrawal Fee Type
                  </label>
                  <select
                    {...formik.getFieldProps('custWithdrawalFeeType')}
                    className={clsx('form-control bg-transparent')}
                    data-placeholder=''
                  >
                    <option value=''>Please Select</option>
                    <option value='percentage'>Percentage(%)</option>
                    <option value='fixed'>Fixed</option>
                  </select>
                </div>{' '}
                {/* end::Form group */}
              </div>
              <div className='col'>
                {' '}
                {/* begin::Form group Customer Withdrawal Fee Value */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    Customer Withdrawal Fee Value
                  </label>
                  <input
                    placeholder='Enter Customer Withdrawal Fee Value'
                    type='number'
                    onWheel={(event) => event.currentTarget.blur()}
                    autoComplete='off'
                    {...formik.getFieldProps('custWithdrawalFeeValue')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.custWithdrawalFeeValue &&
                          formik.errors.custWithdrawalFeeValue,
                      },
                      {
                        'is-valid':
                          formik.touched.custWithdrawalFeeValue &&
                          !formik.errors.custWithdrawalFeeValue,
                      }
                    )}
                  />
                  {formik.touched.custWithdrawalFeeValue && formik.errors.custWithdrawalFeeValue && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.custWithdrawalFeeValue}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </div>
              <div className='col'>
                {/* begin::Form group Customer Withdrawal Fee Value */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    Customer Minimum Withdrawal
                  </label>
                  <input
                    placeholder='Enter Customer Minimum Withdrawal'
                    type='number'
                    onWheel={(event) => event.currentTarget.blur()}
                    autoComplete='off'
                    {...formik.getFieldProps('custMinWithdrawal')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.custMinWithdrawal && formik.errors.custMinWithdrawal,
                      },
                      {
                        'is-valid':
                          formik.touched.custMinWithdrawal && !formik.errors.custMinWithdrawal,
                      }
                    )}
                  />
                  {formik.touched.custMinWithdrawal && formik.errors.custMinWithdrawal && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.custMinWithdrawal}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </div>
              <div className='col'>
                {/* begin::Form group Customer Withdrawal Fee Value */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    Customer Maximum Withdrawal
                  </label>
                  <input
                    placeholder='Enter Customer Maximum Withdrawal'
                    type='number'
                    onWheel={(event) => event.currentTarget.blur()}
                    autoComplete='off'
                    {...formik.getFieldProps('custMaxWithdrawal')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.custMaxWithdrawal && formik.errors.custMaxWithdrawal,
                      },
                      {
                        'is-valid':
                          formik.touched.custMaxWithdrawal && !formik.errors.custMaxWithdrawal,
                      }
                    )}
                  />
                  {formik.touched.custMaxWithdrawal && formik.errors.custMaxWithdrawal && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.custMaxWithdrawal}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </div>
            </div>
          </div>
        </div>
        <div className='card card-custom card-flush mb-5'>
          <div className='card-header'>
            <h3 className='card-title'>Customer Deposit</h3>
          </div>
          <div className='card-body py-5'>
            <div className='row'>
              <div className='col'>
                {/* begin::Form group Customer Deposit Fee Type */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6 required'>
                    Customer Deposit Fee Type
                  </label>
                  <select
                    {...formik.getFieldProps('custDepositFeeType')}
                    className={clsx('form-control bg-transparent')}
                    data-placeholder=''
                  >
                    <option value=''>Please Select</option>
                    <option value='percentage'>Percentage(%)</option>
                    <option value='fixed'>Fixed</option>
                  </select>
                </div>{' '}
                {/* end::Form group */}
              </div>
              <div className='col'>
                {/* begin::Form group Customer Withdrawal Fee Value */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    Customer Deposit Fee Value
                  </label>
                  <input
                    placeholder='Enter Customer Deposit Fee Value'
                    type='number'
                    onWheel={(event) => event.currentTarget.blur()}
                    autoComplete='off'
                    {...formik.getFieldProps('custDepositFeeValue')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.custDepositFeeValue && formik.errors.custDepositFeeValue,
                      },
                      {
                        'is-valid':
                          formik.touched.custDepositFeeValue && !formik.errors.custDepositFeeValue,
                      }
                    )}
                  />
                  {formik.touched.custDepositFeeValue && formik.errors.custDepositFeeValue && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.custDepositFeeValue}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </div>
              <div className='col'>
                {/* begin::Form group Customer Minimum Deposit */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    Customer Minimum Deposit
                  </label>
                  <input
                    placeholder='Enter Customer Minimum Deposit'
                    type='number'
                    onWheel={(event) => event.currentTarget.blur()}
                    autoComplete='off'
                    {...formik.getFieldProps('custMinDeposit')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.custMinDeposit && formik.errors.custMinDeposit,
                      },
                      {
                        'is-valid': formik.touched.custMinDeposit && !formik.errors.custMinDeposit,
                      }
                    )}
                  />
                  {formik.touched.custMinDeposit && formik.errors.custMinDeposit && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.custMinDeposit}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </div>
              <div className='col'>
                {/* begin::Form group*/}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    Customer Maximum Deposit
                  </label>
                  <input
                    placeholder='Enter Customer Maximum Deposit'
                    type='number'
                    onWheel={(event) => event.currentTarget.blur()}
                    autoComplete='off'
                    {...formik.getFieldProps('custMaxDeposit')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.custMaxDeposit && formik.errors.custMaxDeposit,
                      },
                      {
                        'is-valid': formik.touched.custMaxDeposit && !formik.errors.custMaxDeposit,
                      }
                    )}
                  />
                  {formik.touched.custMaxDeposit && formik.errors.custMaxDeposit && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.custMaxDeposit}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
              </div>
            </div>
          </div>
        </div>

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </div>
  )
}

export default MerchantListPage_EditMerchantFee
