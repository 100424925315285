export function ErrorHandling(response: any, logout: any) {
  let errorCode = response.data.code
  let errorMessage = response.data.message

  if (errorCode == 499) {
    logout()
    window.location.href = '/auth'
    alert(errorMessage)
  } else {
    alert(errorMessage)
  }
}
