import React, {useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'

const WalletListPage_Edit: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  let state: any = location.state

  const initialValues = {
    walletAccName: String(state.walletAccName),
    walletAccNo: String(state.walletAccNo),
    walletCd: String(state.walletCd),
    alive: String(state.alive),
    agentName: String(state.agentName),
    merchantName: String(state.merchantName),
  }

  const addUserSchema = Yup.object().shape({})

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        axios
          .post(
            `${SISPAY_API}/wallet/activity/update`,
            {
              request: {
                id: String(state.id),
                isAlive: values.alive,
                session: session,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response) {
            setLoading(false)
            navigate(-1)
            alert('Wallet Activity Updated!')
          })
          .catch(function (error) {})
      } catch (error) {}
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
        <span className='text-gray-800 fs-1 fw-bolder ms-5 d-flex align-items-center'>
          Edit Wallet Activity
        </span>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group Wallet Account Name */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Wallet Account Name
            </label>
            <input
              placeholder='Enter your Wallet Account Name'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('walletAccName')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletAccName && formik.errors.walletAccName,
                },
                {
                  'is-valid': formik.touched.walletAccName && !formik.errors.walletAccName,
                }
              )}
            />
            {formik.touched.walletAccName && formik.errors.walletAccName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account Number */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Wallet Account Number
            </label>
            <input
              placeholder='Enter your Wallet Account Number'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('walletAccNo')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletAccNo && formik.errors.walletAccNo,
                },
                {
                  'is-valid': formik.touched.walletAccNo && !formik.errors.walletAccNo,
                }
              )}
            />
            {formik.touched.walletAccNo && formik.errors.walletAccNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Code */}
          <div className='fv-row mb-5'>
            <label className='form-label required fw-bolder text-dark fs-6'>Wallet Code</label>
            <input
              type='text'
              placeholder='Enter your wallet code'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('walletCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletCd && formik.errors.walletCd,
                },
                {
                  'is-valid': formik.touched.walletCd && !formik.errors.walletCd,
                }
              )}
            />
            {formik.touched.walletCd && formik.errors.walletCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Alive */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Alive</label>
            <select
              {...formik.getFieldProps('alive')}
              className={clsx(
                'form-control bg-transaparent',
                {
                  'is-invalid': formik.touched.alive && formik.errors.alive,
                },
                {
                  'is-valid': formik.touched.alive && !formik.errors.alive,
                }
              )}
              data-placeholder=''
            >
              <option value='false'>False</option>
              <option value='true'>True</option>
            </select>
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group Merchant Name */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Merchant Name</label>
            <input
              placeholder={formik.values.agentName == '' ? 'EMPTY' : 'Enter Merchant Name'}
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('merchantName')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.merchantName && formik.errors.merchantName,
                },
                {
                  'is-valid': formik.touched.merchantName && !formik.errors.merchantName,
                }
              )}
            />
            {formik.touched.merchantName && formik.errors.merchantName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.merchantName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Agent Name */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Agent Name</label>
            <input
              placeholder={formik.values.agentName == '' ? 'EMPTY' : 'Enter Agent Name'}
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('agentName')}
              className={clsx(
                'form-control bg-grey',
                {'is-invalid': formik.touched.agentName && formik.errors.agentName},
                {
                  'is-valid': formik.touched.agentName && !formik.errors.agentName,
                }
              )}
            />
            {formik.touched.agentName && formik.errors.agentName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.agentName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            >
              {!loading && <span className='indicator-label'>Update</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}

export default WalletListPage_Edit
