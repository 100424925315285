import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import axios from 'axios'

const DepositManagementPage_MatchSMS: React.FC = () => {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  function triggerMatchingSMS() {
    axios
      .post(
        `${SISPAY_API}/cash/depositrequest/admin/matchsmsmaster`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response: any) {
        alert(response.data.message)
      })
      .catch(function (error) {
        alert(error)
      })
  }

  return (
    <div className='d-flex flex-column'>
      <h5 className='mb-5'>This will trigger the matching of PENDING deposits using SMS! </h5>
      <button
        type='button'
        className='btn btn-danger w-50'
        data-bs-toggle='modal'
        data-bs-target='#staticBackdrop'
      >
        Trigger Matching using SMS
      </button>

      <div
        className='modal fade'
        id='staticBackdrop'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                Trigger matching of PENDING deposits using SMS
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              Do you wish to trigger matching of PENDING deposits using SMS?
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                data-bs-dismiss='modal'
                className='btn btn-danger'
                onClick={() => {
                  triggerMatchingSMS()
                }}
              >
                Understood
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DepositManagementPage_MatchSMS
