import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {ErrorHandling} from '../../components/ErrorHandling'
import {useAuth} from '../../auth'
import {useRef, useState} from 'react'
import getTimestamp from '../../components/GetTimestamp'

export default function RefundModal(props: any) {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const navigate = useNavigate()
  const {logout} = useAuth()

  //Props
  let transData = props.transData

  //States
  const [loading, setLoading] = useState(false)
  const [srcWalletAccNo, setSrcWalletAccNo] = useState('')
  const [srcWalletAccName, setSrcWalletAccName] = useState('')

  function refundTransaction() {
    axios
      .post(
        `${SISPAY_API}/cash/transaction/admin/refund`,
        {
          request: {
            CustomerCode: transData.customerCd,
            BankCode: transData.walletCd,
            Currency: 'BDT',
            Amount: transData.amount.toString(),
            transId: transData.id.toString(),
            ResponseURL: 'http://www.google.com',
            ResultURL: 'http://www.google.com',
            TransactionTimeStamp: getTimestamp(),
            session: session,
            DestinationAccountNo:
              transData.walletCd == 'BKASH' ? srcWalletAccNo : transData.senderReceiver.toString(),
            DestinationAccountName: srcWalletAccName,
            walletAccNo: transData.walletAccNo.toString(),
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          setLoading(false)
          // navigate(-1)
          alert(response.data.message)
        } else {
          setLoading(false)
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  return (
    <div>
      <div
        className='modal fade'
        id='refundModal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                Refund Transaction
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-4'>ID</div>
                <div className='col-8 d-flex align-items-center'>{transData.id}</div>
              </div>
              <div className='separator separator-dashed border-secondary my-2'></div>
              <div className='row'>
                <div className='col-4'>Customer Code</div>
                <div className='col-8 d-flex align-items-center'>{transData.customerCd}</div>
              </div>{' '}
              <div className='separator separator-dashed border-white my-2'></div>
              <div className='row'>
                <div className='col-4'>Wallet Code</div>
                <div className='col-8 d-flex align-items-center'>{transData.walletCd}</div>
              </div>{' '}
              <div className='separator separator-dashed border-white my-2'></div>
              <div className='row'>
                <div className='col-4'>Amount</div>
                <div className='col-8 d-flex align-items-center'>{transData.amount}</div>
              </div>{' '}
              <div className='separator separator-dashed border-white my-2'></div>
              <div className='row'>
                <div className='col-4 required'>Destination Wallet Account No</div>
                {transData.walletCd == 'BKASH' ? (
                  <div className='col-8 d-flex align-items-center'>
                    <input
                      type='number'
                      className='form-control'
                      placeholder='Enter Destination Wallet Account No'
                      id='srcWalletAccNoInput'
                      onChange={(e) => {
                        setSrcWalletAccNo(e.target.value)
                      }}
                    />
                  </div>
                ) : (
                  <div className='col-8 d-flex align-items-center'>{transData.senderReceiver}</div>
                )}
              </div>{' '}
              <div className='separator separator-dashed border-white my-2'></div>
              <div className='row'>
                <div className='col-4'>Destination Wallet Account Name</div>
                <div className='col-8 d-flex align-items-center'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Destination Wallet Account Name'
                    id='srcWalletAccNameInput'
                    onChange={(e) => {
                      setSrcWalletAccName(e.target.value)
                    }}
                  />
                </div>
              </div>{' '}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => {
                  refundTransaction()
                }}
                disabled={transData.walletCd == 'BKASH' && srcWalletAccNo == '' ? true : false}
              >
                Refund
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
