import {create} from 'zustand'

export const useDetailedReportingStore = create((set) => ({
  //Data
  getData: false,
  filterOptions: {
    merchantCd: '',
    timeZone: 'GMT+6',
    basedOn: 'transCompleted',
    transType: 'ALL',
    dateTimeFrom: '',
    dateTimeTo: '',
  },

  //Functions
  setGetData: (value: boolean) => set({getData: value}),
  setFilterOptions: (value: any) => set({filterOptions: value}),
}))
