import React, {Ref, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {Filter} from './components/filter'
import {Column, useTable, useFlexLayout, useResizeColumns} from 'react-table'
import {Styles} from '../components/Table_Style'
import {OverlayTrigger} from 'react-bootstrap'
import popover from '../components/Popover'
import SMS_Export_CSV from './components/SMS_Export_CSV'
import {metricsArray} from '../components/MetricsArray'
import FormatNumber from '../components/FormatNumber'
import scrollToTop from '../components/ScrollToTop'

const SmsManagementPage: React.FC = () => {
  const [formData, setFormData] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [metrics, setMatrics] = useState<any>(undefined)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalFormData, setTotalFormData] = useState(0)
  const [filterValues, setFilterValues] = useState<any>([])
  const [downloadLoading, setDownloadLoading] = useState(false)

  console.log('Current Page', currentPage)

  useEffect(() => {
    let metrics = localStorage.getItem('smsMetrics')
    if (metrics) {
      setMatrics(JSON.parse(metrics))
    } else {
      setMatrics([])
    }
  }, [formData, showMetrics])

  //Table
  interface Data {
    smsId: string
    smsTxnDT: string
    receivedDT: string
    source: string
    walletAccName: string
    walletAccNo: string
    walletCd: string
    txnId: string
    senderReceiver: string
    serviceCenter: string
    amount: string
    smsBody: string
    smsTemplate: string
    txnFee: string
    smsBal: string
    walletBalance: string
    id: string
    balDiff: string
    smsType: string
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: 'Received DateTime',
        accessor: (row: any) => {
          return row.receivedDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        Header: 'SMS Transaction DateTime',
        accessor: (row: any) => {
          return row.smsTxnDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        Header: 'SMS Content',
        accessor: (row: any) => {
          return (
            <OverlayTrigger trigger='hover' placement='top' overlay={popover(row.smsBody)}>
              <div
                style={{
                  whiteSpace: 'normal',
                }}
              >
                {row.smsBody}
              </div>
            </OverlayTrigger>
          )
        },
        width: 280,
      },
      {
        Header: 'TxnID',
        accessor: 'txnId',
      },
      {
        Header: 'Amount',
        accessor: (row: any) => {
          return FormatNumber(row.amount)
        },
      },
      {
        Header: 'SMS Type',
        accessor: 'smsType',
      },
      {
        Header: 'Source',
        accessor: 'source',
      },
      {
        Header: 'Service Center',
        accessor: 'serviceCenter',
      },
      {
        Header: 'Wallet Account Name',
        accessor: 'walletAccName',
      },
      {
        Header: 'Wallet Account No',
        accessor: 'walletAccNo',
      },
      {
        Header: 'SMS ID',
        accessor: 'smsId',
      },
      {
        Header: 'SMS Balance',
        accessor: (row: any) => {
          return FormatNumber(row.smsBal)
        },
      },
      {
        Header: 'Wallet Balance',
        accessor: (row: any) => {
          return FormatNumber(row.walletBalance)
        },
      },
      {
        Header: 'Balance Different',
        accessor: (row: any) => {
          return FormatNumber(row.balDiff)
        },
      },
      {
        Header: 'Transaction Fee',
        accessor: (row: any) => {
          return FormatNumber(row.txnFee)
        },
      },

      {
        Header: 'SMS Template',
        accessor: 'smsTemplate',
      },
      {
        Header: 'Wallet Code',
        accessor: 'walletCd',
      },
      {
        Header: 'Sender/Receiver',
        accessor: 'senderReceiver',
      },
      {
        id: 'action',
        width: 250,
        Header: () => {
          return (
            <table className='m-0 p-0 w-100'>
              <tbody>
                <tr className='border-bottom'>
                  <td colSpan={2} className='text-center p-1'>
                    Action
                  </td>
                </tr>
                <tr>
                  <td className=' text-center w-50 p-1'>Request</td>
                  <td className=' text-center w-50 p-1'>Transaction</td>
                </tr>
              </tbody>
            </table>
          )
        },
        accessor: (formData: any) => {
          return (
            <table className='m-0 p-0 w-100'>
              <tbody>
                <tr className='d-flex flex-row'>
                  <td className='w-50 p-1 d-flex align-items-center justify-content-center'>
                    {' '}
                    {formData.reqId ? (
                      <td className='mx-2'>
                        <Link
                          to={'/request/' + formData.reqId}
                          //state={getRequestData(formData.requestID)}
                          className='btn btn-icon btn-bg-primary btn-active-color-primary'
                        >
                          {formData.reqId}
                        </Link>
                      </td>
                    ) : (
                      <td className='mx-2' style={{visibility: 'hidden'}}>
                        {' '}
                        <Link
                          to={'/request/' + formData.reqId}
                          className='btn btn-icon btn-bg-primary btn-active-color-primary'
                        >
                          {formData.reqId}
                        </Link>
                      </td>
                    )}
                  </td>
                  <td className=' text-center d-flex align-items-center justify-content-center w-50 p-1'>
                    {formData.transId ? (
                      <td className='mx-2'>
                        <Link
                          to={'/transaction/' + formData.transId}
                          state={formData}
                          className='btn btn-icon btn-bg-info btn-active-color-primary'
                        >
                          {formData.transId}
                        </Link>
                      </td>
                    ) : (
                      <td className='mx-2'>
                        <td style={{visibility: 'hidden'}}>
                          {' '}
                          <Link
                            to={'/requestManagement/' + formData.transId}
                            //state={getRequestData(formData.requestID)}
                            className='btn btn-icon btn-bg-primary btn-active-color-primary'
                          >
                            {/* <button className='btn btn-secondary'></button> */}
                            {formData.transId}
                          </Link>
                        </td>
                      </td>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          )
        },
      },
    ],
    []
  )
  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        //maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        initialState: {hiddenColumns: metrics ? metrics : []},
        defaultColumn,
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter(
                    (column) =>
                      column.Header !== ' ' && column.Header !== 'Action' && column.id !== 'action'
                  )
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex justify-content-center align-items-center m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            onClick={() => {
                              metricsArray('smsMetrics', column.id)
                            }}
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.Header)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        <div className='fv-row'>
          <button
            className='btn btn-info btn-sm fw-bold btn-color-white-700 w-100'
            disabled={formData.length > 0 && !downloadLoading ? false : true}
            onClick={() => {
              SMS_Export_CSV(filterValues, setDownloadLoading)
            }}
          >
            {!downloadLoading ? 'Download Content as CSV' : 'Downloading...Please Wait!'}
          </button>
        </div>
        <div className='m-0 d-flex'>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Filter
        setFormData={setFormData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalFormData={setTotalFormData}
        setFilterValues={setFilterValues}
        downloadLoading={downloadLoading}
      />
      <Styles>
        <Table columns={columns} data={formData == undefined ? [] : formData} />
      </Styles>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {totalFormData}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(currentPage - 1)
            scrollToTop()
          }}
        >
          Previous
        </button>
        {currentPage == 0 ? (
          <div>1 of {Math.ceil(totalFormData / 200)}</div>
        ) : (
          <div>
            {currentPage} of {Math.ceil(totalFormData / 200)}
          </div>
        )}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage >= Math.ceil(totalFormData / 200)}
          onClick={() => {
            if (currentPage == 0) {
              setCurrentPage(2)
            } else {
              setCurrentPage(currentPage + 1)
            }
            scrollToTop()
          }}
        >
          Next
        </button>
        <select
          onChange={(e) => setCurrentPage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: Math.ceil(totalFormData / 200)}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
      </div>
      {/* Pagination END */}
    </div>
  )
}

export default SmsManagementPage
