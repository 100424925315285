/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* User Management */}
      <SidebarMenuItem
        to='/user-management'
        icon='/media/icons/duotune/communication/com006.svg'
        title='User Management'
        hasBullet={false}
      />

      {/* Merchant Management */}
      <SidebarMenuItemWithSub
        to='/merchant'
        title='Merchant Management'
        icon='/media/icons/duotune/ecommerce/ecm004.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/merchant/list' title='Merchant List' hasBullet={true} />
        <SidebarMenuItem to='/merchant/fee' title='Merchant Fee' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* Wallet Management */}
      <SidebarMenuItemWithSub
        to='/wallet'
        title='Wallet Management'
        icon='/media/icons/duotune/finance/fin008.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/wallet/list' title='Wallet List' hasBullet={true} />
        <SidebarMenuItem to='/wallet/utilization' title='Wallet Utilization' hasBullet={true} />
        <SidebarMenuItem to='/wallet/activity' title='Wallet Activity' hasBullet={true} />
        <SidebarMenuItem to='/wallet/assignment/list' title='Wallet Assignment' hasBullet={true} />
        <SidebarMenuItem to='/wallet/health' title='Wallet Health Monitoring' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* SMS Management */}
      <SidebarMenuItem
        to='/sms/list'
        icon='/media/icons/duotune/communication/com007.svg'
        title='SMS Management'
        hasBullet={false}
      />

      {/* Request */}
      <SidebarMenuItemWithSub
        to='/request'
        title='Request Management'
        icon='/media/icons/duotune/general/gen005.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/request/pending' title='Pending Request' hasBullet={true} />
        <SidebarMenuItem to='/request/list' title='Request' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/transaction'
        title='Transaction Management'
        icon='/media/icons/duotune/finance/fin008.svg'
        fontIcon='bi-layers'
      >
        {/* Transaction */}
        <SidebarMenuItem
          to='/transaction/pending'
          icon='/media/icons/duotune/finance/fin010.svg'
          title='Pending Transaction'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/transaction/list'
          icon='/media/icons/duotune/finance/fin010.svg'
          title='Transaction'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/deposit'
        title='Deposit Management'
        icon='/media/icons/duotune/finance/fin001.svg'
        fontIcon='bi-layers'
      >
        {/* Deposit */}
        <SidebarMenuItem
          to='/deposit/pending'
          icon='/media/icons/duotune/finance/fin001.svg'
          title='Pending Deposit'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/deposit/list'
          icon='/media/icons/duotune/finance/fin001.svg'
          title='Deposit'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/withdrawal'
        title='Withdrawal Management'
        icon={`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
        <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
        <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
        <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
      </svg>`}
        fontIcon='bi-layers'
      >
        {' '}
        <SidebarMenuItem
          to='/withdrawal/pending'
          title='Withdrawal Pending List'
          hasBullet={true}
        />
        <SidebarMenuItem to='/withdrawal/list' title='Withdrawal List' hasBullet={true} />
        <SidebarMenuItem
          to='/withdrawal/bulk-approval'
          title='Bulk Action for Withdrawal'
          hasBullet={true}
        />
        {/* <SidebarMenuItem to='/whitelist/center' title='Service Center Whitelist' hasBullet={true} /> */}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/settlement'
        title='Settlements & Top Up for Merchant'
        icon={`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-exchange" viewBox="0 0 16 16">
        <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z"/>
      </svg>`}
        fontIcon='bi-layers'
      >
        {' '}
        <SidebarMenuItem
          to='/settlement/pending'
          title='Pending Settlement & Top Up Request'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/settlement/list'
          title='Settlement & Top Up Request'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      {/* White List Management */}
      <SidebarMenuItemWithSub
        to='/whiteList'
        title='White List Management'
        icon='/media/icons/duotune/finance/fin008.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/whitelist/source' title='Source Whitelist' hasBullet={true} />
        <SidebarMenuItem to='/whitelist/center' title='Service Center Whitelist' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/reporting'
        title='Reporting'
        icon={`
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16">
  <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
</svg>`}
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/reporting/daily' title='Daily Report' hasBullet={true} />
        <SidebarMenuItem to='/reporting/monthly' title='Monthly Report' hasBullet={true} />
        <SidebarMenuItem to='/reporting/detailed' title='Detailed Report' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/agent'
        title='Agent Management'
        icon='/media/icons/duotune/communication/com005.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/agent/summary' title='Agent Summary' hasBullet={true} />
        <SidebarMenuItem
          to='/agent/previous-day-summary'
          title='Previous Day Agent Summary'
          hasBullet={true}
        />
        <SidebarMenuItem to='/agent/credit-reload' title='Credit Reload' hasBullet={true} />
        <SidebarMenuItem
          to='/agent/commission-withdrawal'
          title='Commission Withdrawal'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
