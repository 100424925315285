import axios from 'axios'
import {stringify} from 'querystring'
import {useEffect, useState} from 'react'
import {sortByName} from '../../../components/SortByName'

export default function DeactivateAll(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  let setFormData = props.setFormData
  const [wallets, setWallets] = useState([])

  //ABSTRACT FUNCTION: Retrieve data from API
  function retrieveData(SISPAY_API: string, url: string, location: string) {
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let wallets = response.data.result[`${location}`]
        let walletsExcludeDeactive = wallets.filter((wallet: any) => wallet.status !== 'DE-ACTIVE')
        setWallets(walletsExcludeDeactive)
        //setFormData(sortByName(response.data.result[`${location}`], 'walletAccName'))
      })
      .catch(function (error) {
        alert(error)
      })
  }

  function getSetFormData(SISPAY_API: string, url: string, location: string) {
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let wallets = response.data.result[`${location}`]
        let walletsExcludeDeactive = wallets.filter((wallet: any) => wallet.status !== 'DE-ACTIVE')
        setWallets(walletsExcludeDeactive)
        setFormData(sortByName(wallets, 'walletAccName'))
      })
      .catch(function (error) {
        alert(error)
      })
  }
  //GET USER LIST
  useEffect(() => {
    retrieveData(SISPAY_API, '/wallet/list', 'wallet')
  }, [])

  function deactiveAll(wallets: any) {
    let ids = []

    for (let i = 0; i < wallets.length; i++) {
      if (wallets[i].status !== 'DE-ACTIVE') {
        ids.push({id: wallets[i].id})
      }
    }

    axios
      .post(
        `${SISPAY_API}/wallet/status/bulkupdate`,
        {
          request: {
            session: session,
            status: 'INACTIVE',
            wallets: ids,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == '200') {
          getSetFormData(SISPAY_API, '/wallet/list', 'wallet')
        }
      })
      .catch(function (error) {})
  }

  return (
    <div
      className='modal fade'
      id='kt_modal_deactive_all'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              In-Active All Accounts
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            {wallets.length > 0 ? (
              <div>Total of {wallets.length} being in-actived.</div>
            ) : (
              'No Wallet Selected'
            )}
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
              Close
            </button>
            {wallets.length > 0 && (
              <button
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => {
                  retrieveData(SISPAY_API, '/wallet/list', 'wallet')
                  deactiveAll(wallets)
                }}
              >
                IN-ACTIVE
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
