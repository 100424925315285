export function actionBtnCss(withdrawalStatus: any): any {
  if (
    withdrawalStatus == 'PENDING' ||
    withdrawalStatus == 'REJECTED' ||
    withdrawalStatus == 'SUCCESSFUL'
  ) {
    return {visibility: 'hidden', opacity: '0'}
  } else if (withdrawalStatus == 'IN-PROGRESS') {
    return {visibility: 'visible', opacity: '1'}
  }
}
