import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

export function WalletList_ExportPDF(formData: any, startDate: string, endDate: string) {
  // Register the fonts with pdfMake
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  const documentDefinition = {
    pageOrientation: 'landscape' as 'landscape', // Cast the pageOrientation value explicitly
    pageSize: 'A0' as 'A0',
    content: [
      {text: `Exported Data ${startDate} - ${endDate}`, style: 'header'},
      '\n', // Add a line break
      {
        table: {
          //widths: ['*', '*', '*'], // Adjust column widths as needed
          body: [
            [
              'ID',
              'Wallet Account Name',
              'Wallet Account Number',
              'Wallet Code',
              'Status',
              'Wallet Type',
              'Daily Deposit Limit',
              'Daily Withdrawal Limit',
              'Minimum Deposit',
              'Minimum Withdrawal',
              'Maximum Deposit',
              'Maximum Withdrawal',
              'Preferred Account',
              'Weightage',
              'Starting Balance',
              'Balance Difference',
              'Is Agent Wallet',
              'Agent Name',
              'Agent Code',
              'Merchant Name',
              'Wallet Balance',
              'Deactivated Date',
              'Created Date',
            ], // Add headers as a separate row
            ...formData.map((item: any) => [
              item.id,
              item.walletAccName,
              item.walletAccNo,
              item.walletCd,
              item.status,
              item.walletType,
              item.dailyDepositLmt,
              item.dailyWithdrawalLmt,
              item.minDeposit,
              item.minWithdrawal,
              item.maxDeposit,
              item.maxWithdrawal,
              item.prefAcc,
              item.weightage,
              item.startBal,
              item.balDiff,
              item.isAgentWallet,
              item.agentName,
              item.agentCd,
              item.merchantName,
              item.walletBal,
              item.deactivatedDT,
              item.createdDate,
            ]),
          ],
        },
        layout: 'lightHorizontalLines', // Add a layout to handle long tables
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
    },
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
  pdfDocGenerator.download(`Exported Data ${startDate} - ${endDate}.pdf`)
}
