import axios from 'axios'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {useRef} from 'react'
import {usePendingSettlementStore} from './PendingSettle_Store'
import {useSettlementStore} from './Settle_Store'

export function Settlement_Approve(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //Props
  let setRemarks = props.setRemarks
  let remarks = props.remarks
  let id = props.currentSettlement
  let settlementFilterBtn = props.settlementFilterBtn

  //Refs
  const textarea = useRef<any>(null)

  //Zustand Store
  const setPendingGetData = usePendingSettlementStore((state: any) => state.setGetData)
  const setGetData = useSettlementStore((state: any) => state.setGetData)

  // Get the current URL
  var currentUrl = window.location.href

  function verifyCurrentUrl(currentUrl: string) {
    if (currentUrl.includes('settlement/list')) {
      setGetData(true)
    } else if (currentUrl.includes('settlement/pending')) {
      setPendingGetData(true)
    } else {
      console.log('Current URL does not match the specified patterns')
    }
  }

  function approveRequest() {
    axios
      .post(
        `${SISPAY_API}/merchant/settlementtopup/approve`,
        {
          request: {
            id: id,
            notesRemarks: remarks,
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        settlementFilterBtn.current.click()
        setRemarks('') // Reset remarks to an empty string
        if (textarea.current) {
          textarea.current.value = '' // Clear the textarea's value
        }
        if (response.data.code == '200') {
          verifyCurrentUrl(currentUrl)
          alert('Settlement Request Approved!')
        } else {
          verifyCurrentUrl(currentUrl)
          alert(response.data.message)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_approve_settlement'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Settlement Approval: {id}</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <textarea
              className='w-100 h-200px'
              ref={textarea}
              onChange={(e) => {
                setRemarks(e.target.value)
              }}
            ></textarea>
          </div>
          <div className='modal-footer'>
            <button
              type='submit'
              className='btn btn-success'
              data-bs-dismiss='modal'
              onClick={() => {
                approveRequest()
              }}
            >
              APPROVE
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
