import styled from 'styled-components'

export const TableWithFirstAndLastColStick = styled.div`
  .table {
    display: inline-table;
    width: 100%;

    .sticky-header {
      position: sticky !important;
      top: 0;
      z-index: 99;
      background-color: var(--bs-body-bg);

      .th {
        background-color: var(--bs-body-bg);
      }
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid rgba(182, 173, 178, 0.2);
      border-right: 1px solid rgba(182, 173, 178, 0.2);

      position: relative;

      :first-child {
        border-left: 0;
        position: sticky !important;
        left: 0;
        background-color: var(--bs-body-bg);
        border-right: 1px solid grey;
        z-index: 2;
      }

      :last-child {
        border-right: 0;
        position: sticky !important;
        right: 0;
        background-color: var(--bs-body-bg);
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-left: 1px solid grey;
      }
      .resizer {
        display: inline-block;

        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        touch-action: none;

        &.isResizing {
        }
      }
    }
  }
`
