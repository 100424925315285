import React, {Ref, useState} from 'react'
import {Column, useTable, useResizeColumns, useFlexLayout} from 'react-table'
import {KTSVG} from '../../../../_metronic/helpers'
import {Filter} from './components/Filter'
import {Styles_NoAction} from '../../components/Table_Style_NoAction'
import scrollToTop from '../../components/ScrollToTop'
import MerchantTransactionHistory_ExportCSV from './components/MerchantTransactionHistory_ExportCSV'

const Merchant_Transaction_History_List: React.FC = () => {
  const userRole = localStorage.getItem('userRole')
  const userType = localStorage.getItem('userType')

  //States
  const [formData, setFormData] = useState([])
  const [selectedArray, setSelectedArray] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [test, setTest] = useState(Boolean)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [totalFormData, setTotalFormData] = useState(0)
  const [filterValues, setFilterValues] = useState<any>([])
  const [downloadLoading, setDownloadLoading] = useState(false)

  //Table::START
  interface Data {
    receivedDT: string
    submittedDT: string
    custAccNo: string
    customerCd: string
    walletCd: string
    debit: string
    credit: string
    clientIP: string
    transType: string
    status: string
    fee: string
    merchantTxnId: string
    submittedTxnId: string
    referenceId: string
    matchTxnId: string
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: 'Transaction DateTime',
        accessor: (row) => {
          return row.receivedDT.replace('T', ' ').split('.')[0]
        },
      },
      {
        Header: 'Submitted DateTime',
        accessor: 'submittedDT',
      },
      {
        Header: 'Customer Account No',
        accessor: 'custAccNo',
      },
      {
        Header: 'Customer Code',
        accessor: 'customerCd',
      },
      {
        Header: 'Wallet Code',
        accessor: 'walletCd',
      },
      {
        Header: 'Debit',
        accessor: 'debit',
      },
      {
        Header: 'Credit',
        accessor: 'credit',
      },
      {
        Header: 'IP',
        accessor: 'clientIP',
      },
      {
        Header: 'Transaction Type',
        accessor: 'transType',
      },
      {
        Header: 'Transaction Status',
        accessor: 'status',
      },
      {
        Header: 'Fee',
        accessor: 'fee',
      },
      {
        Header: 'Merchant Transaction ID',
        accessor: 'merchantTxnId',
      },
      {
        Header: 'Submitted Transaction ID',
        accessor: 'submittedTxnId',
      },
      {
        Header: 'Matched TxnID',
        accessor: 'matchTxnId',
      },
      {
        Header: 'Reference ID',
        accessor: 'referenceId',
      },
    ],
    [test, selectedArray]
  )

  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter(
                    (column) =>
                      column.Header !== ' ' &&
                      column.Header !== 'Action' &&
                      column.id !== 'selection'
                  )
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex justify-content-center align-items-center m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.Header)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }

  //Table::END

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0 d-flex'>
          <button
            className='btn btn-info btn-sm fw-bold btn-color-white-700 w-100'
            disabled={formData.length > 0 || !downloadLoading ? false : true}
            onClick={() => {
              MerchantTransactionHistory_ExportCSV(filterValues, setDownloadLoading)
            }}
            hidden={userRole === 'moderator' && userType === 'Merchant' ? true : false}
          >
            {!downloadLoading ? 'Download Content as CSV' : 'Downloading...Please Wait!'}
          </button>
        </div>

        <div className='m-0 d-flex '>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Filter
        setFormData={setFormData}
        setSelectedArray={setSelectedArray}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalFormData={setTotalFormData}
        setFilterValues={setFilterValues}
        setDownloadLoading={setDownloadLoading}
      />
      <Styles_NoAction>
        <Table columns={columns} data={formData == undefined ? [] : formData} />
      </Styles_NoAction>
      {/* Modal */}
      {/* Modal */}
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {totalFormData}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(currentPage - 1)
            scrollToTop()
          }}
        >
          Previous
        </button>
        {currentPage == 0 ? (
          <div>1 of {Math.ceil(totalFormData / 200)}</div>
        ) : (
          <div>
            {currentPage} of {Math.ceil(totalFormData / 200)}
          </div>
        )}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage >= Math.ceil(totalFormData / 200)}
          data-kt-scrolltop='true'
          onClick={() => {
            if (currentPage == 0) {
              setCurrentPage(2)
            } else {
              setCurrentPage(currentPage + 1)
            }
            scrollToTop()
          }}
        >
          Next
        </button>
        <select
          onChange={(e) => setCurrentPage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: Math.ceil(totalFormData / 200)}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
      </div>
      {/* Pagination END */}
      {/* Modal */}
    </div>
  )
}

export default Merchant_Transaction_History_List
