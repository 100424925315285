import React, {Ref, useEffect, useRef, useState} from 'react'
import {Column, useTable, useResizeColumns, useFlexLayout} from 'react-table'
import {metricsArray} from '../../components/MetricsArray'
import {KTSVG} from '../../../../_metronic/helpers'
import {OverlayTrigger} from 'react-bootstrap'
import popover from '../../components/Popover'
import {Export_CSV} from '../../components/Export_CSV'
import {Export_Excel} from '../../components/Export_Excel'
import {CommissionWithdrawal_Filter} from './components/CommissionWithdrawal_Filter'
import CommissionWithdrawal_ApproveModal from './components/CommissionWithdrawal_ApproveModal'
import CommissionWithdrawal_RejectModal from './components/CommissionWithdrawal_RejectModal'
import deleteKeysFromObjects from '../../components/DeleteKeysFromObjects'
import {CommissionWithdrawal_ExportPDF} from './components/CommissionWithdrawal_ExportPDF'
import FormatNumber from '../../components/FormatNumber'
import {Link} from 'react-router-dom'
import CommissionWithdrawal_BulkApprovalModal from './components/CommissionWithdrawal_BulkApprovalModal'
import {cwPushAllRow} from './components/ComissionWithdrawal_pushAllRow'
import {cwPushUniqueRow} from './components/CommissionWithdrawal_pushUnique'
import {checkValueExists} from '../../components/CheckValueExists'
import {TableWithFirstSecondAndLastColStick} from '../../walletManagement/walletList/components/WalletListStyle'

const CommissionWithdrawal_List: React.FC = () => {
  //States
  const [formData, setFormData] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [isAction, setIsAction] = useState(false)
  const [metrics, setMatrics] = useState<any>(undefined)
  const [currentCommWithdrawal, setCurrentCommWithdrawal] = useState({
    id: '',
    requestDT: '',
    transType: '',
    status: '',
    username: '',
  })
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [selectedArray, setSelectedArray] = useState([])

  //Refs
  const commWithdrawalFilterBtn = useRef<any>()

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  let items = formData

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = Math.min(firstIndex + itemsPerPage, items.length)
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : []

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(e.target.value)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset the current page to 1
  }
  //Pagincation

  //Table::START

  useEffect(() => {
    let metrics = localStorage.getItem('commWithdrawalMetrics')
    if (metrics) {
      setMatrics(JSON.parse(metrics))
    } else {
      setMatrics([])
    }
  }, [formData, showMetrics])

  interface Data {
    amount: string
    destWalletCd: string
    requestDT: string
    transType: string
    id: string
    destWalletAcc: string
    userId: string
    status: string
    username: string
    statUpdateRemark: string
    successRejectBy: string
  }

  const keyMap = {
    id: 'CW Request ID',
    requestDT: 'CW Request Submission DateTime',
    transType: 'CW Type',
    status: 'CW Status',
    username: 'Agent',
    amount: 'Amount',
    statUpdateRemark: 'Remark',
    successRejectBy: 'Success/ Reject By',
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        id: 'selection',
        Header: () => {
          return (
            //Check All Checkbox
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                title={'Select All ' + formData.length + ' Deposits'}
                style={{
                  cursor: 'pointer',
                  padding: '0',
                }}
                type='checkbox'
                value=''
                id='checkAll'
                onChange={() => {
                  cwPushAllRow(selectedArray, formData, setSelectedArray)
                }}
                checked={selectedArray.length > 0 && selectedArray.length === formData.length}
              />
            </div>
          )
        },
        width: 70,
        accessor: (formData: any) => {
          return (
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                style={{
                  cursor: 'pointer',
                }}
                type='checkbox'
                value=''
                id={'checkbox_' + formData.walletAccNo}
                onChange={() => {
                  cwPushUniqueRow(selectedArray, formData, setSelectedArray)
                }}
                checked={checkValueExists(selectedArray, formData.id)}
              />
            </div>
          )
        },
      },
      {
        id: 'CW Request ID',
        Header: 'CW Request ID',
        accessor: 'id',
        width: 150,
      },
      {
        id: 'CW Request Submission DateTime',
        Header: 'CW Request Submission DateTime',
        accessor: (row: any) => {
          return row.requestDT.replace('T', ' ').replace(/\.\d+/, '')
        },
        width: 200,
      },
      {
        id: 'CW Type',
        Header: 'CW Type',
        accessor: 'transType',
      },
      {
        id: 'CW Status',
        Header: 'CW Status',
        accessor: 'status',
      },
      {
        id: 'Agent',
        Header: 'Agent',
        accessor: (row: any) => {
          return row.username
        },
      },
      {
        id: 'Amount',
        Header: 'Amount',
        accessor: (row: any) => {
          return FormatNumber(row.amount)
        },
      },
      {
        id: 'Remark',
        Header: 'Remark',
        accessor: 'statUpdateRemark',
      },
      {
        id: 'Success/ Reject By',
        Header: 'Success/ Reject By',
        accessor: 'successRejectBy',
      },
      {
        Header: 'Action',
        accessor: (formData: any) => {
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <a
                className='btn btn-icon btn-bg-success btn-active-color-white btn-sm me-1'
                data-bs-toggle='modal'
                href='#creditReloadApprove'
                role='button'
                onClick={() => {
                  setCurrentCommWithdrawal(formData)
                }}
                style={{
                  opacity: formData.status == 'PENDING' ? '1' : '0',
                  visibility: formData.status == 'PENDING' ? 'visible' : 'hidden',
                }}
              >
                <OverlayTrigger
                  trigger='hover'
                  placement='top'
                  overlay={popover('Approve Credit Reload')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <i className='bi bi-lightning-charge-fill'></i>
                  </div>
                </OverlayTrigger>{' '}
              </a>
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                data-bs-toggle='modal'
                data-bs-target='#creditReloadReject'
                onClick={() => {
                  setCurrentCommWithdrawal(formData)
                }}
                style={{
                  opacity: formData.status == 'PENDING' ? '1' : '0',
                  visibility: formData.status == 'PENDING' ? 'visible' : 'hidden',
                }}
              >
                <OverlayTrigger
                  trigger='hover'
                  placement='top'
                  overlay={popover('Reject Credit Reload')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </button>
            </div>
          )
        },
      },
    ],
    [selectedArray]
  )
  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {hiddenColumns: metrics ? metrics : []},
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter(
                    (column) =>
                      column.Header !== ' ' &&
                      column.Header !== 'Action' &&
                      column.id !== 'selection'
                  )
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex justify-content-center align-items-center m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            onClick={() => {
                              metricsArray('commWithdrawalMetrics', column.id)
                            }}
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.id)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5' style={{maxHeight: '70vh'}}>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  {headerGroups.map((headerGroup) => (
                    <div
                      {...headerGroup.getHeaderGroupProps()}
                      className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200 sticky-header'
                    >
                      {headerGroup.headers.map((column: any) => (
                        <div {...column.getHeaderProps()} className='th'>
                          {column.render('Header')}
                          {/* Use column.getResizerProps to hook up the events correctly */}
                          <div
                            {...(column as any).getResizerProps()}
                            className={`resizer ${(column as any).isResizing ? 'isResizing' : ''}`}
                          />
                        </div>
                      ))}
                    </div>
                  ))}

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        <div className='d-flex m-0'>
          <div
            className='position-relative me-5'
            style={{
              width: '180px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-info w-100'
              onClick={() => {
                setIsAction(!isAction)
              }}
            >
              Download Record As:
            </div>{' '}
            {isAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_CSV(
                      deleteKeysFromObjects(formData, ['destWalletCd', 'destWalletAcc', 'userId']),
                      keyMap,
                      startDate,
                      endDate,
                      'Commission_Withdrawal'
                    )
                    setIsAction(!isAction)
                  }}
                >
                  CSV
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_Excel(
                      deleteKeysFromObjects(formData, ['destWalletCd', 'destWalletAcc', 'userId']),
                      keyMap,
                      startDate,
                      endDate,
                      'Commission_Withdrawal'
                    )
                    setIsAction(!isAction)
                  }}
                >
                  Excel WorkBook
                </button>{' '}
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    CommissionWithdrawal_ExportPDF(formData, startDate, endDate)
                    setIsAction(!isAction)
                  }}
                >
                  PDF
                </button>{' '}
              </div>
            )}
          </div>
          <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-warning me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_cw_bulk_approval'
            disabled={selectedArray.length > 0 ? false : true}
          >
            BULK APPROVAL
          </button>
          <Link
            to='/agent/commission-withdrawal/create'
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-primary me-5'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-plus me-1'
              viewBox='0 0 16 16'
            >
              <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
            </svg>
            Commission Conversion/Withdrawal
          </Link>{' '}
        </div>
        <button
          type='button'
          className='btn btn-sm btn-flex fw-bold rotate'
          onClick={(e) => {
            setShowMetrics(!showMetrics)
            e.currentTarget.classList.toggle('active')
          }}
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr004.svg'
            className='svg-icon-6 svg-icon-muted me-1 rotate-180'
          />{' '}
          Select Metrics
        </button>
      </div>

      <CommissionWithdrawal_Filter
        setFormData={setFormData}
        commWithdrawalFilterBtn={commWithdrawalFilterBtn}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      <CommissionWithdrawal_BulkApprovalModal
        selectedArray={selectedArray}
        setSelectedArray={setSelectedArray}
        setFormData={setFormData}
        commWithdrawalFilterBtn={commWithdrawalFilterBtn}
      />
      <TableWithFirstSecondAndLastColStick>
        <Table columns={columns} data={currentItems == undefined ? [] : currentItems} />
      </TableWithFirstSecondAndLastColStick>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {formData.length}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={previousPage}
        >
          Previous
        </button>
        {currentPage} of {lastPage}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage === lastPage}
          onClick={nextPage}
        >
          Next
        </button>
        <select
          onChange={(e) => changePage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: lastPage}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
          <option value={250}>250 per page</option>
          <option value={500}>500 per page</option>
        </select>
      </div>
      {/* Pagination END */}

      <CommissionWithdrawal_ApproveModal
        currentCommWithdrawal={currentCommWithdrawal}
        setCurrentCommWithdrawal={setCurrentCommWithdrawal}
        commWithdrawalFilterBtn={commWithdrawalFilterBtn}
      />
      <CommissionWithdrawal_RejectModal
        currentCommWithdrawal={currentCommWithdrawal}
        setCurrentCommWithdrawal={setCurrentCommWithdrawal}
        commWithdrawalFilterBtn={commWithdrawalFilterBtn}
      />
    </div>
  )
}

export default CommissionWithdrawal_List
