import {useRef} from 'react'
import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import {useAuth} from '../../../auth'
import {customStyles} from '../../../components/CustomStyles'
import {ErrorHandling} from '../../../components/ErrorHandling'
import {filterArrayByMultipleKeys} from '../../../components/Filter'
import {getCurrentDateInput} from '../../../components/GetCurrentDateInput'
import {sortByName} from '../../../components/SortByName'
import {usePreviousDayAgentSummaryStore} from './PreviousDayAgentSummary_Store'

export function PreviousDayAgentSummary_Filter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData
  let creditReloadFilterBtn = props.creditReloadFilterBtn
  let setStartDate = props.setStartDate
  let setEndDate = props.setEndDate

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [agentSelectOptions, setAgentSelectOptions] = useState([])

  //Refs
  const agentSelectRef = useRef<any>()

  //Zustand Store
  const filterOptions = usePreviousDayAgentSummaryStore((state: any) => state.filterOptions)
  const getData = usePreviousDayAgentSummaryStore((state: any) => state.getData)
  const setFilterOptions = usePreviousDayAgentSummaryStore((state: any) => state.setFilterOptions)
  const setGetData = usePreviousDayAgentSummaryStore((state: any) => state.setGetData)

  //GET AGENT SELECT OPTIONS
  function getAgentSelectOptions() {
    let result
    axios
      .post(
        `${SISPAY_API}/user/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result.users
          let user: object
          let users: any = []

          for (let i = 0; i < result.length; i++) {
            if (result[i].userType == 'Agent') {
              user = {
                value: result[i].username,
                label: result[i].username,
              }
              users.push(user)
            }
          }

          users = Array.from(new Set(users))
          setAgentSelectOptions(sortByName(users, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  useEffect(() => {
    getAgentSelectOptions()
  }, [])

  const initialValues = {
    username: filterOptions.username ? String(filterOptions.username) : '',
    queryDate: filterOptions.queryDate ? String(filterOptions.queryDate) : getCurrentDateInput(),
  }

  const filterSchema = Yup.object().shape({
    username: Yup.string(),
    queryDate: Yup.date(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)

      let username = values.username.trim()
      let queryDate = values.queryDate.trim()

      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/agent/summary/daily/list`,
          {
            request: {
              queryDate: queryDate,
              session: session,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let filteredFormData = filterArrayByMultipleKeys(
            response.data.result.agentSummary,
            ['agentUsername'],
            [username]
          )

          setStartDate(queryDate)
          setEndDate(queryDate)

          setFormData(filteredFormData)

          setDisabled(false)
          setLoading(false)
        })
        .catch(function (error) {})
    },
  })

  useEffect(() => {
    if (getData == false) return

    setDisabled(true)
    setLoading(true)

    let username = formik.values.username.trim()
    let queryDate = formik.values.queryDate.trim()

    axios
      .post(
        `${SISPAY_API}/agent/summary/daily/list`,
        {
          request: {
            queryDate: queryDate,
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let filteredFormData = filterArrayByMultipleKeys(
          response.data.result.agentSummary,
          ['agentUsername'],
          [username]
        )

        setStartDate(queryDate)
        setEndDate(queryDate)
        setFormData(filteredFormData)
        setGetData(false)
        setDisabled(false)
        setLoading(false)
      })
      .catch(function (error) {})
  }, [getData])

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-2 py-1'>
          <div className=''>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='row'>
                <div className='col'>
                  {/* begin::Form group Agent */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Agent</label>
                    <Select
                      ref={agentSelectRef}
                      className='basic-single'
                      classNamePrefix='select'
                      options={agentSelectOptions}
                      styles={customStyles}
                      isClearable={true}
                      name='username'
                      value={
                        formik.values.username
                          ? {value: formik.values.username, label: formik.values.username}
                          : null
                      }
                      onChange={(e: any) =>
                        e !== null
                          ? formik.setFieldValue('username', e.value)
                          : formik.setFieldValue('username', '')
                      }
                    />
                  </div>{' '}
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group DateTime */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Date</label>
                    <input
                      placeholder='Enter your date'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('queryDate')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.queryDate && formik.errors.queryDate,
                        },
                        {
                          'is-valid': formik.touched.queryDate && !formik.errors.queryDate,
                        }
                      )}
                    />
                    {formik.touched.queryDate && formik.errors.queryDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.queryDate}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                  {/* end::Form group */}
                </div>
              </div>
              <div className='row'></div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      disabled={disabled}
                      onClick={() => {
                        agentSelectRef.current.setValue('')
                        formik.resetForm({
                          values: {
                            username: '',
                            queryDate: getCurrentDateInput(),
                          },
                        })
                      }}
                    >
                      {!loading && <span className='indicator-label'>Reset</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      ref={creditReloadFilterBtn}
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid || disabled}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>{' '}
      </div>
    </div>
  )
}
