import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'
import Select from 'react-select'
import {sortByName} from '../../components/SortByName'

const WalletListPage_Assign: React.FC = () => {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const navigate = useNavigate()
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  const [loading, setLoading] = useState(false)
  const [merchantCodes, setMerchantCodes] = useState()
  const [walletAccs, setWalletAccs] = useState(Object)
  const [multi, setMulti] = useState([])

  //ABSTRACT FUNCTION: Retrieve data from API
  function retrieveData(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let result = response.data.result[`${location}`]
        let merchantCode: object
        let merchantCodes: any = []

        for (let i = 0; i < result.length; i++) {
          merchantCode = {
            value: result[i].merchantCd,
            label: result[i].merchantName + ' - ' + result[i].merchantCd,
          }
          merchantCodes.push(merchantCode)
        }

        merchantCodes = Array.from(new Set(merchantCodes))

        setMerchantCodes(sortByName(merchantCodes, 'label'))
      })
      .catch(function (error) {})
  }

  //ABSTRACT FUNCTION: Retrieve Wallet Account from API
  function retrieveWalletAcc(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
            isDropdown: true,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let result = response.data.result[`${location}`]

        setWalletAccs(sortByName(result, 'walletAccName'))
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    retrieveData(SISPAY_API, '/merchant/list', 'merchant')
    retrieveWalletAcc(SISPAY_API, '/wallet/list', 'wallet')
  }, [])

  const initialValues = {
    walletAccNo: '',
    walletCd: '',
    merchantCd: '',
    status: '',
    session: session,
  }

  const addUserSchema = Yup.object().shape({})

  const formik = useFormik({
    initialValues,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      function extractValues(merchants: {value: string; label: string}[]): string {
        return merchants.map((merchant) => merchant.value).join('|')
      }

      let multiString = extractValues(multi)

      try {
        axios
          .post(
            `${SISPAY_API}/wallet/assignment`,
            {
              request: {
                walletAccNo: values.walletAccNo,
                walletCd: values.walletCd,
                merchantCd: multiString,
                status: values.status,
                session: session,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response) {
            if (response.data.code == '200') {
              setLoading(false)
              navigate(-1)
              alert('Wallet Successfully Assigned!')
            } else {
              alert(response.data.message)
            }
          })
          .catch(function (error) {})
      } catch (error) {}
    },
  })

  const customStyles = {
    control: (base: any, state: {isFocused: any}) => ({
      ...base,
      background: 'transparent',
    }),
    menu: (base: any) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base: any) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  }

  const multiOptions = merchantCodes

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
        <span className='text-gray-800 fs-1 fw-bolder ms-5 d-flex align-items-center'>
          Wallet Assignment
        </span>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          <div className='row'>
            {/* begin::Form group Wallet Code */}
            <div className='fv-row mb-8'>
              <label className='form-label required fw-bolder text-dark fs-6'>Wallet Code</label>
              <select
                {...formik.getFieldProps('walletCd')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.walletCd && formik.errors.walletCd,
                  },
                  {
                    'is-valid': formik.touched.walletCd && !formik.errors.walletCd,
                  }
                )}
                data-placeholder=''
              >
                <option value=''>Please Select</option>
                <option value='BKASH'>BKASH</option>
                <option value='ROCKET'>ROCKET</option>
                <option value='NAGAD'>NAGAD</option>
              </select>
            </div>{' '}
            {/* end::Form group */}
            {/* begin::Form group Wallet Account No */}
            <div className='fv-row mb-8'>
              <label className='form-label required fw-bolder text-dark fs-6'>
                Wallet Account No
              </label>
              <select
                {...formik.getFieldProps('walletAccNo')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.walletAccNo && formik.errors.walletAccNo,
                  },
                  {
                    'is-valid': formik.touched.walletAccNo && !formik.errors.walletAccNo,
                  }
                )}
                data-placeholder=''
              >
                <option value=''>Please Select</option>
                {walletAccs.length > 0 &&
                  walletAccs
                    .filter(
                      (walletAcc: {walletCd: string}) =>
                        walletAcc.walletCd == formik.getFieldProps('walletCd').value
                    )
                    .map((walletAcc: any) => {
                      return (
                        <option value={walletAcc.walletAccNo}>
                          {walletAcc.walletAccName} - {walletAcc.walletAccNo}
                        </option>
                      )
                    })}
              </select>
            </div>{' '}
            {/* end::Form group */}
            {/* begin::Form group Merchant Code */}
            <div
              className='fv-row mb-8'
              style={{
                color: 'black',
              }}
            >
              <label className='form-label required fw-bolder text-dark fs-6'>Merchant</label>
              <Select
                isMulti
                name='merchantCd'
                options={multiOptions}
                className='basic-multi-select'
                classNamePrefix='select'
                onChange={(choice: any) => setMulti(choice)}
                styles={customStyles}
              />
            </div>{' '}
            {/* end::Form group */}
            {/* begin::Form group Status */}
            <div className='fv-row mb-8'>
              <label className='form-label required fw-bolder text-dark fs-6'>Status</label>
              <select
                {...formik.getFieldProps('status')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.status && formik.errors.status,
                  },
                  {
                    'is-valid': formik.touched.status && !formik.errors.status,
                  }
                )}
                data-placeholder=''
              >
                <option value=''>Please Select</option>
                <option value='active'>Active</option>
                <option value='inactive'>Inactive</option>
              </select>
            </div>{' '}
            {/* end::Form group */}
            <div className='col'>
              {/* begin::Form group */}
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                >
                  {!loading && <span className='indicator-label'>ASSIGN</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Form group */}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default WalletListPage_Assign
