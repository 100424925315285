/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'

function DashboardPage() {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const [sms, setSms] = useState([])
  const [request, setRequest] = useState([])
  const [transactions, setTransactions] = useState([])
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //ABSTRACT FUNCTION: Retrieve data from API
  // function retrieveSms(SISPAY_API: string, url: string, location: string) {
  //   let result
  //   axios
  //     .post(
  //       `${SISPAY_API}${url}`,
  //       {
  //         request: {
  //           session: session,
  //         },
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     )
  //     .then(function (response) {
  //       setSms(response.data.result[`${location}`])
  //     })
  //     .catch(function (error) {})
  // }

  //ABSTRACT FUNCTION: Retrieve data from API
  // function retrieveRequest(SISPAY_API: string, url: string, location: string) {
  //   let result
  //   axios
  //     .post(
  //       `${SISPAY_API}${url}`,
  //       {
  //         request: {
  //           session: session,
  //         },
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     )
  //     .then(function (response) {
  //       setRequest(response.data.result[`${location}`])
  //     })
  //     .catch(function (error) {})
  // }

  //ABSTRACT FUNCTION: Retrieve data from API
  // function retrieveTransaction(SISPAY_API: string, url: string, location: string) {
  //   let result
  //   axios
  //     .post(
  //       `${SISPAY_API}${url}`,
  //       {
  //         request: {
  //           session: session,
  //         },
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     )
  //     .then(function (response) {
  //       setTransactions(response.data.result[`${location}`])
  //     })
  //     .catch(function (error) {})
  // }

  // useEffect(() => {
  //   retrieveSms(SISPAY_API, '/sms/listmessage', 'smsList')
  //   retrieveRequest(SISPAY_API, '/sms/listrequest', 'smsRequestList')
  //   retrieveTransaction(SISPAY_API, '/transaction/list', 'TransactionList')
  // }, [])

  return (
    <div className='d-flex flex-row flex-wrap'>
      {/* <div className='bg-light d-flex flex-column justify-content-center align-items-center w-300px h-200px m-5 rounded-2'>
        <h2>SMS</h2>
        <div className='d-flex flex-column justify-content-center align-items-center bg-white text-black rounded-2 p-2 m-2'>
          <div>TOTAL</div>
          <div className='fw-bold'>{sms.length}</div>
        </div>
      </div>
      <div className='bg-light d-flex flex-column justify-content-center align-items-center w-300px h-200px m-5 rounded-2'>
        <h2>Request</h2>
        <div className='d-flex flex-row flex-wrap justify-content-center align-items-center'>
          <div className='d-flex flex-column justify-content-center align-items-center bg-white text-black rounded-2 p-2 m-2'>
            <div>TOTAL</div>
            <div className='fw-bold'>{request.length}</div>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center bg-white text-black rounded-2 p-2 m-2'>
            <div>DEPOSIT</div>
            <div className='fw-bold'>
              {request.filter((request: {smsType: string}) => request.smsType == 'DEPOSIT').length}
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center bg-white text-black rounded-2 p-2 m-2'>
            <div>B2B RECEIVE</div>
            <div className='fw-bold'>
              {
                request.filter((request: {smsType: string}) => request.smsType == 'B2B RECEIVE')
                  .length
              }
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center bg-white text-black rounded-2 p-2 m-2'>
            <div>B2B SEND</div>
            <div className='fw-bold'>
              {request.filter((request: {smsType: string}) => request.smsType == 'B2B SEND').length}
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center bg-white text-black rounded-2 p-2 m-2'>
            <div>WITHDRAWAL</div>
            <div className='fw-bold'>
              {
                request.filter((request: {smsType: string}) => request.smsType == 'WITHDRAWAL')
                  .length
              }
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center bg-white text-black rounded-2 p-2 m-2'>
            <div>JUNK</div>
            <div className='fw-bold'>
              {request.filter((request: {smsType: string}) => request.smsType == 'JUNK').length}
            </div>
          </div>
        </div>
      </div>
      <div className='bg-light d-flex flex-column justify-content-center align-items-center w-300px h-200px m-5 rounded-2'>
        <h2>TRANSACTION</h2>
        <div className='d-flex flex-row flex-wrap justify-content-center align-items-center'>
          <div className='d-flex flex-column justify-content-center align-items-center bg-white text-black rounded-2 p-2 m-2'>
            <div>TOTAL</div>
            <div className='fw-bold'>{transactions.length}</div>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center bg-white text-black rounded-2 p-2 m-2'>
            <div>PENDING</div>
            <div className='fw-bold'>
              {
                transactions.filter(
                  (transaction: {transSts: string}) => transaction.transSts == 'PENDING'
                ).length
              }
            </div>
          </div>{' '}
        </div>
      </div> */}
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
