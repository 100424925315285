import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import {filterArrayByMultipleKeys} from '../../../components/Filter'
import {searching} from '../../../components/Searching'
import {sortByName} from '../../../components/SortByName'
import {Overlay} from '../../../components/Overlay'
import {ErrorHandling} from '../../../components/ErrorHandling'
import {useAuth} from '../../../auth/core/Auth'
import {useWhiteListSourceStore} from './WhiteList_Source_Store'

export function Filter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  //Zustand Store
  const filterOptions = useWhiteListSourceStore((state: any) => state.filterOptions)
  const getData = useWhiteListSourceStore((state: any) => state.getData)
  const setFilterOptions = useWhiteListSourceStore((state: any) => state.setFilterOptions)
  const setGetData = useWhiteListSourceStore((state: any) => state.setGetData)

  console.log(filterOptions)

  const initialValues = {
    search: filterOptions.search ? String(filterOptions.search) : '',
    active: filterOptions.active,
    walletCd: filterOptions.walletCd ? String(filterOptions.walletCd) : '',
  }

  const filterSchema = Yup.object().shape({
    search: Yup.string(),
  })

  const keys = ['source', 'description']

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)
      let search = values.search.trim()
      let walletCd = values.walletCd.trim()
      let active = values.active !== '' ? Boolean(values.active) : ''

      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/whitelist/source/list`,
          {
            request: {
              session: session,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 200) {
            let filteredFormData = filterArrayByMultipleKeys(
              response.data.result.users,
              ['active', 'walletCd'],
              [active, walletCd]
            )

            if (search !== '') {
              filteredFormData = searching(filteredFormData, search.toLowerCase(), keys)
            }

            setFormData(sortByName(filteredFormData, 'source'))

            setDisabled(false)
            setLoading(false)
          } else {
            ErrorHandling(response, logout)
          }
        })
        .catch(function (error) {})
    },
  })

  useEffect(() => {
    if (getData == false) return

    setDisabled(true)
    setLoading(true)
    let search = formik.values.search.trim()
    let walletCd = formik.values.walletCd.trim()
    let active = formik.values.active !== '' ? Boolean(formik.values.active) : ''

    axios
      .post(
        `${SISPAY_API}/whitelist/source/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let filteredFormData = filterArrayByMultipleKeys(
            response.data.result.users,
            ['active', 'walletCd'],
            [active, walletCd]
          )

          if (search !== '') {
            filteredFormData = searching(filteredFormData, search.toLowerCase(), keys)
          }

          setFormData(sortByName(filteredFormData, 'source'))

          setGetData(false)
          setDisabled(false)
          setLoading(false)
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }, [getData])

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-2 py-1'>
          <div className='mb-10'>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='fv-row mb-5'>
                {' '}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      {' '}
                      {/* begin::Form group Search */}
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>Search</label>
                        {Overlay('SEARCH Source, Description')}
                      </div>
                      <input
                        placeholder='Enter your search'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('search')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.search && formik.errors.search,
                          },
                          {
                            'is-valid': formik.touched.search && !formik.errors.search,
                          }
                        )}
                      />
                      {formik.touched.search && formik.errors.search && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.search}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                    <div className='col'>
                      {/* begin::Form group User type */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>
                        <select
                          {...formik.getFieldProps('walletCd')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value=''>ALL</option>
                          <option value='BKASH'>BKASH</option>
                          <option value='NAGAD'>NAGAD</option>
                          <option value='ROCKET'>ROCKET</option>
                        </select>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group Status */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Active</label>
                        <select
                          {...formik.getFieldProps('active')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value=''>ALL</option>
                          <option value='true'>YES</option>
                          <option value='false'>NO</option>
                        </select>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      disabled={disabled}
                      onClick={() =>
                        formik.resetForm({
                          values: {
                            search: '',
                            active: 'true',
                            walletCd: '',
                          },
                        })
                      }
                    >
                      {!loading && <span className='indicator-label'>Reset</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid || disabled}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>{' '}
    </div>
  )
}
