export default function FormatNumberTo2Decimals(input: any) {
  const number = parseFloat(input)

  // Check if the input is a valid number
  if (!isNaN(number)) {
    // Check if the number is an integer (has no decimal part)
    if (Number.isInteger(number)) {
      return number.toFixed(2) // Add ".00" to integers
    } else {
      // Check if there's only one decimal place
      const decimalPart = number.toString().split('.')[1]
      if (decimalPart && decimalPart.length === 1) {
        return number.toFixed(2) // Add an extra zero after the decimal point
      } else {
        return number.toString() // Keep the original format
      }
    }
  } else {
    return input
  }
}
