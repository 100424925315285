import axios from 'axios'
import {modifyDT} from '../../../components/ModifyDT'
import {getCurrentDateTime} from '../../../components/GetCurrentDatetime'
import {modifyKeys} from '../../../components/ModifyKeys'

const keyMap = {
  fee: 'Fee',
  merchantTxnId: 'Merchant Transaction ID',
  receivedDT: 'Received DateTime',
  submittedTxnId: 'Submitted Transaction ID',
  custAccNo: 'Customer Account No',
  referenceId: 'Reference ID',
  walletCd: 'Wallet Code',
  transType: 'Transaction Type',
  clientIP: 'Client IP',
  submittedDT: 'Submitted DateTime',
  debit: 'Debit',
  credit: 'Credit',
  customerCd: 'Customer Code',
  merchantCd: 'Merchant Code',
  status: 'Status',
  matchTxnId: 'Match TxnID',
}

function escapeCsvValue(value: string): string {
  // If the value contains a comma or line breaks, wrap it in double quotes and escape existing double quotes and line breaks
  if (value.includes(',') || value.includes('\n') || value.includes('\r')) {
    return `"${value.replace(/"/g, '""').replace(/[\n\r]+/g, ' ')}"`
  }
  return value
}

function generateFile(formData: any) {
  let dataset = formData.map((obj: any) => {
    const updatedObj = {...obj}
    delete updatedObj.merchantTimestamp
    delete updatedObj.depositProcessId
    return updatedObj
  })

  // Modify receivedDT
  dataset = modifyDT(dataset, 'Completed DateTime')
  dataset = modifyDT(dataset, 'Deposit DateTime')

  const header = Object.keys(dataset[0]).join(',')
  const rows = dataset.map((transaction: any) =>
    Object.values(transaction)
      .map((value: any) => escapeCsvValue(value.toString()))
      .join(',')
  )

  const csvContent = `${header}\n${rows.join('\n')}`
  const blob = new Blob([csvContent], {type: 'text/csv'})

  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `deposit_${getCurrentDateTime()}.csv`
  a.click()

  URL.revokeObjectURL(url)
}

export default function MerchantTransactionHistory_ExportCSV(
  filterValues: any,
  setDownloadLoading: any
): void {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const merchantCdStored = localStorage.getItem('merchantCd')

  let searchTransaction = filterValues.searchTransaction
  let searchCustomer = filterValues.searchCustomer
  let searchAmount = filterValues.searchAmount
  let transactionType = filterValues.transactionType
  let transactionStatus = filterValues.transactionStatus
  let walletCd = filterValues.walletCd
  let dateTimeFrom = filterValues.dateTimeFrom
  let dateTimeTo = filterValues.dateTimeTo

  setDownloadLoading(true)

  axios
    .post(
      `${SISPAY_API}/merchant/portal/transactionhistory`,
      {
        request: {
          session: session,
          fromDate: dateTimeFrom,
          toDate: dateTimeTo,
          transType: transactionType,
          merchantCd: merchantCdStored,
          action: 'download', //list or download

          ...(transactionStatus && {byStatus: transactionStatus}),
          ...(searchTransaction && {byTransaction: searchTransaction}),
          ...(searchCustomer && {byCustomer: searchCustomer}),
          ...(searchAmount && {byAmount: searchAmount}),
          ...(walletCd && {byWalletCd: walletCd}),
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function (response) {
      let formData = modifyKeys(response.data.result.merchantTransHistory, keyMap)
      generateFile(formData)
      setDownloadLoading(false)
    })
    .catch(function (error) {
      setDownloadLoading(false)
    })
}
