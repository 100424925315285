import {create} from 'zustand'

export const useMerchantTransactionHisotryStore = create((set) => ({
  //Data
  getData: false,
  filterOptions: {
    searchTransaction: '',
    searchCustomer: '',
    searchAmount: '',
    transactionType: 'ALL',
    transactionStatus: '',
    walletCd: '',
    dateTimeFrom: '',
    dateTimeTo: '',
  },

  //Functions
  setGetData: (value: boolean) => set({getData: value}),
  setFilterOptions: (value: any) => set({filterOptions: value}),
}))
