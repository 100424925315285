/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {MerchantAdminRoutes} from './Merchant_Admin_Routes'
import {MerchantModRoutes} from './Merchant_Mod_Routes'
import {AdminOperationRoutes} from './Admin_Operation_Routes'
import {MerchantVIPRoutes} from './Merchant_VIP_Routes'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  const userType: string = localStorage.getItem('userType') || ''
  const userRole: string = localStorage.getItem('userRole') || ''

  function renderLayout() {
    if (currentUser && userType === 'Admin' && userRole === 'operation') {
      return (
        <>
          <Route path='/*' element={<AdminOperationRoutes />} />
          <Route index element={<Navigate to='/dashboard' />} />
        </>
      )
    } else if (currentUser && (userType === 'Admin' || userType === 'System Admin')) {
      return (
        <>
          <Route path='/*' element={<PrivateRoutes />} />
          <Route index element={<Navigate to='/dashboard' />} />
        </>
      )
    } else if (currentUser && userType === 'Merchant' && userRole === 'admin') {
      return (
        <>
          <Route path='/*' element={<MerchantAdminRoutes />} />
          <Route index element={<Navigate to='/dashboard' />} />
        </>
      )
    } else if (currentUser && userType === 'Merchant' && userRole === 'moderator') {
      return (
        <>
          <Route path='/*' element={<MerchantModRoutes />} />
          <Route index element={<Navigate to='/dashboard' />} />
        </>
      )
    } else if (currentUser && userType === 'Merchant' && userRole === 'vip') {
      return (
        <>
          <Route path='/*' element={<MerchantVIPRoutes />} />
          <Route index element={<Navigate to='/dashboard' />} />
        </>
      )
    } else if (currentUser && (userType === 'Reseller' || userType === 'Agent')) {
      return (
        <>
          <Route path='auth' element={<Navigate to='/error' />} />
          <Route path='*' element={<Navigate to='/error' />} />
        </>
      )
    } else if (currentUser == undefined) {
      return (
        <>
          <Route path='auth' element={<AuthPage />} />
          <Route path='*' element={<Navigate to='/error' />} />
        </>
      )
    }
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {renderLayout()}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
