import {OverlayTrigger} from 'react-bootstrap'
import popover from './Popover'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'

export function Overlay(text: string) {
  return (
    <OverlayTrigger trigger='hover' placement='top' overlay={popover(text)}>
      <div
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        <KTSVG
          path='/media/icons/duotune/general/gen045.svg'
          className='svg-icon-muted svg-icon-1hx ms-3'
        />
      </div>
    </OverlayTrigger>
  )
}
