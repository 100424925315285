import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useAuth} from '../../auth/core/Auth'
import Select from 'react-select'
import {customStyles} from '../../components/CustomStyles'
import axios from 'axios'
import {sortByName} from '../../components/SortByName'
import {ErrorHandling} from '../../components/ErrorHandling'
import getTimestampInSec from '../../components/GetTimeInSeconds'
import getTimestamp from '../../components/GetTimestamp'
import sha1 from 'crypto-js/sha1'
import qs from 'qs'

const ManualWithdrawal_Payout: React.FC = () => {
  const {logout} = useAuth()
  const navigate = useNavigate()
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //States
  const [loading, setLoading] = useState(false)

  const initialValues = {
    customerCd: 'CC' + getTimestampInSec(),
    currency: 'BDT',
    bankCode: 'nagad',
    amount: '201.00',
    timestamp: getTimestamp(),
    resultUrl: 'www.google.com',
    responseUrl: 'www.google.com',
    walletAccountNo: '',
  }

  const ManualDepositCreateSchema = Yup.object().shape({})

  const formik = useFormik({
    initialValues,
    // enableReinitialize: true,
    validationSchema: ManualDepositCreateSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      let data: any = {
        CustomerCode: values.customerCd,
        Currency: values.currency,
        BankCode: values.bankCode,
        Amount: values.amount,
        TransactionTimeStamp: values.timestamp,
        ResultURL: values.resultUrl,
        ResponseURL: values.responseUrl,
        walletAccountNo: values.walletAccountNo.toString(),
        session: session,
      }

      axios
        .post(
          `${SISPAY_API}/cash/payoutrequest/admin/create`,
          {
            request: data,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 200) {
            setLoading(false)
            navigate(-1)
            alert(response.data.message)
          } else {
            setLoading(false)
            ErrorHandling(response, logout)
          }
        })
        .catch(function (error) {
          alert(error)
        })
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-between'></div>{' '}
      <div className='card card-custom card-flush mt-5'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group Customer Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Customer Code</label>
            <input
              placeholder='Enter Customer Code'
              autoComplete='off'
              //   disabled={true}
              {...formik.getFieldProps('customerCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.customerCd && formik.errors.customerCd,
                },
                {
                  'is-valid': formik.touched.customerCd && !formik.errors.customerCd,
                }
              )}
            />
            {formik.touched.customerCd && formik.errors.customerCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.customerCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Currency */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Currency</label>
            <input
              placeholder='Enter Currency'
              autoComplete='off'
              //   disabled={true}
              {...formik.getFieldProps('currency')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.currency && formik.errors.currency,
                },
                {
                  'is-valid': formik.touched.currency && !formik.errors.currency,
                }
              )}
            />
            {formik.touched.currency && formik.errors.currency && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.currency}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Bank Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Bank Code</label>
            <select
              {...formik.getFieldProps('bankCode')}
              className={clsx('form-control bg-transparent')}
              data-placeholder=''
            >
              <option value=''>Select Bank Code</option>
              <option value='nagad'>NAGAD</option>
              <option value='bkash'>BKASH</option>
              <option value='rocket'>ROCKET</option>
            </select>
            {formik.touched.bankCode && formik.errors.bankCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.bankCode}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account Number */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Wallet Acc No</label>
            <input
              placeholder='Enter Wallet Acc No'
              autoComplete='off'
              type='number'
              //   disabled={true}
              {...formik.getFieldProps('walletAccountNo')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletAccountNo && formik.errors.walletAccountNo,
                },
                {
                  'is-valid': formik.touched.walletAccountNo && !formik.errors.walletAccountNo,
                }
              )}
            />
            {formik.touched.walletAccountNo && formik.errors.walletAccountNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccountNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Amount */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Amount</label>
            <input
              placeholder='Enter Amount'
              autoComplete='off'
              type='number'
              //   disabled={true}
              {...formik.getFieldProps('amount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.amount && formik.errors.amount,
                },
                {
                  'is-valid': formik.touched.amount && !formik.errors.amount,
                }
              )}
            />
            {formik.touched.amount && formik.errors.amount && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.amount}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Transaction Timestamp */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Transaction Timestamp
            </label>
            <input
              placeholder='Enter Transaction Timestamp'
              autoComplete='off'
              {...formik.getFieldProps('timestamp')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.timestamp && formik.errors.timestamp,
                },
                {
                  'is-valid': formik.touched.timestamp && !formik.errors.timestamp,
                }
              )}
            />
            {formik.touched.timestamp && formik.errors.timestamp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.timestamp}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Result URL */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Result URL</label>
            <input
              placeholder='Enter Result URL'
              autoComplete='off'
              {...formik.getFieldProps('resultUrl')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.resultUrl && formik.errors.resultUrl,
                },
                {
                  'is-valid': formik.touched.resultUrl && !formik.errors.resultUrl,
                }
              )}
            />
            {formik.touched.resultUrl && formik.errors.resultUrl && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.resultUrl}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Response URL */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Response URL</label>
            <input
              placeholder='Enter Response URL'
              autoComplete='off'
              {...formik.getFieldProps('responseUrl')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.responseUrl && formik.errors.responseUrl,
                },
                {
                  'is-valid': formik.touched.responseUrl && !formik.errors.responseUrl,
                }
              )}
            />
            {formik.touched.responseUrl && formik.errors.responseUrl && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.responseUrl}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-success w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}

export default ManualWithdrawal_Payout
