import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const SISPAY_API = process.env.REACT_APP_SISPAY_API

export const GET_USER_BY_ACCESSTOKEN_URL = `${SISPAY_API}/user/list`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(
    `${SISPAY_API}/user/login`,
    {
      request: {
        username,
        password,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post(GET_USER_BY_ACCESSTOKEN_URL, {
    request: {},
    session: token,
  })
}
