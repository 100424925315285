import {useRef} from 'react'
import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import {useAuth} from '../../../auth'
import {customStyles} from '../../../components/CustomStyles'
import {ErrorHandling} from '../../../components/ErrorHandling'
import {filterArrayByMultipleKeys} from '../../../components/Filter'
import {getCurrentDateInput} from '../../../components/GetCurrentDateInput'
import {searching} from '../../../components/Searching'
import {sortByDate} from '../../../components/SortByDate'
import {Overlay} from '../../../components/Overlay'
import {sortByName} from '../../../components/SortByName'
import {useCreditReloadStore} from './CreditReload_Store'

export function CreditReload_Filter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData
  let creditReloadFilterBtn = props.creditReloadFilterBtn
  let setStartDate = props.setStartDate
  let setEndDate = props.setEndDate

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [agentSelectOptions, setAgentSelectOptions] = useState([])

  //Refs
  const agentSelectRef = useRef<any>()

  //Zustand Store
  const filterOptions = useCreditReloadStore((state: any) => state.filterOptions)
  const getData = useCreditReloadStore((state: any) => state.getData)
  const setFilterOptions = useCreditReloadStore((state: any) => state.setFilterOptions)
  const setGetData = useCreditReloadStore((state: any) => state.setGetData)

  //GET AGENT SELECT OPTIONS
  function getAgentSelectOptions() {
    let result
    axios
      .post(
        `${SISPAY_API}/user/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result.users
          let user: object
          let users: any = []

          for (let i = 0; i < result.length; i++) {
            if (result[i].userType == 'Agent') {
              user = {
                value: result[i].username,
                label: result[i].username,
              }
              users.push(user)
            }
          }

          users = Array.from(new Set(users))
          setAgentSelectOptions(sortByName(users, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  useEffect(() => {
    getAgentSelectOptions()
  }, [])

  const keys = ['id', 'createBy']

  const initialValues = {
    search: filterOptions.search ? String(filterOptions.search) : '',
    username: filterOptions.username ? String(filterOptions.username) : '',
    status: filterOptions.status ? String(filterOptions.status) : '',
    reqType: filterOptions.reqType ? String(filterOptions.reqType) : '',
    dateTimeFrom: filterOptions.dateTimeFrom
      ? String(filterOptions.dateTimeFrom)
      : getCurrentDateInput(),
    dateTimeTo: filterOptions.dateTimeTo ? String(filterOptions.dateTimeTo) : getCurrentDateInput(),
  }

  const filterSchema = Yup.object().shape({
    search: Yup.string(),
    username: Yup.string(),
    status: Yup.string(),
    reqType: Yup.string(),
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)

      let search = values.search.trim()
      let username = values.username.trim()
      let status = values.status.trim()
      let reqType = values.reqType.trim()
      let dateTimeFrom = values.dateTimeFrom.trim()
      let dateTimeTo = values.dateTimeTo.trim()

      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/agent/creditreload/list`,
          {
            request: {
              fromDate: dateTimeFrom,
              toDate: dateTimeTo,
              session: session,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let filteredFormData = filterArrayByMultipleKeys(
            response.data.result.agentCreditReloadReqs,
            ['username', 'status', 'reqType'],
            [username, status, reqType]
          )

          if (search !== '') {
            filteredFormData = searching(filteredFormData, search.toLowerCase(), keys)
          }

          setStartDate(dateTimeFrom)
          setEndDate(dateTimeTo)

          setFormData(sortByDate(filteredFormData, 'requestDT'))

          setDisabled(false)
          setLoading(false)
        })
        .catch(function (error) {})
    },
  })

  useEffect(() => {
    if (getData == false) return

    setDisabled(true)
    setLoading(true)

    let search = formik.values.search.trim()
    let username = formik.values.username.trim()
    let status = formik.values.status.trim()
    let reqType = formik.values.reqType.trim()
    let dateTimeFrom = formik.values.dateTimeFrom.trim()
    let dateTimeTo = formik.values.dateTimeTo.trim()

    axios
      .post(
        `${SISPAY_API}/agent/creditreload/list`,
        {
          request: {
            fromDate: dateTimeFrom,
            toDate: dateTimeTo,
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let filteredFormData = filterArrayByMultipleKeys(
          response.data.result.agentCreditReloadReqs,
          ['username', 'status', 'reqType'],
          [username, status, reqType]
        )

        if (search !== '') {
          filteredFormData = searching(filteredFormData, search.toLowerCase(), keys)
        }

        setStartDate(dateTimeFrom)
        setEndDate(dateTimeTo)

        setFormData(sortByDate(filteredFormData, 'requestDT'))
        setGetData(false)
        setDisabled(false)
        setLoading(false)
      })
      .catch(function (error) {})
  }, [getData])

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-2 py-1'>
          <div className=''>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='row'>
                <div className='col'>
                  {' '}
                  {/* begin::Form group Search */}
                  <div className='d-flex'>
                    <label className='form-label fw-bolder text-dark fs-6'>Search</label>
                    <span>{Overlay('Search by CR Request ID, Create By')}</span>
                  </div>
                  <input
                    placeholder='Enter your search'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('search')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.search && formik.errors.search,
                      },
                      {
                        'is-valid': formik.touched.search && !formik.errors.search,
                      }
                    )}
                  />
                  {formik.touched.search && formik.errors.search && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.search}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}
                <div className='col'>
                  {/* begin::Form group Agent */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Agent</label>
                    <Select
                      ref={agentSelectRef}
                      className='basic-single'
                      classNamePrefix='select'
                      options={agentSelectOptions}
                      styles={customStyles}
                      isClearable={true}
                      name='username'
                      value={
                        formik.values.username
                          ? {value: formik.values.username, label: formik.values.username}
                          : null
                      }
                      onChange={(e: any) =>
                        e !== null
                          ? formik.setFieldValue('username', e.value)
                          : formik.setFieldValue('username', '')
                      }
                    />
                  </div>{' '}
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group Status */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Status</label>
                    <select
                      {...formik.getFieldProps('status')}
                      className={clsx('form-control bg-transparent')}
                      data-placeholder=''
                    >
                      <option value=''>ALL</option>
                      <option value='PENDING'>PENDING</option>
                      <option value='IN-PROGRESS'>IN-PROGRESS</option>
                      <option value='SUCCESSFUL'>SUCCESSFUL</option>
                      <option value='REJECTED'>REJECTED</option>
                    </select>
                  </div>{' '}
                  {/* end::Form group */}
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  {/* begin::Form group Request Type */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Request Type</label>
                    <select
                      {...formik.getFieldProps('reqType')}
                      className={clsx('form-control bg-transparent')}
                      data-placeholder=''
                    >
                      <option value=''>ALL</option>
                      <option value='CREDIT-RELOAD'>CREDIT-RELOAD</option>
                      <option value='CREDIT-ADJUSTMENT'>CREDIT-ADJUSTMENT</option>
                    </select>
                  </div>{' '}
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group DateTime From */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>From</label>
                    <input
                      placeholder='Enter your start time'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('dateTimeFrom')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                        },
                        {
                          'is-valid': formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                        }
                      )}
                    />
                    {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateTimeFrom}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group DateTime To */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>To</label>
                    <input
                      placeholder='Enter your end time'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('dateTimeTo')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                        },
                        {
                          'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                        }
                      )}
                    />
                    {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateTimeTo}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                  {/* end::Form group */}
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      disabled={disabled}
                      onClick={() => {
                        agentSelectRef.current.setValue('')
                        formik.resetForm({
                          values: {
                            search: '',
                            username: '',
                            status: '',
                            reqType: '',
                            dateTimeFrom: getCurrentDateInput(),
                            dateTimeTo: getCurrentDateInput(),
                          },
                        })
                      }}
                    >
                      {!loading && <span className='indicator-label'>Reset</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      ref={creditReloadFilterBtn}
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid || disabled}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>{' '}
      </div>
    </div>
  )
}
