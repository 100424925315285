import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import {getCurrentDateInput} from '../../components/GetCurrentDateInput'
import {sortByDate} from '../../components/SortByDate'
import Select from 'react-select'
import {customStyles} from '../../components/CustomStyles'
import {useAuth} from '../../auth/core/Auth'
import {ErrorHandling} from '../../components/ErrorHandling'
import {sortByName} from '../../components/SortByName'
import {UniqueWalletAccNo} from '../../components/UniqueWalletAccNo'
import Modal from '../../components/Modal'
import {usePendingTransactionStore} from './PendingTransaction_Store'

export function Filter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData
  let currentPage = props.currentPage
  let setCurrentPage = props.setCurrentPage
  let setTotalFormData = props.setTotalFormData
  let transactionPendingFilterBtn = props.transactionPendingFilterBtn
  let downloadLoading = props.downloadLoading
  let setFilterValues = props.setFilterValues

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [wallets, setWallets] = useState(Object)
  const [merchants, setMerchants] = useState([])

  //Refs
  const selectedMerchant = useRef<any>()
  const selectedWalletAccNo = useRef<any>()

  //Zustand Store
  const filterOptions = usePendingTransactionStore((state: any) => state.filterOptions)
  const getData = usePendingTransactionStore((state: any) => state.getData)
  const setFilterOptions = usePendingTransactionStore((state: any) => state.setFilterOptions)
  const setGetData = usePendingTransactionStore((state: any) => state.setGetData)

  const initialValues = {
    search: filterOptions.search ? String(filterOptions.search) : '',
    txnId: filterOptions.txnId ? String(filterOptions.txnId) : '',
    walletAccNo: filterOptions.walletAccNo ? String(filterOptions.walletAccNo) : '',
    merchant: filterOptions.merchant ? String(filterOptions.merchant) : '',
    transType: filterOptions.transType ? String(filterOptions.transType) : '',
    transSts: 'PENDING',
    callbackStatus: filterOptions.callbackStatus ? String(filterOptions.callbackStatus) : '',
    dateTimeFrom: filterOptions.dateTimeFrom
      ? String(filterOptions.dateTimeFrom)
      : getCurrentDateInput(),
    dateTimeTo: filterOptions.dateTimeTo ? String(filterOptions.dateTimeTo) : getCurrentDateInput(),
    senderRecvr: filterOptions.senderRecvr ? String(filterOptions.senderRecvr) : '',
  }

  const filterSchema = Yup.object().shape({
    search: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)
      let search = values.search.trim()
      let walletAccNo = values.walletAccNo.trim()
      let transType = values.transType.trim()
      let txnId = values.txnId.trim()
      let dateTimeFrom = values.dateTimeFrom.trim()
      let dateTimeTo = values.dateTimeTo.trim()
      let merchant = values.merchant.trim()
      let byTransSts = values.transSts.trim()
      let callbackStatus = values.callbackStatus.trim()
      let senderRecvr = values.senderRecvr.trim()

      setFilterValues(values)
      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/transaction/list`,
          {
            request: {
              session: session,
              fromDate: dateTimeFrom,
              toDate: dateTimeTo,
              action: 'list',
              page: '1',
              byTransSts: byTransSts,
              //Filter
              ...(txnId && {byTxnId: txnId}),
              ...(search && {bySrcWalletAcc: search}),
              ...(walletAccNo && {byWalletAccNo: walletAccNo}),
              ...(transType && {byTransType: transType}),
              ...(callbackStatus && {byCallbackStatus: callbackStatus}),
              ...(merchant && {byMerchant: merchant}),
              ...(senderRecvr && {bySenderRecvr: senderRecvr}),
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let formData = response.data.result

          setFormData(sortByDate(formData.TransactionList, 'receivedDt'))
          setTotalFormData(formData.totalCount)

          setLoading(false)
          setDisabled(false)
          if (currentPage !== 0) {
            setCurrentPage(1)
          }
        })
        .catch(function (error) {})
    },
  })

  useEffect(() => {
    if (getData == false && currentPage == 0) return

    if (getData == true) {
      setCurrentPage(1)
    }

    setDisabled(true)
    setLoading(true)

    let search = formik.values.search.trim()
    let walletAccNo = formik.values.walletAccNo.trim()
    let transType = formik.values.transType.trim()
    let txnId = formik.values.txnId.trim()
    let dateTimeFrom = formik.values.dateTimeFrom.trim()
    let dateTimeTo = formik.values.dateTimeTo.trim()
    let merchant = formik.values.merchant.trim()
    let callbackStatus = formik.values.callbackStatus.trim()
    let senderRecvr = formik.values.senderRecvr.trim()

    axios
      .post(
        `${SISPAY_API}/transaction/list`,
        {
          request: {
            session: session,
            fromDate: dateTimeFrom,
            toDate: dateTimeTo,
            action: 'list',
            page: currentPage,
            byTransSts: 'PENDING',
            //Filter
            ...(txnId && {byTxnId: txnId}),
            ...(search && {bySrcWalletAcc: search}),
            ...(walletAccNo && {byWalletAccNo: walletAccNo}),
            ...(transType && {byTransType: transType}),
            ...(callbackStatus && {byCallbackStatus: callbackStatus}),
            ...(merchant && {byMerchant: merchant}),
            ...(senderRecvr && {bySenderRecvr: senderRecvr}),
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let formData = response.data.result

        setFormData(sortByDate(formData.TransactionList, 'receivedDt'))
        setTotalFormData(formData.totalCount)

        setGetData(false)
        setLoading(false)

        setDisabled(false)
      })
      .catch(function (error) {})
  }, [currentPage, getData])

  //ABSTRACT FUNCTION: Retrieve data from API
  function retrieveWalletAccNo(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          // Extract walletAccNo and form a new array of JSON objects
          let walletAccNoList = UniqueWalletAccNo(result)

          setWallets(sortByName(walletAccNoList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  //ABSTRACT FUNCTION: Retrieve data from API
  function retrieveTransactionType(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            status: 'PENDING',
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]
          //Get Merchant List
          const uniqueMerchantCds = new Set()
          const merchantList = result.reduce((list: any, item: any) => {
            if (!uniqueMerchantCds.has(item.merchantCd) && item.merchantCd !== '') {
              uniqueMerchantCds.add(item.merchantCd)
              list.push({
                value: item.merchantCd,
                label: item.merchantName + ' - ' + item.merchantCd,
              })
            }
            return list
          }, [])

          setMerchants(sortByName(merchantList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  //GET USER LIST
  useEffect(() => {
    retrieveWalletAccNo(SISPAY_API, '/wallet/list', 'wallet')
    retrieveTransactionType(SISPAY_API, '/merchant/list', 'merchant')
  }, [])

  return (
    <div>
      <div className='card card-custom card-flush mb-5'>
        <div className='menu d-flex flex-column'>
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          </div>

          <div className='separator border-gray-200'></div>

          <div className='px-2 py-1'>
            <div className='mb-10'>
              <form onSubmit={formik.handleSubmit} className='m-5'>
                {' '}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      {/* begin::Form group Search */}
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Source Wallet Acc
                      </label>
                      <input
                        placeholder='Enter Source Wallet Acc'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('search')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.search && formik.errors.search,
                          },
                          {
                            'is-valid': formik.touched.search && !formik.errors.search,
                          }
                        )}
                      />
                      {formik.touched.search && formik.errors.search && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.search}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                    <div className='col'>
                      {/* begin::Form group Search */}
                      <label className='form-label fw-bolder text-dark fs-6'>Sender/Receiver</label>
                      <input
                        placeholder='Enter Sender/Receiver'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('senderRecvr')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.senderRecvr && formik.errors.senderRecvr,
                          },
                          {
                            'is-valid': formik.touched.senderRecvr && !formik.errors.senderRecvr,
                          }
                        )}
                      />
                      {formik.touched.senderRecvr && formik.errors.senderRecvr && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.senderRecvr}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}

                    <div className='col'>
                      {/* begin::Form group TxnID */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>TxnID</label>
                        <input
                          placeholder='Enter your search'
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('txnId')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': formik.touched.txnId && formik.errors.txnId,
                            },
                            {
                              'is-valid': formik.touched.txnId && !formik.errors.txnId,
                            }
                          )}
                        />
                        {formik.touched.txnId && formik.errors.txnId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.txnId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* end::Form group */}
                    </div>

                    <div className='col'>
                      {/* begin::Form group Wallet Account Number */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Wallet Account Number
                        </label>

                        <Select
                          ref={selectedWalletAccNo}
                          className='basic-single'
                          classNamePrefix='select'
                          name='walletAccNo'
                          options={wallets}
                          isClearable={true}
                          styles={customStyles}
                          value={
                            formik.values.walletAccNo
                              ? {value: formik.values.walletAccNo, label: formik.values.walletAccNo}
                              : null
                          }
                          onChange={(e: any) =>
                            e !== null
                              ? formik.setFieldValue('walletAccNo', e.value)
                              : formik.setFieldValue('walletAccNo', '')
                          }
                        />
                      </div>{' '}
                      {/* end::Form group */}
                    </div>

                    <div className='col'>
                      {/* begin::Form group Merchant List */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Merchant</label>
                        <div className='col'>
                          <Select
                            ref={selectedMerchant}
                            className='basic-single'
                            classNamePrefix='select'
                            name='merchants'
                            isClearable={true}
                            options={merchants}
                            styles={customStyles}
                            value={
                              formik.values.merchant
                                ? {value: formik.values.merchant, label: formik.values.merchant}
                                : null
                            }
                            onChange={(e: any) =>
                              e !== null
                                ? formik.setFieldValue('merchant', e.value)
                                : formik.setFieldValue('merchant', '')
                            }
                          />
                        </div>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='row'>
                      <div className='col'>
                        {/* begin::Form group */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            Callback Status
                          </label>
                          <div
                            style={{
                              color: 'black',
                            }}
                          >
                            <select
                              {...formik.getFieldProps('callbackStatus')}
                              className={clsx('form-control bg-transparent')}
                              data-placeholder=''
                            >
                              <option value=''>ALL</option>
                              <option value='none'>NONE</option>
                              <option value='Success'>SUCCESS</option>
                              <option value='Sent'>SENT</option>
                            </select>{' '}
                          </div>
                        </div>{' '}
                        {/* end::Form group */}
                      </div>
                      <div className='col'>
                        {/* begin::Form group Transaction Type */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            Transaction Type
                          </label>
                          <select
                            {...formik.getFieldProps('transType')}
                            className={clsx('form-control bg-transparent')}
                            data-placeholder=''
                          >
                            <option value=''>ALL</option>
                            <option value='DEPOSIT'>DEPOSIT</option>
                            <option value='WITHDRAWAL'>WITHDRAWAL</option>
                            <option value='B2B RECEIVE'>B2B RECEIVE</option>
                            <option value='B2B SEND'>B2B SEND</option>
                          </select>
                        </div>{' '}
                        {/* end::Form group */}
                      </div>
                      <div className='col'>
                        {/* begin::Form group DateTime From */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fw-bolder text-dark fs-6'>From</label>
                          <input
                            placeholder='Enter your start time'
                            type='date'
                            autoComplete='off'
                            {...formik.getFieldProps('dateTimeFrom')}
                            className={clsx(
                              'form-control bg-transparent',
                              {
                                'is-invalid':
                                  formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                              },
                              {
                                'is-valid':
                                  formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                              }
                            )}
                          />
                          {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.dateTimeFrom}</span>
                              </div>
                            </div>
                          )}
                        </div>{' '}
                        {/* end::Form group */}
                      </div>
                      <div className='col'>
                        {/* begin::Form group DateTime To */}
                        <div className='fv-row mb-5'>
                          <label className='form-label fw-bolder text-dark fs-6'>To</label>
                          <input
                            placeholder='Enter your end time'
                            type='date'
                            autoComplete='off'
                            {...formik.getFieldProps('dateTimeTo')}
                            className={clsx(
                              'form-control bg-transparent',
                              {
                                'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                              },
                              {
                                'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                              }
                            )}
                          />
                          {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.dateTimeTo}</span>
                              </div>
                            </div>
                          )}
                        </div>{' '}
                        {/* end::Form group */}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col'></div>
                    </div>
                    <div className='row'>
                      <div className='col'></div>
                      <div className='col'></div>
                      <div className='col'>
                        {/* begin::Form group */}
                        <div className='text-center'>
                          <button
                            className='btn btn-lg btn-light w-100 mb-5'
                            disabled={disabled || downloadLoading}
                            onClick={() => {
                              selectedMerchant.current.setValue('')
                              selectedWalletAccNo.current.setValue('')
                              formik.resetForm({
                                values: {
                                  search: '',
                                  txnId: '',
                                  walletAccNo: '',
                                  merchant: '',
                                  transType: '',
                                  transSts: 'PENDING',
                                  callbackStatus: '',
                                  dateTimeFrom: getCurrentDateInput(),
                                  dateTimeTo: getCurrentDateInput(),
                                  senderRecvr: '',
                                },
                              })
                            }}
                          >
                            {!loading && <span className='indicator-label'>Reset</span>}
                            {loading && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>
                        {/* end::Form group */}
                      </div>
                      <div className='col'>
                        {/* begin::Form group */}
                        <div className='text-center'>
                          <button
                            ref={transactionPendingFilterBtn}
                            type='submit'
                            id='kt_sign_up_submit'
                            className='btn btn-lg btn-primary w-100 mb-5'
                            disabled={
                              formik.isSubmitting || !formik.isValid || disabled || downloadLoading
                            }
                          >
                            {!loading && <span className='indicator-label'>Submit</span>}
                            {loading && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>
                        {/* end::Form group */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {Modal(loading)}
    </div>
  )
}
