import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useAuth} from '../../auth/core/Auth'
import axios from 'axios'
import {ErrorHandling} from '../../components/ErrorHandling'
import getTimestampInSec from '../../components/GetTimeInSeconds'
import {useNavigate} from 'react-router-dom'
import getTimestamp from '../../components/GetTimestamp'
import Select from 'react-select'
import {sortByName} from '../../components/SortByName'
import {customStyles} from '../../components/CustomStyles'
import sha1 from 'crypto-js/sha1'
import {UniqueWalletAccNo} from '../../components/UniqueWalletAccNo'

const DepositManagementPage_BO: React.FC = () => {
  const {logout} = useAuth()
  const navigate = useNavigate()
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //States
  const [loading, setLoading] = useState(false)
  const [merchants, setMerchants] = useState([])
  const [merchantCdList, setMerchantCdList] = useState([])
  const [wallets, setWallets] = useState([])

  //Get Data
  function retrieveMerchant(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          let merchantCdList: any = Array.from(
            new Set(
              result
                .filter((item: any) => item.merchantCd !== '')
                .map((item: any) => {
                  return {
                    value: item.merchantCd,
                    label: item.merchantName + ' - ' + item.merchantCd,
                  }
                })
            )
          )
          setMerchants(result)
          setMerchantCdList(sortByName(merchantCdList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  //ABSTRACT FUNCTION: Retrieve data from API
  function getWallets(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let walletAccNoList = response.data.result[`${location}`]
          // Extract walletAccNo and form a new array of JSON objects

          walletAccNoList = walletAccNoList.filter(
            (item: any) => item.status == 'ACTIVE' || item.status == 'INACTIVE'
          )

          // Remove duplicate walletAccNo
          walletAccNoList = walletAccNoList.filter(
            (item: any, index: number, self: any[]) =>
              index === self.findIndex((t) => t.walletAccNo === item.walletAccNo)
          )

          console.log(walletAccNoList)

          setWallets(sortByName(walletAccNoList, 'walletAccName'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    retrieveMerchant(SISPAY_API, '/merchant/list', 'merchant')
    getWallets(SISPAY_API, '/wallet/list', 'wallet')
  }, [])

  function SD_Generate() {
    let MerchantCode = formik.values.merchantCd
    let CustomerCode = formik.values.customerCd
    let CurrencyCode = formik.values.currency
    let SecureCode = formik.values.secureCd
    let BankCode = formik.values.bankCode
    let Amount: any = formik.values.amount
    let TimeStamp = formik.values.timestamp

    let combinedString = `${Amount}${BankCode}${CurrencyCode}${SecureCode}${MerchantCode}${CustomerCode}${TimeStamp}`
    let signature = sha1(combinedString).toString()
    formik.setFieldValue('signature', signature)
    return signature
  }

  const initialValues = {
    depositType: 'singleDeposit',
    merchantCd: '',
    secureCd: '',
    customerCd: 'CC' + getTimestampInSec(),
    phoneNo: '0000000',
    currency: 'BDT',
    bankCode: '',
    amount: '',
    languageCode: 'EN',
    sourceAccNo: '',
    sourceAccOptions: '',
    transId: 'TID' + getTimestampInSec(),
    reference: '',
    timestamp: getTimestamp(),
    clientIp: '0.0.0.0',
    signature: '',
    responseUrl: 'www.google.com',
    resultUrl: 'www.google.com',
    submittedTransId: '',
    walletAccNo: '',
  }

  const ManualDepositCreateSchema = Yup.object().shape({
    merchantCd: Yup.string().required('Merchant Code is required'),
    // secureCd: Yup.string(),
    customerCd: Yup.string().required('Customer Code is required'),
    phoneNo: Yup.string().required('Phone Number is required'),
    currency: Yup.string().required('Currency is required'),
    bankCode: Yup.string().required('Bank Code is required'),
    amount: Yup.string().required('Amount is required'),
    languageCode: Yup.string().required('Language Code is required'),
    sourceAccNo: Yup.string().when('depositType', {
      is: 'singleDeposit',
      then: Yup.string().required('Source Acc No is required'),
    }),
    sourceAccOptions: Yup.string().when('depositType', {
      is: 'multiDeposit',
      then: Yup.string().required('Source Acc Options is required'),
    }),
    transId: Yup.string().required('Transaction ID is required'),
    reference: Yup.string(),
    timestamp: Yup.string().required('Timestamp is required'),
    clientIp: Yup.string().required('Client IP is required'),
    walletAccNo: Yup.string().required('Wallet Account No is required'),
    responseUrl: Yup.string().required('Response URL is required'),
    resultUrl: Yup.string().required('Result URL is required'),
    submittedTransId: Yup.string().required('Submitted Transaction ID is required'),
  })

  const formik = useFormik({
    initialValues,
    // enableReinitialize: true,
    validationSchema: ManualDepositCreateSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      let data: any = {
        session: session,
        MerchantCode: values.merchantCd,
        CustomerCode: values.customerCd,
        PhoneNumber: values.phoneNo.toString(),
        Currency: values.currency,
        BankCode: values.bankCode,
        Amount: Number(values.amount).toFixed(2).toString(),
        LanguageCode: values.languageCode,
        MerchantTransID: values.transId,
        Reference: values.reference,
        TransactionTimeStamp: values.timestamp,
        ClientIP: values.clientIp,
        Signature: SD_Generate(),
        SubmittedTransID: values.submittedTransId,
        WalletAccountNo: values.walletAccNo,
        ResponseURL: values.responseUrl,
        ResultURL: values.resultUrl,
      }

      if (values.depositType == 'singleDeposit') {
        data.SourceAccNo = values.sourceAccNo
        data.SourceAccountOption = null
      } else if (values.depositType == 'multiDeposit') {
        data.SourceAccNo = values.sourceAccNo
        data.SourceAccountOption = values.sourceAccOptions
      }

      axios
        .post(
          `${SISPAY_API}/cash/depositrequest/admin/create`,
          {
            request: data,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 200) {
            setLoading(false)
            navigate(-1)
            alert(response.data.message)
          } else {
            setLoading(false)
            alert(response.data.message)
            // ErrorHandling(response, logout)
          }
        })
        .catch(function (error) {
          alert(error)
        })
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-between'></div>{' '}
      <div className='card card-custom card-flush mt-5'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group Deposit Type */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Deposit Type</label>
            <select
              {...formik.getFieldProps('depositType')}
              className={clsx('form-control bg-transparent')}
              data-placeholder=''
            >
              <option value='singleDeposit'>Single Source</option>
              <option value='multiDeposit'>Multiple Source</option>
            </select>
            {formik.touched.depositType && formik.errors.depositType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.depositType}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Merchant Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Merchant Code</label>
            <Select
              className='basic-single'
              classNamePrefix='select'
              options={merchantCdList}
              styles={customStyles}
              isClearable={true}
              name='merchantCd'
              onChange={(e: any) => {
                if (e !== null) {
                  formik.setFieldValue('merchantCd', e.value)
                  const secureCd = merchants
                    .filter((item: any) => item.merchantCd === e.value)
                    .map((item: any) => item.secureCd)
                  formik.setFieldValue('secureCd', secureCd)
                } else {
                  formik.setFieldValue('merchantCd', '')
                  formik.setFieldValue('secureCd', '')
                }
              }}
            />
            {formik.touched.merchantCd && formik.errors.merchantCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.merchantCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Secure Code */}
          <div className='fv-row mb-8 d-none'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Secure Code</label>
            <input
              placeholder='Enter Secure Code'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('secureCd')}
              value={merchants
                .filter((item: any) => item.merchantCd === formik.values.merchantCd)
                .map((item: any) => item.secureCd)}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.secureCd && formik.errors.secureCd,
                },
                {
                  'is-valid': formik.touched.secureCd && !formik.errors.secureCd,
                }
              )}
            />
            {formik.touched.secureCd && formik.errors.secureCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.secureCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Customer Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Customer Code</label>
            <input
              placeholder='Enter Customer Code'
              autoComplete='off'
              //   disabled={true}
              {...formik.getFieldProps('customerCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.customerCd && formik.errors.customerCd,
                },
                {
                  'is-valid': formik.touched.customerCd && !formik.errors.customerCd,
                }
              )}
            />
            {formik.touched.customerCd && formik.errors.customerCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.customerCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Phone Number */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Phone Number</label>
            <input
              placeholder='Enter Phone Number'
              autoComplete='off'
              type='number'
              //   disabled={true}
              {...formik.getFieldProps('phoneNo')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.phoneNo && formik.errors.phoneNo,
                },
                {
                  'is-valid': formik.touched.phoneNo && !formik.errors.phoneNo,
                }
              )}
            />
            {formik.touched.phoneNo && formik.errors.phoneNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phoneNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Currency */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Currency</label>
            <input
              placeholder='Enter Currency'
              autoComplete='off'
              //   disabled={true}
              {...formik.getFieldProps('currency')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.currency && formik.errors.currency,
                },
                {
                  'is-valid': formik.touched.currency && !formik.errors.currency,
                }
              )}
            />
            {formik.touched.currency && formik.errors.currency && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.currency}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Bank Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Bank Code</label>
            <select
              {...formik.getFieldProps('bankCode')}
              className={clsx('form-control bg-transparent')}
              data-placeholder=''
            >
              <option value=''>Select Bank Code</option>
              <option value='nagad'>NAGAD</option>
              <option value='bkash'>BKASH</option>
              <option value='rocket'>ROCKET</option>
            </select>
            {formik.touched.bankCode && formik.errors.bankCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.bankCode}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Amount */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Amount</label>
            <input
              placeholder='Enter Amount'
              autoComplete='off'
              type='number'
              //   disabled={true}
              {...formik.getFieldProps('amount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.amount && formik.errors.amount,
                },
                {
                  'is-valid': formik.touched.amount && !formik.errors.amount,
                }
              )}
            />
            {formik.touched.amount && formik.errors.amount && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.amount}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Language Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Language Code</label>
            <input
              placeholder='Enter Language Code'
              autoComplete='off'
              {...formik.getFieldProps('languageCode')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.languageCode && formik.errors.languageCode,
                },
                {
                  'is-valid': formik.touched.languageCode && !formik.errors.languageCode,
                }
              )}
            />
            {formik.touched.languageCode && formik.errors.languageCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.languageCode}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Source Account Number */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Source Account Number
            </label>
            <input
              placeholder='0000000'
              autoComplete='off'
              // disabled={formik.values.depositType == 'singleDeposit' ? false : true}
              {...formik.getFieldProps('sourceAccNo')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.sourceAccNo && formik.errors.sourceAccNo,
                },
                {
                  'is-valid': formik.touched.sourceAccNo && !formik.errors.sourceAccNo,
                }
              )}
            />
            {formik.touched.sourceAccNo && formik.errors.sourceAccNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.sourceAccNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Source Account Options */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Source Account Options
            </label>
            <input
              placeholder='0000000|1111111|2222222'
              autoComplete='off'
              disabled={formik.values.depositType == 'multiDeposit' ? false : true}
              {...formik.getFieldProps('sourceAccOptions')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.sourceAccOptions && formik.errors.sourceAccOptions,
                },
                {
                  'is-valid': formik.touched.sourceAccOptions && !formik.errors.sourceAccOptions,
                }
              )}
            />
            {formik.touched.sourceAccOptions && formik.errors.sourceAccOptions && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.sourceAccOptions}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Merchant Transaction ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Merchant Transaction ID
            </label>
            <input
              placeholder='Enter Merchant Transaction ID'
              autoComplete='off'
              {...formik.getFieldProps('transId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.transId && formik.errors.transId,
                },
                {
                  'is-valid': formik.touched.transId && !formik.errors.transId,
                }
              )}
            />
            {formik.touched.transId && formik.errors.transId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.transId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Reference */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Reference</label>
            <input
              placeholder='Enter Reference'
              autoComplete='off'
              {...formik.getFieldProps('reference')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.reference && formik.errors.reference,
                },
                {
                  'is-valid': formik.touched.reference && !formik.errors.reference,
                }
              )}
            />
            {formik.touched.reference && formik.errors.reference && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.reference}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Timestamp */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Timestamp</label>
            <input
              placeholder='Enter Timestamp'
              autoComplete='off'
              {...formik.getFieldProps('timestamp')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.timestamp && formik.errors.timestamp,
                },
                {
                  'is-valid': formik.touched.timestamp && !formik.errors.timestamp,
                }
              )}
            />
            {formik.touched.timestamp && formik.errors.timestamp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.timestamp}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Client IP */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Client IP</label>
            <input
              placeholder='Enter Client IP'
              autoComplete='off'
              {...formik.getFieldProps('clientIp')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.clientIp && formik.errors.clientIp,
                },
                {
                  'is-valid': formik.touched.clientIp && !formik.errors.clientIp,
                }
              )}
            />
            {formik.touched.clientIp && formik.errors.clientIp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.clientIp}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account No */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Wallet Account No
            </label>
            <select
              {...formik.getFieldProps('walletAccNo')}
              className={clsx('form-control', {
                'bg-grey': formik.values.merchantCd === '',
                'bg-transparent': formik.values.merchantCd !== '',
              })}
              data-placeholder=''
              disabled={formik.values.merchantCd == '' ? true : false}
            >
              <option value=''>Select Wallet Acc No</option>
              {wallets
                .filter((item: any) => item.merchantName.includes(formik.values.merchantCd))
                .map((item: any, index: number) => (
                  <option key={index} value={item.walletAccNo}>
                    {item.walletAccName + ' - ' + item.walletAccNo}
                  </option>
                ))}
            </select>
            {formik.touched.walletAccNo && formik.errors.walletAccNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Submitted Trans ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Submitted Transaction ID
            </label>
            <input
              placeholder='Enter Submitted Transaction ID'
              autoComplete='off'
              {...formik.getFieldProps('submittedTransId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.submittedTransId && formik.errors.submittedTransId,
                },
                {
                  'is-valid': formik.touched.submittedTransId && !formik.errors.submittedTransId,
                }
              )}
            />
            {formik.touched.submittedTransId && formik.errors.submittedTransId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.submittedTransId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Response URL */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Response URL</label>
            <input
              placeholder='Enter Response URL'
              autoComplete='off'
              {...formik.getFieldProps('responseUrl')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.responseUrl && formik.errors.responseUrl,
                },
                {
                  'is-valid': formik.touched.responseUrl && !formik.errors.responseUrl,
                }
              )}
            />
            {formik.touched.responseUrl && formik.errors.responseUrl && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.responseUrl}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Result URL */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Result URL</label>
            <input
              placeholder='Enter Backend URL'
              autoComplete='off'
              {...formik.getFieldProps('resultUrl')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.resultUrl && formik.errors.resultUrl,
                },
                {
                  'is-valid': formik.touched.resultUrl && !formik.errors.resultUrl,
                }
              )}
            />
            {formik.touched.resultUrl && formik.errors.resultUrl && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.resultUrl}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-success w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}

export default DepositManagementPage_BO
