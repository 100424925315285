import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import {customStyles} from '../../../components/CustomStyles'
import {filterArrayByMultipleKeys} from '../../../components/Filter'
import {getCurrentDateInput} from '../../../components/GetCurrentDateInput'
import {searching} from '../../../components/Searching'
import {sortByDate} from '../../../components/SortByDate'
import {Overlay} from '../../../components/Overlay'

export function Filter(props: any) {
  let setFormData = props.setFormData
  let setSelectedArray = props.setSelectedArray
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const [loading, setLoading] = useState(false)
  const [walletCdList, setwalletCdList] = useState(Object)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const selectedWalletCd = useRef<any>(null)

  const initialValues = {
    searchTransaction: '',
    searchCustomer: '',
    searchAmount: '',
    transactionType: '',
    transactionStatus: '',
    walletCd: '',
    dateTimeFrom: getCurrentDateInput(),
    dateTimeTo: getCurrentDateInput(),
  }

  const filterSchema = Yup.object().shape({
    searchTransaction: Yup.string(),
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const keysSearchsearchCustomer = ['customerCd', 'destinationWalletAcc', 'destinationWalletName']
  //const keysSearchTransaction = ['merchantTxnId']

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      let searchTransaction = values.searchTransaction
      let searchCustomer = values.searchCustomer
      let searchAmount = values.searchAmount
      let transactionType = values.transactionType
      let transactionStatus = values.transactionStatus
      let walletCd = values.walletCd
      let dateTimeFrom = values.dateTimeFrom
      let dateTimeTo = values.dateTimeTo

      axios
        .post(
          `${SISPAY_API}/cash/withdrawalrequest/listpending`,
          {
            request: {
              session: session,
              status: 'PENDING',
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let filteredFormData = filterArrayByMultipleKeys(
            response.data.result.depositRequestList,
            ['searchAmount'],
            [searchAmount]
          )

          if (searchTransaction !== '') {
            filteredFormData = searching(
              filteredFormData,
              searchTransaction.toLowerCase(),
              keysSearchsearchCustomer
            )
          }

          // if (searchTransaction !== '') {
          //   filteredFormData = searching(
          //     filteredFormData,
          //     searchTransaction.toLowerCase(),
          //     keysSearchTransaction
          //   )
          // }

          //Filter Date
          if (dateTimeFrom !== '' && dateTimeTo != '') {
            const startingDate = dateTimeFrom.substring(0, 10)
            const endingDate = dateTimeTo.substring(0, 10)

            filteredFormData = filteredFormData.filter((data) => {
              const withdrawalDT = data.withdrawalDT.substring(0, 10)
              return withdrawalDT >= startingDate && withdrawalDT <= endingDate
            })
          }

          setFormData(sortByDate(filteredFormData, 'withdrawalDT'))
          //setFormData(filteredFormData)
          setLoading(false)
        })
        .catch(function (error) {})
    },
  })

  //ABSTRACT FUNCTION: Retrieve data from API
  function retreieveWithdrawalList(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let result = response.data.result[`${location}`]
        // Extract merchantCd and form a new array of JSON objects
        let walletCdList: any = Array.from(
          new Set(result.map((request: any) => request.merchantCd))
        )
          .filter((merchantCd) => merchantCd !== '')
          .map((request) => {
            return {value: request, label: request}
          })

        setwalletCdList(walletCdList)
      })
      .catch(function (error) {})
  }

  //GET USER LIST
  useEffect(() => {
    retreieveWithdrawalList(SISPAY_API, '/cash/withdrawalrequest/list', 'depositRequestList')
  }, [])

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-2 py-1'>
          <div className='mb-10'>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='fv-row mb-5'>
                {' '}
                <div className='row'>
                  <div className='col'>
                    {/* begin::Form group*/}
                    <div className='fv-row mb-5'>
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Search Transaction
                        </label>
                        {Overlay('SEARCH Submitted Transaction ID, Transaction ID')}
                      </div>
                      <input
                        placeholder='SEARCH Submitted Transaction ID, Transaction ID'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('searchTransaction')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid':
                              formik.touched.searchTransaction && formik.errors.searchTransaction,
                          },
                          {
                            'is-valid':
                              formik.touched.searchTransaction && !formik.errors.searchTransaction,
                          }
                        )}
                      />
                      {formik.touched.searchTransaction && formik.errors.searchTransaction && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.searchTransaction}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group*/}
                    <div className='fv-row mb-5'>
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Search Customer
                        </label>
                        {Overlay('SEARCH Customer Code, Source Wallet Account')}
                      </div>
                      <input
                        placeholder='SEARCH Customer Code, Source Wallet Account'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('searchCustomer')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid':
                              formik.touched.searchCustomer && formik.errors.searchCustomer,
                          },
                          {
                            'is-valid':
                              formik.touched.searchCustomer && !formik.errors.searchCustomer,
                          }
                        )}
                      />
                      {formik.touched.searchCustomer && formik.errors.searchCustomer && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.searchCustomer}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group*/}
                    <div className='fv-row mb-5'>
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>Search Amount</label>
                        {Overlay('SEARCH Amount')}
                      </div>
                      <input
                        placeholder='SEARCH Amount'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('searchAmount')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.searchAmount && formik.errors.searchAmount,
                          },
                          {
                            'is-valid': formik.touched.searchAmount && !formik.errors.searchAmount,
                          }
                        )}
                      />
                      {formik.touched.searchAmount && formik.errors.searchAmount && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.searchAmount}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='row'>
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Transaction Type
                        </label>

                        <div
                          style={{
                            color: 'black',
                          }}
                        >
                          <select
                            {...formik.getFieldProps('transactionType')}
                            className={clsx('form-control bg-transparent')}
                            data-placeholder=''
                          >
                            <option value=''>Select Transaction Type</option>
                            <option value='DEPOSIT'>DEPOSIT</option>
                            <option value='WITHDRAWAL'>WITHDRAWAL</option>
                          </select>{' '}
                        </div>
                      </div>{' '}
                    </div>
                    <div className='col'>
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Transaction Status
                        </label>

                        <div
                          style={{
                            color: 'black',
                          }}
                        >
                          <select
                            {...formik.getFieldProps('transactionStatus')}
                            className={clsx('form-control bg-transparent')}
                            data-placeholder=''
                          >
                            <option value=''>ALL</option>
                            <option value='PENDING'>PENDING</option>
                            <option value='APPROVED'>APPROVED</option>
                            <option value='REJECTED'>REJECTED</option>
                            <option value='CANCELLED'>CANCELLED</option>
                          </select>{' '}
                        </div>
                      </div>{' '}
                    </div>
                    {/* end::Form group */}
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>

                        <div>
                          <Select
                            ref={selectedWalletCd}
                            className='basic-single'
                            classNamePrefix='select'
                            options={walletCdList}
                            styles={customStyles}
                            name='walletCd'
                            onChange={(e: any) => formik.setFieldValue('walletCd', e.value)}
                          />
                        </div>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                  </div>
                </div>
                <div className='col'></div>
              </div>
              <div className='row'>
                <div className='col'>
                  {/* begin::Form group DateTime From */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>From</label>
                    <input
                      placeholder='Enter your start time'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('dateTimeFrom')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                        },
                        {
                          'is-valid': formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                        }
                      )}
                    />
                    {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateTimeFrom}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group DateTime To */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>To</label>
                    <input
                      placeholder='Enter your end time'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('dateTimeTo')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                        },
                        {
                          'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                        }
                      )}
                    />
                    {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateTimeTo}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                  {/* end::Form group */}
                  <div className='col'></div>
                  <div className='col'></div>
                  <div className='col'></div>
                </div>
                <div className='row'>
                  <div className='col'></div>
                  <div className='col'></div>
                  <div className='col'></div> <div className='col'></div>
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      //disabled={formik.isSubmitting}
                      onClick={() => {
                        setSelectedArray([])
                        selectedWalletCd.current.setValue('')
                        formik.resetForm()
                      }}
                    >
                      {<span className='indicator-label'>Reset</span>}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
