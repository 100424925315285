import React, {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'
import Select from 'react-select'
import {customStyles} from '../../../components/CustomStyles'
import {ErrorHandling} from '../../../components/ErrorHandling'
import {useAuth} from '../../../auth'
import FormatNumberTo2Decimals from '../../../components/FormatNumberTo2Decimals'
import {sortByName} from '../../../components/SortByName'

const CommissionWithdrawal_Comm_Create: React.FC = () => {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const navigate = useNavigate()
  const {logout} = useAuth()
  const [loading, setLoading] = useState(false)
  const [usernameList, setUsernameList] = useState([])

  function getUserList(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]
          let user: object
          let users: any = []

          for (let i = 0; i < result.length; i++) {
            if (result[i].userType == 'Reseller' || result[i].userType == 'Agent') {
              user = {
                value: result[i].username,
                label: result[i].username,
              }
              users.push(user)
            }
          }

          users = Array.from(new Set(users))

          users = sortByName(users, 'value')

          setUsernameList(users)
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  useEffect(() => {
    getUserList(SISPAY_API, '/user/list', 'users')
    axios
      .post(
        `${SISPAY_API}/wallet/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result.wallet
          let uniqueWallets: any = []
          let seenValues = new Set()

          for (let i = 0; i < result.length; i++) {
            if (result[i].status !== 'DEACTIVE') {
              const walletAccName = result[i].walletAccName
              const walletAccNo = result[i].walletAccNo
              const walletCd = result[i].walletCd
              if (!seenValues.has(walletAccNo)) {
                seenValues.add(walletAccNo)
                uniqueWallets.push({
                  value: walletAccNo,
                  label: walletAccName + ' - ' + walletAccNo,
                  walletCd: walletCd,
                })
              }
            }
          }
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }, [])

  const initialValues = {
    username: '',
    session: session,
    amount: 0,
    transType: '', // DIRECT or CREDIT-CONVERSION
    destWalletAcc: '',
    destWalletCd: '',
  }

  const creditReloadCreateSchema = Yup.object().shape({
    username: Yup.string().required('Username is required.'),
    transType: Yup.string().required('Request Type is required.'),
    amount: Yup.number()
      .min(1, 'Minimum 1')
      .required('Amount is required.')
      .test('is-decimal', 'Amount must have 2 decimal places.', (value) => {
        if (value !== undefined) {
          // Modify the validation to check if the value has up to 2 decimal places
          return /^\d+(\.\d{0,2})?$/.test(value.toString())
        }
        return true // If value is undefined, no validation error
      }),
    destWalletCd: Yup.string().when('transType', {
      is: (val: string) => val === 'DIRECT',
      then: Yup.string().required('Destination Wallet Code is required.'),
    }),
    destWalletAcc: Yup.string().when('transType', {
      is: (val: string) => val === 'DIRECT',
      then: Yup.string().required('Destination Wallet Account is required.'),
    }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: creditReloadCreateSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        axios
          .post(
            `${SISPAY_API}/agent/commwithdrawal/create`,
            {
              request: {
                username: values.username,
                session: values.session,
                amount: values.amount,
                transType: values.transType, // DIRECT or CREDIT-CONVERSION
                destWalletAcc: values.destWalletAcc,
                destWalletCd: values.destWalletCd,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response) {
            if (response.data.code == '200') {
              setLoading(false)
              navigate(-1)
              alert(`Commission Withdrawal has been submmited successfully.`)
            } else {
              alert(response.data.message)
              setLoading(false)
            }
          })
          .catch(function (error) {})
      } catch (error) {}
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
        <span className='text-gray-800 fs-1 fw-bolder ms-5 d-flex align-items-center'>
          Create Commission Conversion / Withdrawal
        </span>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          <div className='row'>
            <div className='col'>
              <div className='fv-row mb-8'>
                <label className='form-label required fw-bolder text-dark fs-6'>Username</label>
                <Select
                  // ref={destWalletAccRef}
                  className='basic-single'
                  classNamePrefix='select'
                  options={usernameList}
                  styles={customStyles}
                  isClearable={true}
                  name='username'
                  onBlur={() => {
                    formik.setFieldTouched('username')
                  }}
                  onChange={(e: any) =>
                    e !== null
                      ? formik.setFieldValue('username', e.value)
                      : formik.setFieldValue('username', '')
                  }
                />
              </div>
              {/* begin::Form group Request Type */}
              <div className='fv-row mb-8'>
                <label className='form-label required fw-bolder text-dark fs-6'>Request Type</label>
                <select
                  {...formik.getFieldProps('transType')}
                  onChange={(e) => {
                    formik.setFieldValue('transType', e.target.value)
                    formik.setFieldValue('destWalletCd', '')
                    formik.setFieldValue('destWalletAcc', '')
                  }}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.transType && formik.errors.transType,
                    },
                    {
                      'is-valid': formik.touched.transType && !formik.errors.transType,
                    }
                  )}
                  data-placeholder=''
                >
                  <option value=''>Please Select</option>
                  <option value='DIRECT'>DIRECT</option>
                  <option value='CREDIT-CONVERSION'>CREDIT-CONVERSION</option>
                </select>
                {formik.touched.transType && formik.errors.transType && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.transType}</span>
                    </div>
                  </div>
                )}
              </div>{' '}
              {/* end::Form group */}
              {/* begin::Form group Wallet Code */}
              <div className='fv-row mb-8'>
                <label className='form-label required fw-bolder text-dark fs-6'>
                  Destination Wallet Code
                </label>
                <select
                  {...formik.getFieldProps('destWalletCd')}
                  disabled={formik.values.transType == 'DIRECT' ? false : true}
                  onChange={(e) => {
                    formik.setFieldValue('destWalletCd', e.target.value)
                    formik.setFieldValue('destWalletAcc', '')
                  }}
                  className={clsx(
                    'form-control bg-grey',
                    {
                      'is-invalid': formik.touched.destWalletCd && formik.errors.destWalletCd,
                    },
                    {
                      'is-valid': formik.touched.destWalletCd && !formik.errors.destWalletCd,
                    }
                  )}
                  data-placeholder=''
                >
                  <option value=''>Please Select</option>
                  <option value='NAGAD'>NAGAD</option>
                  <option value='BKASH'>BKASH</option>
                  <option value='ROCKET'>ROCKET</option>
                </select>
                {formik.touched.destWalletCd && formik.errors.destWalletCd && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.destWalletCd}</span>
                    </div>
                  </div>
                )}
              </div>{' '}
              {/* end::Form group */}
              {/* begin::Form group Agent */}
              <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>
                  Destination Wallet Account No
                </label>
                <input
                  placeholder='Enter Destination Wallet Account'
                  type='text'
                  autoComplete='off'
                  disabled={formik.values.destWalletCd == '' ? true : false}
                  {...formik.getFieldProps('destWalletAcc')}
                  className={clsx(
                    'form-control bg-grey',
                    {
                      'is-invalid': formik.touched.destWalletAcc && formik.errors.destWalletAcc,
                    },
                    {
                      'is-valid': formik.touched.destWalletAcc && !formik.errors.destWalletAcc,
                    }
                  )}
                />
                {formik.touched.destWalletAcc && formik.errors.destWalletAcc && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.destWalletAcc}</span>
                    </div>
                  </div>
                )}
              </div>{' '}
              {/* end::Form group */}
              {/* begin::Form group Amount */}
              <div className='fv-row mb-8'>
                <label className='form-label required fw-bolder text-dark fs-6'>Amount</label>
                <input
                  placeholder='Enter Amount'
                  type='number'
                  autoComplete='off'
                  {...formik.getFieldProps('amount')}
                  onBlur={(e) => {
                    // Get the formatted value and set it back into the form field
                    const formattedValue = FormatNumberTo2Decimals(e.target.value)
                    formik.setFieldValue('amount', formattedValue)
                    formik.setFieldTouched('amount', true)
                  }}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.amount && formik.errors.amount,
                    },
                    {
                      'is-valid': formik.touched.amount && !formik.errors.amount,
                    }
                  )}
                />
                {formik.touched.amount && formik.errors.amount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.amount}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}
              {/* begin::Form group */}
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                >
                  {!loading && <span className='indicator-label'>Create</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Form group */}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CommissionWithdrawal_Comm_Create
