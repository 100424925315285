import axios from 'axios'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {stringify} from 'querystring'

export function ManualMatching(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  let transData = props.transData
  let transactionPendingFilterBtn = props.transactionPendingFilterBtn

  const [formData, setFormData] = useState(Object)
  const [loading, setLoading] = useState(false)
  const [currentDeposit, setCurrentDeposit] = useState(Object)
  const [remark, setRemark] = useState('')

  const keys = ['depositProcessId']

  function search(query: any) {
    if (query == '') {
      retrieveData(SISPAY_API, '/cash/depositrequest/list', 'depositRequestList')
    } else {
      let searchedResult = formData.filter((data: any) =>
        keys.some((key) => data[key].toString().toLowerCase().includes(query))
      )

      setFormData(searchedResult)
    }
  }
  //Search function: END

  function retrieveData(SISPAY_API: string, url: string, location: string) {
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
            byTxnId: String(transData.txnId),
            action: 'search',
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        setFormData(response.data.result[`${location}`])
      })
      .catch(function (error) {})
  }

  //GET LIST
  useEffect(() => {
    if (props.transData.txnId !== '') {
      retrieveData(SISPAY_API, '/cash/depositrequest/list', 'depositRequestList')
    } else {
      setFormData('')
    }
  }, [props.transData.txnId])

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5
  const items = formData ? formData : []

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = firstIndex + itemsPerPage
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : ''
  //Pagincation

  function submitMatchRequest(currentDeposit: any, transData: any) {
    let transId = parseInt(transData.id)
    let depositProcessId = parseInt(currentDeposit.depositProcessId)

    axios
      .post(
        `${SISPAY_API}/transaction/match`,
        {
          request: {
            session: session,
            transId: transId,
            depositProcessId: depositProcessId,
            remark: remark,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response: any) {
        transactionPendingFilterBtn.current.click()

        alert(response.data.message)
        setLoading(false)
      })
      .catch(function (error) {
        alert(error)
      })
  }

  return (
    <div>
      <div
        className='modal fade'
        data-bs-backdrop='static'
        id='exampleModalToggle'
        aria-hidden='true'
        aria-labelledby='exampleModalToggleLabel'
        tabIndex={-1}
      >
        <div className='modal-dialog modal-dialog-centered mw-75 modalSMS' style={{zIndex: '9999'}}>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalToggleLabel'>
                STEP 1 : UNMATCHED DEPOSIT
              </h5>
              <input
                type='text'
                placeholder='Search: Deposit Process ID'
                className='form-control bg-transparent w-25 ms-auto'
                onChange={(e) => search(e.target.value)}
              />
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <div className='py-0 text-center'>
                {currentItems.length > 0 ? (
                  <div>
                    <div className='table-responsive mb-5'>
                      <table className='table table-row-bordered gy-7 gs-7 mt-0'>
                        <thead className='position-sticky top-0 z-index-3'>
                          <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th>Request ID</th>
                            <th>Deposit DateTime</th>
                            <th>Merchant</th>
                            <th>Customer Code</th>
                            <th>Wallet Account Name</th>
                            <th>Wallet Code</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Callback Status</th>
                            <th>Deposit Fee</th>
                            <th>Adjustment Remark</th>
                            <th>Approval/ Rejection Remark</th>
                            <th>Submitted Transaction ID</th>
                            <th>Wallet Account No</th>
                            <th>Agent</th>
                            <th>Merchant Transaction ID</th>
                            <th>Source Wallet Account</th>
                            <th>Reference</th>
                            <th>Match Status</th>
                            <th style={{position: 'sticky', right: '0px'}}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.length > 0
                            ? currentItems.map((formData: any) => {
                                return (
                                  <tr
                                    key={
                                      Number(formData.id) +
                                      Math.floor(Math.random() * (9999999999999 - 1 + 1)) +
                                      1
                                    }
                                  >
                                    <td>{formData.id}</td>
                                    <td>
                                      {formData.depositDT.replace('T', ' ').replace(/\.\d+/, '')}
                                    </td>
                                    <td>
                                      {formData.merchantName} - {formData.merchantCd}
                                    </td>
                                    <td>{formData.customerCd}</td>
                                    <td>{formData.walletAccName}</td>
                                    <td>{formData.walletCd}</td>
                                    <td>{formData.amount}</td>
                                    <td>{formData.status}</td>
                                    <td>{formData.callbackStatus}</td>
                                    <td>{formData.depositFee}</td>
                                    <td>{formData.adjustmentRemark}</td>
                                    <td>{formData.approvalRejectionRemark}</td>
                                    <td>{formData.submittedTxnId}</td>
                                    <td>{formData.walletAccNo}</td>
                                    <td>{formData.agent}</td>
                                    <td>{formData.merchantTxnId}</td>
                                    <td>{formData.srcWalletAcc}</td>
                                    <td>{formData.reference}</td>
                                    <td>{formData.matchStatus}</td>
                                    <td style={{position: 'sticky', right: '0px'}}>
                                      <button
                                        className='btn btn-primary'
                                        data-bs-target='#exampleModalToggle2'
                                        data-bs-toggle='modal'
                                        data-bs-dismiss='modal'
                                        onClick={() => {
                                          setCurrentDeposit(formData)
                                        }}
                                      >
                                        MATCH
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })
                            : ''}
                        </tbody>
                      </table>
                    </div>

                    <div className='mt-5 d-flex justify-content-center align-items-center'>
                      <span className='me-5'>Total: {formData.length}</span>
                      <button
                        className='btn btn-secondary p-2 me-2 w-100px h-30px'
                        disabled={currentPage === 1}
                        onClick={previousPage}
                      >
                        Previous
                      </button>
                      {currentPage} of {lastPage}
                      <button
                        className='btn btn-secondary p-2 ms-2 w-100px h-30px'
                        disabled={currentPage === lastPage}
                        onClick={nextPage}
                      >
                        Next
                      </button>
                      <select
                        onChange={(e) => changePage(Number(e.target.value))}
                        className='btn btn-secondary p-2 ms-2 w-100px'
                      >
                        {Array.from({length: lastPage}, (_, i) => (
                          <option key={i + 1} value={i + 1}>
                            Page {i + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : (
                  'No Matching Deposit Request.'
                )}
              </div>
            </div>

            <div className='modal-footer'></div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        data-bs-backdrop='static'
        id='exampleModalToggle2'
        aria-hidden='true'
        aria-labelledby='exampleModalToggleLabel2'
        tabIndex={-1}
      >
        <div className='modal-dialog modal-dialog-centered mw-75'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title d-flex align-items-center' id='exampleModalToggleLabel2'>
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-5'
                  data-bs-target='#exampleModalToggle'
                  data-bs-toggle='modal'
                  data-bs-dismiss='modal'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr021.svg' className='svg-icon-3' />
                </button>
                Step 2 : Enter Remarks
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <textarea
                className='w-100 h-150px'
                onChange={(e) => {
                  setRemark(e.target.value)
                }}
              />
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-success'
                data-bs-dismiss='modal'
                onClick={() => {
                  submitMatchRequest(currentDeposit, transData)
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <a
        className='btn btn-danger w-100 mb-5'
        data-bs-toggle='modal'
        href='#exampleModalToggle'
        role='button'
      >
        MANUAL MATCHING
      </a> */}
    </div>
  )
}
