import React, {Ref, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useLayout} from '../../../../_metronic/layout/core'
import {Filter} from './components/filter'
import {Column, useFilters, useFlexLayout, useResizeColumns, useTable} from 'react-table'
import styled from 'styled-components'
import {ColumnFilter} from '../../components/ColumnFilter'
import {metricsArray} from '../../components/MetricsArray'
import {Export_CSV} from '../../components/Export_CSV'
import {Export_Excel} from '../../components/Export_Excel'
import {getCurrentDateInput} from '../../components/GetCurrentDateInput'
import {WalletUtilization_ExportPDF} from './components/WalletUtilization_ExportPDF'
import FormatNumber from '../../components/FormatNumber'
import {WalletUtilization_Style} from './components/WalletUtilization_Style'
import clsx from 'clsx'

const WalletUtilizationPage: React.FC = () => {
  //Select Metrics
  const [formData, setFormData] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [metrics, setMatrics] = useState<any>(undefined)
  const [downloadAction, setDownloadAction] = useState(false)

  function decimalToPercentage(decimalValue: number) {
    const percentage = decimalValue
    return `${percentage.toFixed(2)}%`
  }

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  let items = formData

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = Math.min(firstIndex + itemsPerPage, items.length)
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : []

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(e.target.value)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset the current page to 1
  }
  //Pagincation

  //Calculate Data
  function calculateSum(data: any, key: string) {
    return data.reduce((acc: any, curr: any) => acc + (curr[key] ? curr[key] : 0), 0)
  }

  function calculateAverage(data: any, key: string) {
    return data.reduce((acc: any, curr: any) => acc + curr[key], 0) / data.length
  }

  useEffect(() => {
    let metrics = localStorage.getItem('walletUtilizationMetrics')
    if (metrics) {
      setMatrics(JSON.parse(metrics))
    } else {
      setMatrics([])
    }
  }, [formData, showMetrics])

  interface Data {
    dailyDepositLmt: Function
    dailyWithdrawalLmt: string
    depositCount: string
    depositTotal: string
    depositUtilise: string
    status: string
    currentDepositAmt: string
    currentWithdrawalAmt: string
    walletAccName: string
    walletAccNo: string
    walletBal: string
    walletCd: string
    withdrawalCount: string
    withdrawalTotal: string
    withdrawalUtilise: string
    openBalance: string
    closingBalance: string
  }

  const keyMap = {
    walletAccName: 'Wallet Account Name',
    walletAccNo: 'Wallet Account Number',
    walletCd: 'Wallet Code',
    status: 'Status',
    walletBal: 'Wallet Balance',
    dailyDepositLmt: 'Daily Deposit Limit',
    depositTotal: 'Current Deposit Amount',
    depositCount: 'Current Deposit Count',
    depositUtilise: 'Deposit Utilization',
    dailyWithdrawalLmt: 'Daily Withdrawal Limit',
    withdrawalTotal: 'Current Withdrawal Amount',
    withdrawalCount: 'Current Withdrawal Count',
    withdrawalUtilise: 'Withdrawal Utilization',
    openBalance: 'Opening Balance',
    closingBalance: 'Closing Balance',
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        id: 'Wallet Account Name',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Wallet Account Name</div>
            </div>
          )
        },
        accessor: 'walletAccName',
        Filter: ColumnFilter,
      },
      {
        id: 'Wallet Account Number',
        Header: 'Wallet Account Number',
        accessor: 'walletAccNo',
        Filter: ColumnFilter,
      },
      {
        id: 'Wallet Code',
        Header: 'Wallet Code',
        accessor: 'walletCd',
        disableFilters: true,
      },
      {
        id: 'Status',
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
      },
      {
        id: 'Opening Balance',
        Header: 'Opening Balance',
        disableFilters: true,
        accessor: (row: any) => {
          return FormatNumber(row.openBalance)
        },
      },
      {
        id: 'Closing Balance',
        Header: 'Closing Balance',
        disableFilters: true,
        accessor: (row: any) => {
          return FormatNumber(row.closingBalance)
        },
      },
      {
        id: 'Wallet Balance',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Wallet Balance</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'walletBal'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(row.walletBal)
        },
        disableFilters: true,
        width: 250,
      },
      {
        id: 'Daily Deposit Limit',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Daily Deposit Limit</div>
              <span className='badge badge-dark'>
                Average: {FormatNumber(calculateAverage(currentItems, 'dailyDepositLmt'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(row.dailyDepositLmt)
        },
        disableFilters: true,
        width: 250,
      },
      {
        id: 'Current Deposit Amount',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Current Deposit Amount</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'depositTotal'))}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return FormatNumber(row.depositTotal)
        },
        disableFilters: true,
        width: 250,
      },
      {
        id: 'Current Deposit Count',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Current Deposit Count</div>
              <span className='badge badge-dark'>
                Total: {calculateSum(currentItems, 'depositCount')}
              </span>
            </div>
          )
        },
        accessor: (row: any) => {
          return row.depositCount || 0
        },
        disableFilters: true,
        width: 250,
      },
      {
        id: 'Deposit Utilization',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Deposit Utilization</div>
              <span className='badge badge-dark'>
                Average: {decimalToPercentage(calculateAverage(currentItems, 'depositUtilise'))}
              </span>
            </div>
          )
        },
        disableFilters: true,
        accessor: (row) => {
          return decimalToPercentage(Number(row.depositUtilise))
        },
        width: 250,
      },
      {
        id: 'Daily Withdrawal Limit',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Daily Withdrawal Limit</div>
              <span className='badge badge-dark'>
                Average: {FormatNumber(calculateAverage(currentItems, 'dailyWithdrawalLmt'))}
              </span>
            </div>
          )
        },
        disableFilters: true,
        accessor: (row: any) => {
          return FormatNumber(row.dailyWithdrawalLmt)
        },
        width: 250,
      },
      {
        id: 'Current Withdrawal Amount',
        // Header: 'Current Withdrawal Amount',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Current Withdrawal Amount</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'withdrawalTotal'))}
              </span>
            </div>
          )
        },
        disableFilters: true,
        accessor: (row: any) => {
          return FormatNumber(row.withdrawalTotal)
        },
        width: 250,
      },
      {
        id: 'Current Withdrawal Count',
        // Header: 'Current Withdrawal Count',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Current Withdrawal Count</div>
              <span className='badge badge-dark'>
                Total: {FormatNumber(calculateSum(currentItems, 'withdrawalCount'))}
              </span>
            </div>
          )
        },
        disableFilters: true,
        accessor: (row: any) => {
          return row.withdrawalCount || 0
        },
        width: 250,
      },
      {
        id: 'Withdrawal Utilization',
        // Header: 'Withdrawal Utilization',
        Header: () => {
          return (
            <div className='p-0 d-flex flex-column'>
              <div>Withdrawal Utilization</div>
              <span className='badge badge-dark'>
                Average: {decimalToPercentage(calculateAverage(currentItems, 'withdrawalUtilise'))}
              </span>
            </div>
          )
        },
        disableFilters: true,
        accessor: (row) => {
          return decimalToPercentage(Number(row.withdrawalUtilise))
        },
        width: 250,
      },
    ],
    [currentItems]
  )
  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {hiddenColumns: metrics ? metrics : []},
      },
      useFilters,
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter((column) => column.Header !== ' ' && column.Header !== 'Action')
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex justify-content-center align-items-center m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            onClick={() => {
                              metricsArray('walletUtilizationMetrics', column.id)
                            }}
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.id)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5' style={{height: '70vh'}}>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  {headerGroups.map((headerGroup) => (
                    <div
                      {...headerGroup.getHeaderGroupProps()}
                      className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200 sticky-header'
                    >
                      {headerGroup.headers.map((column: any) => (
                        <div {...column.getHeaderProps()} className='th'>
                          {column.render('Header')}
                          {/* Use column.getResizerProps to hook up the events correctly */}
                          <div
                            {...(column as any).getResizerProps()}
                            className={`resizer ${(column as any).isResizing ? 'isResizing' : ''}`}
                          />
                        </div>
                      ))}
                    </div>
                  ))}

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0'>
          <div
            className='position-relative me-5'
            style={{
              width: '180px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-info w-100'
              onClick={() => {
                setDownloadAction(!downloadAction)
              }}
            >
              Download Record As:
            </div>{' '}
            {downloadAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_CSV(
                      formData,
                      keyMap,
                      getCurrentDateInput(),
                      getCurrentDateInput(),
                      'Wallet_Utilization'
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  CSV
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_Excel(
                      formData,
                      keyMap,
                      getCurrentDateInput(),
                      getCurrentDateInput(),
                      'Wallet_Utilization'
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  Excel WorkBook
                </button>{' '}
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    WalletUtilization_ExportPDF(
                      formData,
                      getCurrentDateInput(),
                      getCurrentDateInput()
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  PDF
                </button>{' '}
              </div>
            )}
          </div>
        </div>
        <div className='m-0 d-flex '>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Filter setFormData={setFormData} />
      <WalletUtilization_Style>
        <Table columns={columns} data={currentItems == undefined ? [] : currentItems} />
      </WalletUtilization_Style>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {formData.length}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={previousPage}
        >
          Previous
        </button>
        {currentPage} of {lastPage}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage === lastPage}
          onClick={nextPage}
        >
          Next
        </button>
        <select
          onChange={(e) => changePage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: lastPage}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
          <option value={250}>250 per page</option>
          <option value={500}>500 per page</option>
        </select>
      </div>
      {/* Pagination END */}
    </div>
  )
}

export default WalletUtilizationPage
