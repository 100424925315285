export default function sortTableDataWithHeaderBtn(
  formData: any,
  setFormData: any,
  sortHeader: any,
  setSortHeader: any,
  headerSelected: any
) {
  let sortedFormData = [...formData]

  //Check if the sortHeader is empty or not
  if (sortHeader.sortColumn === '') {
    sortedFormData.sort((a: any, b: any) => {
      if (a[headerSelected] < b[headerSelected]) {
        return -1
      }
      if (a[headerSelected] > b[headerSelected]) {
        return 1
      }
      return 0
    })
    setSortHeader({
      sortColumn: headerSelected,
      sortSequence: 'asc',
    })
  } else {
    if (sortHeader.sortSequence === 'asc') {
      sortedFormData.sort((a: any, b: any) => {
        if (a[headerSelected] > b[headerSelected]) {
          return -1
        }
        if (a[headerSelected] < b[headerSelected]) {
          return 1
        }
        return 0
      })
      setSortHeader({
        sortColumn: headerSelected,
        sortSequence: 'desc',
      })
    } else {
      sortedFormData.sort((a: any, b: any) => {
        if (a[headerSelected] < b[headerSelected]) {
          return -1
        }
        if (a[headerSelected] > b[headerSelected]) {
          return 1
        }
        return 0
      })
      setSortHeader({
        sortColumn: headerSelected,
        sortSequence: 'asc',
      })
    }
  }
  setFormData(sortedFormData)
}
