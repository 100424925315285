import {AdminOperationMenu} from './Admin_Operation_Menu'
import {MerchantAdminMenu} from './Merchant_Admin_Menu'
import {MerchantModeratorMenu} from './Merchant_Mod_Menu'
import {MerchantVIPMenu} from './Merchant_VIP_Menu'
import {SidebarMenuMain} from './SidebarMenuMain'

const SidebarMenu = () => {
  const userType: string = localStorage.getItem('userType') || ''
  const userRole: string = localStorage.getItem('userRole') || ''

  function renderMenu() {
    if (userType === 'Admin' && userRole === 'operation') {
      return <AdminOperationMenu />
    } else if (userType == 'Admin' || userType == 'System Admin') {
      return <SidebarMenuMain />
    } else if (userType == 'Merchant' && userRole == 'admin') {
      return <MerchantAdminMenu />
    } else if (userType == 'Merchant' && userRole == 'moderator') {
      return <MerchantModeratorMenu />
    } else if (userType == 'Merchant' && userRole == 'vip') {
      return <MerchantVIPMenu />
    } else {
      return null
    }
  }

  return (
    <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
      <div
        id='kt_app_sidebar_menu_wrapper'
        className='app-sidebar-wrapper hover-scroll-overlay-y my-5'
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        data-kt-scroll-wrappers='#kt_app_sidebar_menu'
        data-kt-scroll-offset='5px'
        data-kt-scroll-save-state='true'
      >
        <div
          className='menu menu-column menu-rounded menu-sub-indention px-3'
          id='#kt_app_sidebar_menu'
          data-kt-menu='true'
          data-kt-menu-expand='false'
        >
          {renderMenu()}
          {/* <SidebarMenuMain /> */}
        </div>
      </div>
    </div>
  )
}

export {SidebarMenu}
