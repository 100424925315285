import {create} from 'zustand'

export const useCreditReloadStore = create((set) => ({
  //Data
  getData: false,
  filterOptions: {
    search: '',
    username: '',
    status: '',
    reqType: '',
    dateTimeFrom: '',
    dateTimeTo: '',
  },

  //Functions
  setGetData: (value: boolean) => set({getData: value}),
  setFilterOptions: (value: any) => set({filterOptions: value}),
}))
