import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import {filterArrayByMultipleKeys} from '../../components/Filter'
import {searching} from '../../components/Searching'
import {getCurrentDateInput} from '../../components/GetCurrentDateInput'
import {sortByDate} from '../../components/SortByDate'
import {Overlay} from '../../components/Overlay'
import {customStyles} from '../../components/CustomStyles'
import Select from 'react-select'
import {ErrorHandling} from '../../components/ErrorHandling'
import {useAuth} from '../../auth/core/Auth'
import {sortByName} from '../../components/SortByName'
import {UniqueWalletAccNo} from '../../components/UniqueWalletAccNo'
import {usePendingRequestStore} from './PendingRequest_Store'

export function Filter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData
  let requestFilterBtn = props.requestFilterBtn

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [wallets, setWallets] = useState(Object)

  //Refs
  const selectedWalletAccNo = useRef<any>()

  //Zustand Store
  const filterOptions = usePendingRequestStore((state: any) => state.filterOptions)
  const getData = usePendingRequestStore((state: any) => state.getData)
  const setFilterOptions = usePendingRequestStore((state: any) => state.setFilterOptions)
  const setGetData = usePendingRequestStore((state: any) => state.setGetData)

  const initialValues = {
    search: filterOptions.search ? String(filterOptions.search) : '',
    suspectedReason: filterOptions.suspectedReason ? String(filterOptions.suspectedReason) : '',
    txnId: filterOptions.txnId ? String(filterOptions.txnId) : '',
    walletAccNo: filterOptions.walletAccNo ? String(filterOptions.walletAccNo) : '',
    smsType: filterOptions.smsType ? String(filterOptions.smsType) : '',
    smsTemplate: filterOptions.smsTemplate ? String(filterOptions.smsTemplate) : '',
    dateTimeFrom: filterOptions.dateTimeFrom
      ? String(filterOptions.dateTimeFrom)
      : getCurrentDateInput(),
    dateTimeTo: filterOptions.dateTimeTo ? String(filterOptions.dateTimeTo) : getCurrentDateInput(),
  }

  const filterSchema = Yup.object().shape({
    search: Yup.string(),
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const keys = ['source', 'senderReceiver', 'serviceCenter']

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)
      let walletAccNo = values.walletAccNo.trim()
      let search = values.search.trim()
      let smsType = values.smsType.trim()
      let smsTemplate = values.smsTemplate.trim()
      let dateTimeFrom = values.dateTimeFrom.trim()
      let dateTimeTo = values.dateTimeTo.trim()
      let suspectedReason = values.suspectedReason.trim()
      let txnId = values.txnId.trim()

      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/sms/listrequest`,
          {
            request: {
              session: session,
              status: 'PENDING',
              fromDate: dateTimeFrom,
              toDate: dateTimeTo,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let filteredFormData = filterArrayByMultipleKeys(
            response.data.result.smsRequestList,
            ['walletAccNo', 'smsType', 'smsTemplate', 'suspectedReason'],
            [walletAccNo, smsType, smsTemplate, suspectedReason]
          )

          if (search !== '') {
            filteredFormData = searching(filteredFormData, search.toLowerCase(), keys)
          }

          //Filter TxnId
          if (txnId !== '') {
            filteredFormData = filteredFormData.filter((data) => {
              return data.txnId === txnId
            })
          }

          setFormData(sortByDate(filteredFormData, 'receivedDT'))

          setDisabled(false)
          setLoading(false)
        })
        .catch(function (error) {})
    },
  })

  useEffect(() => {
    if (getData == false) return

    setDisabled(true)
    setLoading(true)
    let walletAccNo = formik.values.walletAccNo.trim()
    let search = formik.values.search.trim()
    let smsType = formik.values.smsType.trim()
    let smsTemplate = formik.values.smsTemplate.trim()
    let dateTimeFrom = formik.values.dateTimeFrom.trim()
    let dateTimeTo = formik.values.dateTimeTo.trim()
    let suspectedReason = formik.values.suspectedReason.trim()
    let txnId = formik.values.txnId.trim()

    axios
      .post(
        `${SISPAY_API}/sms/listrequest`,
        {
          request: {
            session: session,
            status: 'PENDING',
            fromDate: dateTimeFrom,
            toDate: dateTimeTo,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let filteredFormData = filterArrayByMultipleKeys(
          response.data.result.smsRequestList,
          ['walletAccNo', 'smsType', 'smsTemplate', 'suspectedReason'],
          [walletAccNo, smsType, smsTemplate, suspectedReason]
        )

        if (search !== '') {
          filteredFormData = searching(filteredFormData, search.toLowerCase(), keys)
        }

        //Filter TxnId
        if (txnId !== '') {
          filteredFormData = filteredFormData.filter((data) => {
            return data.txnId === txnId
          })
        }

        setFormData(sortByDate(filteredFormData, 'receivedDT'))
        setGetData(false)
        setDisabled(false)
        setLoading(false)
      })
      .catch(function (error) {})
  }, [getData])

  //ABSTRACT FUNCTION: Retrieve data from API
  function retrieveRequest(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          let walletAccNoList = UniqueWalletAccNo(result)

          setWallets(sortByName(walletAccNoList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  //GET USER LIST
  useEffect(() => {
    retrieveRequest(SISPAY_API, '/wallet/list', 'wallet')
  }, [])

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-2 py-1'>
          <div className=''>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='fv-row mb-5'>
                {' '}
                <div className='row'>
                  <div className='col'>
                    {/* begin::Form group Search */}
                    <div className='fv-row mb-5'>
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>Search</label>
                        {Overlay('SEARCH Source, Service Center, Sender/Receiver/TxnID')}
                      </div>
                      <input
                        placeholder='Enter your search'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('search')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.search && formik.errors.search,
                          },
                          {
                            'is-valid': formik.touched.search && !formik.errors.search,
                          }
                        )}
                      />
                      {formik.touched.search && formik.errors.search && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.search}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group Suspected Reason */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Suspected Reason
                      </label>
                      <select
                        {...formik.getFieldProps('suspectedReason')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        <option value=''>ALL</option>
                        <option value='Invalid Service Center'>Invalid Service Center</option>
                        <option value='Invalid Source Number'>Invalid Source Number</option>
                        <option value='Balance Different'>Balance Different</option>
                        <option value='Duplicated TxnID'>Duplicated TxnID</option>
                        <option value='Invalid Wallet Code'>Invalid Wallet Code</option>
                        <option value='Invalid Source Wallet'>Invalid Source Wallet</option>
                      </select>
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group TxnId */}
                    <div className='fv-row mb-5'>
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>TxnId</label>
                      </div>
                      <input
                        placeholder='Enter TxnId'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('txnId')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.txnId && formik.errors.txnId,
                          },
                          {
                            'is-valid': formik.touched.txnId && !formik.errors.txnId,
                          }
                        )}
                      />
                      {formik.touched.txnId && formik.errors.txnId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.txnId}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* end::Form group */}
                  <div className='col'>
                    {/* begin::Form group Wallet Account Number */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Wallet Account Number
                      </label>

                      <Select
                        ref={selectedWalletAccNo}
                        className='basic-single'
                        classNamePrefix='select'
                        name='walletAccNo'
                        isClearable={true}
                        options={wallets}
                        styles={customStyles}
                        value={
                          formik.values.walletAccNo
                            ? {value: formik.values.walletAccNo, label: formik.values.walletAccNo}
                            : null
                        }
                        onChange={(e: any) =>
                          e !== null
                            ? formik.setFieldValue('walletAccNo', e.value)
                            : formik.setFieldValue('walletAccNo', '')
                        }
                      />
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    {/* begin::Form group SMS TYPE */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>SMS Type</label>
                      <select
                        {...formik.getFieldProps('smsType')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        <option value=''>ALL</option>
                        <option value='B2B SEND'>B2B SEND</option>
                        <option value='DEPOSIT'>DEPOSIT</option>
                        <option value='WITHDRAWAL'>WITHDRAWAL</option>
                        <option value='B2B RECEIVE'>B2B RECEIVE</option>
                      </select>
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group SMS Template */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>SMS Template</label>
                      <select
                        {...formik.getFieldProps('smsTemplate')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        <option value=''>ALL</option>
                        <option value='NAGAD'>NAGAD</option>
                        <option value='BKASH'>BKASH</option>
                        <option value='ROCKET'>ROCKET</option>
                      </select>
                    </div>{' '}
                  </div>
                  <div className='col'>
                    {/* begin::Form group DateTime From */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>From</label>
                      <input
                        placeholder='Enter your start time'
                        type='date'
                        autoComplete='off'
                        {...formik.getFieldProps('dateTimeFrom')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                          },
                          {
                            'is-valid': formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                          }
                        )}
                      />
                      {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.dateTimeFrom}</span>
                          </div>
                        </div>
                      )}
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group DateTime To */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>To</label>
                      <input
                        placeholder='Enter your end time'
                        type='date'
                        autoComplete='off'
                        {...formik.getFieldProps('dateTimeTo')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                          },
                          {
                            'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                          }
                        )}
                      />
                      {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.dateTimeTo}</span>
                          </div>
                        </div>
                      )}
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      disabled={disabled}
                      onClick={() => {
                        selectedWalletAccNo.current.setValue('')
                        formik.resetForm({
                          values: {
                            search: '',
                            suspectedReason: '',
                            txnId: '',
                            walletAccNo: '',
                            smsType: '',
                            smsTemplate: '',
                            dateTimeFrom: getCurrentDateInput(),
                            dateTimeTo: getCurrentDateInput(),
                          },
                        })
                      }}
                    >
                      {!loading && <span className='indicator-label'>Reset</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      type='submit'
                      ref={requestFilterBtn}
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid || disabled}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
