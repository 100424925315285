import React, {useState, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'

const SmsManagementPage_View: React.FC = () => {
  const location: any = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  let smsData = location.state.formData

  const initialValues = {
    smsId: String(smsData.smsId),
    smsTxnDT: String(smsData.smsTxnDT),
    receivedDT: String(smsData.receivedDT),
    source: String(smsData.source),
    walletAccName: String(smsData.walletAccName),
    walletAccNo: String(smsData.walletAccNo),
    walletCd: String(smsData.walletCd),
    txnId: String(smsData.txnId),
    senderReceiver: String(smsData.senderReceiver),
    serviceCenter: String(smsData.serviceCenter),
    amount: String(smsData.amount),
    smsBody: String(smsData.smsBody),
    smsType: String(smsData.smsType),
    smsTemplate: String(smsData.smsTemplate),
    txnFee: String(smsData.txnFee),
    smsBal: String(smsData.smsBal),
    walletBalance: String(smsData.walletBalance),
    balDiff: Number(smsData.walletBalance) - Number(smsData.smsBal),
  }

  const addUserSchema = Yup.object().shape({})

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
      } catch (error) {}
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group SMS ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>SMS ID</label>
            <input
              placeholder='Enter your smsId'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('smsId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.smsId && formik.errors.smsId,
                },
                {
                  'is-valid': formik.touched.smsId && !formik.errors.smsId,
                }
              )}
            />
            {formik.touched.smsId && formik.errors.smsId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group SMS Transaction DateTime */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>SMS Transaction DateTime</label>
            <input
              placeholder='Enter your SMS Transaction DateTime'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('smsTxnDT')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.smsTxnDT && formik.errors.smsTxnDT,
                },
                {
                  'is-valid': formik.touched.smsTxnDT && !formik.errors.smsTxnDT,
                }
              )}
            />
            {formik.touched.smsTxnDT && formik.errors.smsTxnDT && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsTxnDT}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Received DateTime */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Received DateTime</label>
            <input
              placeholder='Enter Received DateTime'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('receivedDT')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.receivedDT && formik.errors.receivedDT,
                },
                {
                  'is-valid': formik.touched.receivedDT && !formik.errors.receivedDT,
                }
              )}
            />
            {formik.touched.receivedDT && formik.errors.receivedDT && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.receivedDT}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Source */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Source</label>
            <input
              placeholder='Enter Source'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('source')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.source && formik.errors.source,
                },
                {
                  'is-valid': formik.touched.source && !formik.errors.source,
                }
              )}
            />
            {formik.touched.source && formik.errors.source && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.source}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account Name */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Account Name</label>
            <input
              placeholder='Enter Wallet Account Name'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletAccName')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletAccName && formik.errors.walletAccName,
                },
                {
                  'is-valid': formik.touched.walletAccName && !formik.errors.walletAccName,
                }
              )}
            />
            {formik.touched.walletAccName && formik.errors.walletAccName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account Number */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Account Number</label>
            <input
              placeholder='Enter Wallet Account Number'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletAccNo')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletAccNo && formik.errors.walletAccNo,
                },
                {
                  'is-valid': formik.touched.walletAccNo && !formik.errors.walletAccNo,
                }
              )}
            />
            {formik.touched.walletAccNo && formik.errors.walletAccNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>
            <input
              placeholder='Enter Wallet Code'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletCd && formik.errors.walletCd,
                },
                {
                  'is-valid': formik.touched.walletCd && !formik.errors.walletCd,
                }
              )}
            />
            {formik.touched.walletCd && formik.errors.walletCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group TxnID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>TxnID</label>
            <input
              placeholder='Enter TxnID'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('txnId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.txnId && formik.errors.txnId,
                },
                {
                  'is-valid': formik.touched.txnId && !formik.errors.txnId,
                }
              )}
            />
            {formik.touched.txnId && formik.errors.txnId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.txnId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Sender/ Receiver */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Sender/ Receiver</label>
            <input
              placeholder='Enter Sender/ Receiver'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('senderReceiver')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.senderReceiver && formik.errors.senderReceiver,
                },
                {
                  'is-valid': formik.touched.senderReceiver && !formik.errors.senderReceiver,
                }
              )}
            />
            {formik.touched.senderReceiver && formik.errors.senderReceiver && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.senderReceiver}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Service Center */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Service Center</label>
            <input
              placeholder='Enter Service Center'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('serviceCenter')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.serviceCenter && formik.errors.serviceCenter,
                },
                {
                  'is-valid': formik.touched.serviceCenter && !formik.errors.serviceCenter,
                }
              )}
            />
            {formik.touched.serviceCenter && formik.errors.serviceCenter && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.serviceCenter}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Amount */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Amount</label>
            <input
              placeholder='Enter Amount'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('amount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.amount && formik.errors.amount,
                },
                {
                  'is-valid': formik.touched.amount && !formik.errors.amount,
                }
              )}
            />
            {formik.touched.amount && formik.errors.amount && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.amount}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group SMS Content */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>SMS Content</label>
            <input
              placeholder='Enter SMS Content'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('smsBody')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.smsBody && formik.errors.smsBody,
                },
                {
                  'is-valid': formik.touched.smsBody && !formik.errors.smsBody,
                }
              )}
            />
            {formik.touched.smsBody && formik.errors.smsBody && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsBody}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group SMS Type */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>SMS Type</label>
            <input
              placeholder='Enter SMS Type'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('smsType')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.smsType && formik.errors.smsType,
                },
                {
                  'is-valid': formik.touched.smsType && !formik.errors.smsType,
                }
              )}
            />
            {formik.touched.smsType && formik.errors.smsType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsType}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group SMS Template */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>SMS Template</label>
            <input
              placeholder='Enter SMS Template'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('smsTemplate')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.smsTemplate && formik.errors.smsTemplate,
                },
                {
                  'is-valid': formik.touched.smsTemplate && !formik.errors.smsTemplate,
                }
              )}
            />
            {formik.touched.smsTemplate && formik.errors.smsTemplate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsTemplate}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Transaction Fee */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Transaction Fee</label>
            <input
              placeholder='Enter Transaction Fee'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('txnFee')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.txnFee && formik.errors.txnFee,
                },
                {
                  'is-valid': formik.touched.txnFee && !formik.errors.txnFee,
                }
              )}
            />
            {formik.touched.txnFee && formik.errors.txnFee && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.txnFee}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Balance */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Balance</label>
            <input
              placeholder='Enter Balance'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('smsBal')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.smsBal && formik.errors.smsBal,
                },
                {
                  'is-valid': formik.touched.smsBal && !formik.errors.smsBal,
                }
              )}
            />
            {formik.touched.smsBal && formik.errors.smsBal && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsBal}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Balance */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Balance</label>
            <input
              placeholder='Enter Wallet Balance'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletBalance')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletBalance && formik.errors.walletBalance,
                },
                {
                  'is-valid': formik.touched.walletBalance && !formik.errors.walletBalance,
                }
              )}
            />
            {formik.touched.walletBalance && formik.errors.walletBalance && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletBalance}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Balance Different*/}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Balance Different</label>
            <input
              placeholder='Enter Balance Different'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('balDiff')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.balDiff && formik.errors.balDiff,
                },
                {
                  'is-valid': formik.touched.balDiff && !formik.errors.balDiff,
                }
              )}
            />
            {formik.touched.balDiff && formik.errors.balDiff && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.balDiff}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}

export default SmsManagementPage_View
