import React, {Ref, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import axios from 'axios'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import popover from '../components/Popover'
import {Column, useTable, useFlexLayout, useResizeColumns} from 'react-table'
import {Styles} from '../components/Table_Style'
import BulkApproval from './components/bulkApproval'
import {Filter_List} from './components/filter_list'
import Request_Export_CSV from './components/Request_Export_CSV'
import {modifyKeys} from '../components/ModifyKeys'
import {metricsArray} from '../components/MetricsArray'
import FormatNumber from '../components/FormatNumber'
import {useRequestStore} from './components/Request_Store'

const RequestManagementPage_List: React.FC = () => {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //States
  const [isAction, setIsAction] = useState(false)
  const [formData, setFormData] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [remark, setRemark] = useState('')
  const [requestData, setRequestData] = useState(Object)
  const [metrics, setMatrics] = useState<any>(undefined)

  //Refs
  const requestFilterBtn = useRef<any>()

  //Zustand Store
  const setGetData = useRequestStore((state: any) => state.setGetData)

  function approveRequest() {
    axios
      .post(
        `${SISPAY_API}/sms/request/approve`,
        {
          request: {
            reqId: requestData.id,
            smsProcId: requestData.smsProcId,
            smsId: requestData.smsId,
            smsType: requestData.smsType,
            remark: remark,
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        requestFilterBtn.current.click()
        if (response.data.code == '200') {
          alert('Request Approved!')
        } else {
          alert(response.data.message)
        }
      })
      .catch(function (error) {})
  }

  function rejectRequest() {
    axios
      .post(
        `${SISPAY_API}/sms/request/reject`,
        {
          request: {
            reqId: requestData.id,
            remark: remark,
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        requestFilterBtn.current.click()
        if (response.data.code == '200') {
          alert('Request Rejected!')
        } else {
          alert(response.data.message)
        }
      })
      .catch(function (error) {})
  }

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  let items = formData

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = Math.min(firstIndex + itemsPerPage, items.length)
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : []

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(e.target.value)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset the current page to 1
  }
  //Pagincation

  useEffect(() => {
    let metrics = localStorage.getItem('requestMetrics')
    if (metrics) {
      setMatrics(JSON.parse(metrics))
    } else {
      setMatrics([])
    }
  }, [formData, showMetrics])

  //Table
  interface Data {
    smsId: string
    smsTxnDT: string
    receivedDT: string
    source: string
    walletAccName: string
    walletAccNo: string
    walletCd: string
    txnId: string
    senderReceiver: string
    serviceCenter: string
    amount: string
    smsBody: string
    smsType: string
    smsTemplate: string
    txnFee: string
    suspectedReason: string
    smsBal: string
    walletBal: string
    balDiff: string
    merchantName: string
    merchantCd: string
    customerCd: string
    srcWalletAcc: string
    submittedTxnId: string
    depositReqId: string
    reference: string
    remark: string
    status: string
    transId: string
  }

  const keyMap = {
    smsId: 'SMS ID',
    smsTxnDT: 'SMS Transaction DateTime',
    receivedDT: 'Received DateTime',
    source: 'Source',
    walletAccName: 'Wallet Account Name',
    walletAccNo: 'Wallet Account No',
    walletCd: 'Wallet Code',
    txnId: 'TxnID',
    senderReceiver: 'Sender Receiver',
    serviceCenter: 'Service Center',
    amount: 'Amount',
    smsBody: 'SMS Content',
    smsType: 'SMS Type',
    smsTemplate: 'SMS Template',
    txnFee: 'Transaction Fee',
    suspectedReason: 'Suspected Reason',
    smsBal: 'Balance',
    walletBal: 'Wallet Balance',
    balDiff: 'Balance Different',
    merchantName: 'Merchant',
    merchantCd: 'Merchant Code',
    customerCd: 'Customer Code',
    srcWalletAcc: 'Source Wallet Account',
    submittedTxnId: 'Submitted Transaction ID',
    depositReqId: 'Deposit Request ID',
    reference: 'Reference',
    remark: 'Remark',
    status: 'Status',
    transId: 'TransID',
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: 'SMS ID',
        accessor: (row: any) => {
          return <Link to={'/sms/list/' + row.smsId}>{row.smsId}</Link>
        },
      },
      {
        Header: 'SMS Transaction DateTime',
        accessor: (row: any) => {
          return row.smsTxnDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        Header: 'Received DateTime',
        accessor: (row: any) => {
          return row.receivedDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Source',
        accessor: 'source',
      },
      {
        Header: 'Wallet Account Name',
        accessor: 'walletAccName',
      },
      {
        Header: 'Wallet Account No',
        accessor: 'walletAccNo',
      },
      {
        Header: 'Wallet Code',
        accessor: 'walletCd',
      },
      {
        Header: 'TransID',
        accessor: (row) => {
          return (
            <Link to={'/transaction/' + row.transId} target='_blank'>
              {row.transId}
            </Link>
          )
        },
      },
      {
        Header: 'TxnID',
        accessor: 'txnId',
      },
      {
        Header: 'Sender Receiver',
        accessor: 'senderReceiver',
      },
      {
        Header: 'Service Center',
        accessor: 'serviceCenter',
      },
      {
        Header: 'Amount',
        accessor: (row: any) => {
          return FormatNumber(row.amount)
        },
      },
      {
        Header: 'SMS Content',
        accessor: (row: any) => {
          return (
            <OverlayTrigger trigger='hover' placement='top' overlay={popover(row.smsBody)}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {row.smsBody}
              </div>
            </OverlayTrigger>
          )
        },
      },
      {
        Header: 'SMS Type',
        accessor: 'smsType',
      },
      {
        Header: 'SMS Template',
        accessor: 'smsTemplate',
      },
      {
        Header: 'Transaction Fee',
        accessor: (row: any) => {
          return FormatNumber(row.txnFee)
        },
      },
      {
        Header: 'Suspected Reason',
        accessor: 'suspectedReason',
      },
      {
        Header: 'Balance',
        accessor: (row: any) => {
          return FormatNumber(row.smsBal)
        },
      },
      {
        Header: 'Wallet Balance',
        accessor: (row: any) => {
          return FormatNumber(row.walletBal)
        },
      },
      {
        Header: 'Balance Different',
        accessor: (row: any) => {
          return FormatNumber(row.balDiff)
        },
      },
      {
        Header: 'Merchant',
        accessor: (row: any) => {
          return row.merchantName + ' - ' + row.merchantCd
        },
      },
      {
        Header: 'Customer Code',
        accessor: 'customerCd',
      },
      {
        Header: 'Source Wallet Account',
        accessor: 'srcWalletAcc',
      },
      {
        Header: 'Submitted Transaction ID',
        accessor: 'submittedTxnId',
      },
      {
        Header: 'Deposit Request ID',
        accessor: 'depositReqId',
      },
      {
        Header: 'Reference',
        accessor: (row: any) => {
          return (
            <OverlayTrigger trigger='hover' placement='top' overlay={popover(row.reference)}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {row.reference}
              </div>
            </OverlayTrigger>
          )
        },
      },
      {
        Header: 'Remark',
        accessor: 'remark',
      },
      {
        Header: 'Action',
        accessor: (formData: any) => {
          return (
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                onClick={() => {
                  setRequestData(formData)
                }}
                style={{
                  opacity: formData.status === 'PENDING' ? '1' : '0',
                  visibility: formData.status === 'PENDING' ? 'visible' : 'hidden',
                }}
              >
                <OverlayTrigger
                  trigger='hover'
                  placement='top'
                  overlay={popover('Approve Request')}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr012.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </button>

              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_2'
                onClick={() => {
                  setRequestData(formData)
                }}
                style={{
                  opacity: formData.status === 'PENDING' ? '1' : '0',
                  visibility: formData.status === 'PENDING' ? 'visible' : 'hidden',
                }}
              >
                <OverlayTrigger trigger='hover' placement='top' overlay={popover('Reject Request')}>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </button>

              <Link
                to={'/request/' + formData.id}
                state={formData}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                onClick={() => setGetData(true)}
              >
                <OverlayTrigger trigger='hover' placement='top' overlay={popover('Check Details')}>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen038.svg' className='svg-icon-3' />
                  </div>
                </OverlayTrigger>{' '}
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        //maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {hiddenColumns: metrics ? metrics : []},
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter((column) => column.Header !== ' ' && column.Header !== 'Action')
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex justify-content-center align-items-center m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            onClick={() => {
                              metricsArray('requestMetrics', column.id)
                            }}
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.Header)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  return (
    <div>
      <div className='d-flex justify-content-between mb-5 align-items-center'>
        <div className='d-flex '>
          <div className='fv-row'>
            <button
              className='btn btn-info btn-sm fw-bold btn-color-white-700 w-100'
              disabled={formData.length > 0 ? false : true}
              onClick={() => {
                Request_Export_CSV(modifyKeys(formData, keyMap))
              }}
            >
              Download Content as CSV
            </button>
          </div>
          <div
            className='position-relative'
            style={{
              width: '150px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-warning ms-3 w-100'
              onClick={() => {
                setIsAction(!isAction)
              }}
            >
              Action
            </div>{' '}
            {isAction && (
              <div
                className='action-dropdown d-flex-row position-absolute ms-3 mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_bulk_approval'
                  //disabled={selectedArray.length > 0 ? false : true}
                  onClick={() => {
                    setIsAction(!isAction)
                  }}
                >
                  BULK APPROVAL
                </button>{' '}
              </div>
            )}
          </div>
        </div>
        <div className='m-0'>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Filter_List setFormData={setFormData} requestFilterBtn={requestFilterBtn} />
      <Styles>
        <Table columns={columns} data={currentItems == undefined ? [] : currentItems} />
      </Styles>
      <BulkApproval requestFilterBtn={requestFilterBtn} />
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Remarks</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <textarea
                className='w-100 h-200px'
                onChange={(e) => {
                  setRemark(e.target.value)
                }}
              ></textarea>
            </div>
            <div className='modal-footer'>
              <button
                type='submit'
                className='btn btn-success'
                data-bs-dismiss='modal'
                onClick={() => {
                  approveRequest()
                }}
              >
                APPROVE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Remarks</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <textarea
                className='w-100 h-200px'
                onChange={(e) => {
                  setRemark(e.target.value)
                }}
              ></textarea>
            </div>
            <div className='modal-footer'>
              <button
                type='submit'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => {
                  rejectRequest()
                }}
              >
                REJECT
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {formData.length}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={previousPage}
        >
          Previous
        </button>
        {currentPage} of {lastPage}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage === lastPage}
          onClick={nextPage}
        >
          Next
        </button>
        <select
          onChange={(e) => changePage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: lastPage}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
          <option value={250}>250 per page</option>
          <option value={500}>500 per page</option>
        </select>
      </div>
      {/* Pagination END */}
    </div>
  )
}

export default RequestManagementPage_List
