import React, {Ref, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {Filter} from './components/filter'
import {OverlayTrigger} from 'react-bootstrap'
import popover from '../components/Popover'
import {Column, useTable, useFlexLayout, useResizeColumns} from 'react-table'
import styled from 'styled-components'
import Transaction_Export_CSV from './components/Transaction_Export_CSV'
import {metricsArray} from '../components/MetricsArray'
import FormatNumber from '../components/FormatNumber'
import scrollToTop from '../components/ScrollToTop'

const RequestManagementPage: React.FC = () => {
  //States
  const [formData, setFormData] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [metrics, setMatrics] = useState<any>(undefined)
  const [totalFormData, setTotalFormData] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [filterValues, setFilterValues] = useState<any>([])
  const [downloadLoading, setDownloadLoading] = useState(false)

  const Styles = styled.div`
    padding: 1rem;
    .table {
      display: inline-block;

      .tr {
        :last-child {
          .td {
            border-bottom: 0;
          }
        }
      }
      .th,
      .td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid rgba(182, 173, 178, 0.2);
        border-right: 1px solid rgba(182, 173, 178, 0.2);

        position: relative;

        :last-child {
          border-right: 0;
        }
        .resizer {
          display: inline-block;

          width: 10px;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(50%);
          z-index: 1;
          touch-action: none;

          &.isResizing {
          }
        }
      }
    }
  `

  useEffect(() => {
    let metrics = localStorage.getItem('transactionMetrics')
    if (metrics) {
      setMatrics(JSON.parse(metrics))
    } else {
      setMatrics([])
    }
  }, [formData, showMetrics])

  //Table
  interface Data {
    id: string
    smsId: string
    receivedDt: string
    merchantName: string
    merchantCd: string
    customerCd: string
    walletCd: string
    amount: string
    transType: string
    transSts: string
    callbackStatus: string
    txnFee: string
    txnId: string
    submittedTxnId: string
    senderReceiver: string
    srcWalletAccNo: string
    agentName: string
    walletAccNo: string
    depositReqId: string
    smsBody: string
    matchStatus: string
    completedDT: string
    invalidSrcWallet: boolean
    matchedTransTxnId: string
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 100,
      },
      {
        Header: 'SMS ID',
        id: 'smsId',
        accessor: (row: any) => {
          return <Link to={'/sms/list/' + row.smsId}>{row.smsId}</Link>
        },
      },
      {
        Header: 'Received DateTime',
        accessor: (row: any) => {
          return row.receivedDt.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        Header: 'Completed DateTime',
        accessor: (row: any) => {
          return row.completedDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        Header: 'Merchant',
        accessor: (row: any) => {
          return row.merchantName + ' - ' + row.merchantCd
        },
      },
      {
        Header: 'Customer Code',
        accessor: 'customerCd',
      },
      {
        Header: 'Wallet Code',
        accessor: 'walletCd',
      },
      {
        Header: 'Amount',
        accessor: (row: any) => {
          return FormatNumber(row.amount)
        },
      },
      {
        Header: 'Transaction Type',
        accessor: 'transType',
      },
      {
        Header: 'Transaction Status',
        accessor: 'transSts',
      },
      {
        Header: 'Callback Status',
        accessor: 'callbackStatus',
      },
      {
        Header: 'Transaction Fee',
        accessor: (row: any) => {
          return FormatNumber(row.txnFee)
        },
      },
      {
        Header: 'TxnID',
        accessor: 'txnId',
      },
      {
        Header: 'Submitted TxnID',
        accessor: 'submittedTxnId',
      },
      {
        Header: 'Matched Trans Txn ID',
        accessor: 'matchedTransTxnId',
      },
      {
        Header: 'Sender Receiver',
        accessor: 'senderReceiver',
      },
      {
        Header: 'Source Wallet Acc No',
        accessor: 'srcWalletAccNo',
      },
      {
        Header: 'Invalid Source Wallet',
        accessor: (row) => {
          return row.invalidSrcWallet ? 'TRUE' : 'FALSE'
        },
      },
      {
        Header: 'Agent Name',
        accessor: 'agentName',
      },
      {
        Header: 'Wallet Acc No',
        accessor: 'walletAccNo',
      },
      {
        Header: 'Deposit Request ID',
        accessor: (row) => {
          return (
            <Link target='_blank' to={'/deposit/' + row.depositReqId}>
              {row.depositReqId}
            </Link>
          )
        },
      },
      {
        Header: 'SMS Content',
        accessor: (formData: any) => {
          return (
            <OverlayTrigger trigger='hover' placement='top' overlay={popover(formData.smsBody)}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {formData.smsBody}
              </div>
            </OverlayTrigger>
          )
        },
      },
      {
        Header: 'Match Status',
        accessor: 'matchStatus',
      },
    ],
    []
  )
  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        //maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {hiddenColumns: metrics ? metrics : []},
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter((column) => column.Header !== ' ' && column.Header !== 'Action')
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex justify-content-center align-items-center m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            onClick={() => {
                              metricsArray('transactionMetrics', column.id)
                            }}
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.Header)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No data found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        <div className='fv-row'>
          <button
            className='btn btn-info btn-sm fw-bold btn-color-white-700 w-100'
            disabled={formData.length > 0 && !downloadLoading ? false : true}
            onClick={() => {
              Transaction_Export_CSV(filterValues, setDownloadLoading)
            }}
          >
            {!downloadLoading ? 'Download Content as CSV' : 'Downloading...Please Wait!'}
          </button>
        </div>
        <div className='m-0 d-flex'>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Filter
        setFormData={setFormData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalFormData={setTotalFormData}
        setFilterValues={setFilterValues}
        downloadLoading={downloadLoading}
      />
      <Styles>
        <Table columns={columns} data={formData == undefined ? [] : formData} />
      </Styles>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {totalFormData}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(currentPage - 1)
            scrollToTop()
          }}
        >
          Previous
        </button>
        {currentPage == 0 ? (
          <div>1 of {Math.ceil(totalFormData / 200)}</div>
        ) : (
          <div>
            {currentPage} of {Math.ceil(totalFormData / 200)}
          </div>
        )}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage >= Math.ceil(totalFormData / 200)}
          onClick={() => {
            if (currentPage == 0) {
              setCurrentPage(2)
            } else {
              setCurrentPage(currentPage + 1)
            }
            scrollToTop()
          }}
        >
          Next
        </button>
        <select
          onChange={(e) => setCurrentPage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: Math.ceil(totalFormData / 200)}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
      </div>
      {/* Pagination END */}
    </div>
  )
}

export default RequestManagementPage
