export function cwPushAllRow(selectedArray: any, formData: any, setSelectedArray: any) {
  if (selectedArray.length == 0 || selectedArray.length < formData.length) {
    const cwRequests: any[] = formData.map((item: {id: any; status: any}) => {
      return {
        id: item.id,
        status: item.status,
      }
    })
    setSelectedArray([...cwRequests])
  } else {
    setSelectedArray([])
  }
}
