import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Merchant_Transaction_History_List from '../modules/Merchant_Portal/Merchant Transaction history/Merchant_Transaction_History_List'
import Merchant_Deposit_List from '../modules/Merchant_Portal/Merchant Deposit List/Merchant_Deposit_List'
import Merchant_Transaction_Source_List from '../modules/Merchant_Portal/Merchant Transaction Source List/Merchant_Transaction_Source_List'
import Merchant_Settlement_List from '../modules/Merchant_Portal/Merchant Settlement & Top Up List/Merchant_Settlement_List'
import Settlement_Create from '../modules/settlements/Settlement_Create'
import Merchant_Daily_Reporting_List from '../modules/Merchant_Portal/Merchant Reporting/Merchant Daily Report/Merchant_Daily_Report_List'
import Merchant_Monthly_Reporting_List from '../modules/Merchant_Portal/Merchant Reporting/Merchant Monthly Report/Merchant_Monthly_Report_List'
import Merchant_Detailed_Reporting_List from '../modules/Merchant_Portal/Merchant Reporting/Merchant Detailed Reporting/Detailed_Reporting_List'
import MerchantWalletVIP_List from '../modules/Merchant_Portal/Merchant_Wallet_VIP/MerchantWalletVIP_List'

const MerchantVIPRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='/wallet/list'
          element={
            <SuspensedView>
              <MerchantWalletVIP_List />
            </SuspensedView>
          }
        />
        <Route
          path='/merchant-transaction-history'
          element={
            <SuspensedView>
              <Merchant_Transaction_History_List />
            </SuspensedView>
          }
        />
        <Route
          path='/merchant-deposit-history'
          element={
            <SuspensedView>
              <Merchant_Deposit_List />
            </SuspensedView>
          }
        />
        <Route
          path='/merchant-transaction-source-history'
          element={
            <SuspensedView>
              <Merchant_Transaction_Source_List />
            </SuspensedView>
          }
        />
        <Route
          path='/merchant-settlements-topup'
          element={
            <SuspensedView>
              <Merchant_Settlement_List />
            </SuspensedView>
          }
        />
        <Route
          path='/settlement/create'
          element={
            <SuspensedView>
              <Settlement_Create />
            </SuspensedView>
          }
        />
        <Route
          path='/reporting/daily'
          element={
            <SuspensedView>
              <Merchant_Daily_Reporting_List />
            </SuspensedView>
          }
        />
        <Route
          path='/reporting/monthly'
          element={
            <SuspensedView>
              <Merchant_Monthly_Reporting_List />
            </SuspensedView>
          }
        />
        <Route
          path='/reporting/detailed'
          element={
            <SuspensedView>
              <Merchant_Detailed_Reporting_List />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {MerchantVIPRoutes}
