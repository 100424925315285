//Remove keys from array of objects

export default function deleteKeysFromObjects(arrayOfObjects: any, keysToDelete: any) {
  return arrayOfObjects.map((obj: any) => {
    keysToDelete.forEach((key: any) => {
      delete obj[key]
    })
    return obj
  })
}
