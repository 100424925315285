import React, {useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'

const RequestManagementPage_View: React.FC = () => {
  const navigate = useNavigate()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const [loading, setLoading] = useState(false)
  const [submission, setSubmission] = useState('')
  const [request, setRequest] = useState(Object)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const smsIdRef = React.createRef<any>()
  const transIdRef = React.createRef<any>()

  //ABSTRACT FUNCTION: Retrieve inidividual Request Information from API
  function retrieveRequest(SISPAY_API: string, url: string, location: string) {
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            reqId: window.location.pathname.split('/').pop(),
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let result = response.data.result[`${location}`]
        setRequest(result)
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    retrieveRequest(SISPAY_API, '/sms/getrequest', 'smsRequest')
  }, [])

  const initialValues = {
    smsID: String(request.smsId),
    smsProcId: String(request.smsProcId),
    smsTxnDT: String(request.smsTxnDT).replace('T', ' ').replace(/\.\d+/, ''),
    receivedDT: String(request.receivedDT).replace('T', ' ').replace(/\.\d+/, ''),
    source: String(request.source),
    walletAccName: String(request.walletAccName),
    walletAccNo: String(request.walletAccNo),
    walletCd: String(request.walletCd),
    transId: String(request.transId),
    txnId: String(request.txnId),
    senderReceiver: String(request.senderReceiver),
    serviceCenter: String(request.serviceCenter),
    amount: String(request.amount),
    smsBody: String(request.smsBody),
    smsType: String(request.smsType),
    smsTemplate: String(request.smsTemplate),
    txnFee: String(request.txnFee),
    suspectedReason: String(request.suspectedReason),
    smsBal: String(request.smsBal),
    walletBal: String(request.walletBal),
    balDiff: String(request.balDiff),
    merchantName: String(request.merchantName),
    merchantCd: String(request.merchantCd),
    merchant:
      String(request.merchantCd) == ''
        ? ''
        : String(request.merchantName) + ' - ' + String(request.merchantCd),
    customerCd: String(request.customerCd),
    srcWalletAcc: String(request.srcWalletAcc),
    submittedTxnId: String(request.submittedTxnId),
    depositReqId: String(request.depositReqId),
    reference: String(request.reference),
    remark: String(request.remark),
    submission: '',
  }

  const addUserSchema = Yup.object().shape({})

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        values.submission = submission
        if (submission == 'approve') {
          axios
            .post(
              `${SISPAY_API}/sms/request/approve`,
              {
                request: {
                  //session: '2403d806c141ff8c98692139a286941c',
                  reqId: window.location.pathname.split('/').pop(),
                  smsProcId: values.smsProcId,
                  smsId: values.smsID,
                  smsType: values.smsType,
                  remark: values.remark,
                  session: session,
                },
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
            .then(function (response) {
              if (response.data.code == '200') {
                navigate(-1)
                alert('Request Approved!')
              } else {
                alert(response.data.message)
                navigate(-1)
              }
            })
            .catch(function (error) {})
        } else if (submission == 'reject') {
          axios
            .post(
              `${SISPAY_API}/sms/request/reject`,
              {
                request: {
                  //session: '2403d806c141ff8c98692139a286941c',
                  reqId: window.location.pathname.split('/').pop(),
                  remark: values.remark,
                  session: session,
                },
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
            .then(function (response) {
              if (response.data.code == '200') {
                setLoading(false)
                navigate(-1)
                alert('Request Rejected!')
              } else {
                navigate(-1)
                alert(response.data.message)
              }
            })
            .catch(function (error) {})
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group SMS ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>SMS ID</label>
            <div className='d-flex'>
              <input
                ref={smsIdRef}
                placeholder='Enter your smsID'
                disabled={true}
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('smsID')}
                className={clsx(
                  'form-control bg-grey',
                  {
                    'is-invalid': formik.touched.smsID && formik.errors.smsID,
                  },
                  {
                    'is-valid': formik.touched.smsID && !formik.errors.smsID,
                  }
                )}
              />
              <button
                type='button'
                className='btn btn-icon btn-primary ms-5'
                onClick={() => window.open('/sms/list' + '/' + smsIdRef.current.value, '_blank')}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-chevron-double-right'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z'
                  />
                  <path
                    fill-rule='evenodd'
                    d='M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z'
                  />
                </svg>
              </button>
            </div>
            {formik.touched.smsID && formik.errors.smsID && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsID}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group SMS Transaction DateTime */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>SMS Transaction DateTime</label>
            <input
              placeholder='Enter your smsTxnDT'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('smsTxnDT')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.smsTxnDT && formik.errors.smsTxnDT,
                },
                {
                  'is-valid': formik.touched.smsTxnDT && !formik.errors.smsTxnDT,
                }
              )}
            />
            {formik.touched.smsTxnDT && formik.errors.smsTxnDT && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsTxnDT}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Received DateTime */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Received DateTime</label>
            <input
              placeholder='Enter your receivedDT'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('receivedDT')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.receivedDT && formik.errors.receivedDT,
                },
                {
                  'is-valid': formik.touched.receivedDT && !formik.errors.receivedDT,
                }
              )}
            />
            {formik.touched.receivedDT && formik.errors.receivedDT && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.receivedDT}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group source */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Source</label>
            <input
              placeholder='Input Source'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('source')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.source && formik.errors.source,
                },
                {
                  'is-valid': formik.touched.source && !formik.errors.source,
                }
              )}
            />
            {formik.touched.source && formik.errors.source && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.source}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account Name */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Account Name</label>
            <input
              placeholder='Input Wallet Account Name'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletAccName')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletAccName && formik.errors.walletAccName,
                },
                {
                  'is-valid': formik.touched.walletAccName && !formik.errors.walletAccName,
                }
              )}
            />
            {formik.touched.walletAccName && formik.errors.walletAccName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account No */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Account No</label>
            <input
              placeholder='Input Wallet Account No'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletAccNo')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletAccNo && formik.errors.walletAccNo,
                },
                {
                  'is-valid': formik.touched.walletAccNo && !formik.errors.walletAccNo,
                }
              )}
            />
            {formik.touched.walletAccNo && formik.errors.walletAccNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>
            <input
              placeholder='Input Wallet Code'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletCd && formik.errors.walletCd,
                },
                {
                  'is-valid': formik.touched.walletCd && !formik.errors.walletCd,
                }
              )}
            />
            {formik.touched.walletCd && formik.errors.walletCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Trans ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Trans ID</label>
            <div className='d-flex'>
              <input
                ref={transIdRef}
                placeholder='Enter your smsID'
                disabled={true}
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('transId')}
                className={clsx(
                  'form-control bg-grey',
                  {
                    'is-invalid': formik.touched.transId && formik.errors.transId,
                  },
                  {
                    'is-valid': formik.touched.transId && !formik.errors.transId,
                  }
                )}
              />
              <button
                type='button'
                className='btn btn-icon btn-primary ms-5'
                onClick={() =>
                  window.open('/transaction' + '/' + transIdRef.current.value, '_blank')
                }
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-chevron-double-right'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z'
                  />
                  <path
                    fill-rule='evenodd'
                    d='M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z'
                  />
                </svg>
              </button>
            </div>
            {formik.touched.transId && formik.errors.transId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.transId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group TxnId */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>TxnId</label>
            <input
              placeholder='Input TxnId'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('txnId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.txnId && formik.errors.txnId,
                },
                {
                  'is-valid': formik.touched.txnId && !formik.errors.txnId,
                }
              )}
            />
            {formik.touched.txnId && formik.errors.txnId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.txnId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Sender/Receiver */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Sender/Receiver</label>
            <input
              placeholder='Input Sender/Receiver'
              disabled={true}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('senderReceiver')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.senderReceiver && formik.errors.senderReceiver,
                },
                {
                  'is-valid': formik.touched.senderReceiver && !formik.errors.senderReceiver,
                }
              )}
            />
            {formik.touched.senderReceiver && formik.errors.senderReceiver && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.senderReceiver}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group serviceCenter */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Service Center</label>
            <input
              placeholder='Enter serviceCenter'
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('serviceCenter')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.serviceCenter && formik.errors.serviceCenter,
                },
                {
                  'is-valid': formik.touched.serviceCenter && !formik.errors.serviceCenter,
                }
              )}
            />
            {formik.touched.serviceCenter && formik.errors.serviceCenter && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.serviceCenter}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Amount */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Amount</label>
            <input
              placeholder='Enter Amount'
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('amount')}
              className={clsx(
                'form-control bg-grey',
                {'is-invalid': formik.touched.amount && formik.errors.amount},
                {
                  'is-valid': formik.touched.amount && !formik.errors.amount,
                }
              )}
            />
            {formik.touched.amount && formik.errors.amount && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.amount}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group SMS Body */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>SMS Body</label>
            <input
              placeholder='Enter SMS Body'
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('smsBody')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.smsBody && formik.errors.smsBody,
                },
                {
                  'is-valid': formik.touched.smsBody && !formik.errors.smsBody,
                }
              )}
            />
            {formik.touched.smsBody && formik.errors.smsBody && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsBody}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group SMS Type */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>SMS Type</label>
            <input
              placeholder='Enter SMS Type'
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('smsType')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.smsType && formik.errors.smsType,
                },
                {
                  'is-valid': formik.touched.smsType && !formik.errors.smsType,
                }
              )}
            />
            {formik.touched.smsType && formik.errors.smsType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsType}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group SMS Template */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>SMS Template</label>
            <input
              placeholder='Enter SMS Template'
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('smsTemplate')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.smsTemplate && formik.errors.smsTemplate,
                },
                {
                  'is-valid': formik.touched.smsTemplate && !formik.errors.smsTemplate,
                }
              )}
            />
            {formik.touched.smsTemplate && formik.errors.smsTemplate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsTemplate}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Transaction Fee */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Transaction Fee</label>
            <input
              placeholder='Enter Transaction Fee'
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('txnFee')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.txnFee && formik.errors.txnFee,
                },
                {
                  'is-valid': formik.touched.txnFee && !formik.errors.txnFee,
                }
              )}
            />
            {formik.touched.txnFee && formik.errors.txnFee && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.txnFee}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Suspected Reason*/}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Suspected Reason</label>
            <input
              placeholder='Enter Suspected Reason'
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('suspectedReason')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.suspectedReason && formik.errors.suspectedReason,
                },
                {
                  'is-valid': formik.touched.suspectedReason && !formik.errors.suspectedReason,
                }
              )}
            />
            {formik.touched.suspectedReason && formik.errors.suspectedReason && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.suspectedReason}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Balance */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Balance</label>
            <input
              placeholder='Enter Balance'
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('smsBal')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.smsBal && formik.errors.smsBal,
                },
                {
                  'is-valid': formik.touched.smsBal && !formik.errors.smsBal,
                }
              )}
            />
            {formik.touched.smsBal && formik.errors.smsBal && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.smsBal}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Balance */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Wallet Balance</label>
            <input
              placeholder='Enter Wallet Balance'
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('walletBal')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletBal && formik.errors.walletBal,
                },
                {
                  'is-valid': formik.touched.walletBal && !formik.errors.walletBal,
                }
              )}
            />
            {formik.touched.walletBal && formik.errors.walletBal && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletBal}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Balance Different*/}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Balance Different</label>
            <input
              placeholder='Enter smsBal different'
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('balDiff')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.balDiff && formik.errors.balDiff,
                },
                {
                  'is-valid': formik.touched.balDiff && !formik.errors.balDiff,
                }
              )}
            />
            {formik.touched.balDiff && formik.errors.balDiff && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.balDiff}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Merchant */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Merchant</label>
            <input
              placeholder={formik.values.merchant == '' ? 'EMPTY' : 'Enter Merchant'}
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('merchant')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.merchant && formik.errors.merchant,
                },
                {
                  'is-valid': formik.touched.merchant && !formik.errors.merchant,
                }
              )}
            />
            {formik.touched.merchant && formik.errors.merchant && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.merchant}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Customer Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Customer Code</label>
            <input
              placeholder={formik.values.customerCd == '' ? 'EMPTY' : 'Enter Customer Code'}
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('customerCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.customerCd && formik.errors.customerCd,
                },
                {
                  'is-valid': formik.touched.customerCd && !formik.errors.customerCd,
                }
              )}
            />
            {formik.touched.customerCd && formik.errors.customerCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.customerCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Source Wallet Account */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Source Wallet Account</label>
            <input
              placeholder={formik.values.customerCd == '' ? 'EMPTY' : 'Enter Source Wallet Acc'}
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('srcWalletAcc')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.srcWalletAcc && formik.errors.srcWalletAcc,
                },
                {
                  'is-valid': formik.touched.srcWalletAcc && !formik.errors.srcWalletAcc,
                }
              )}
            />
            {formik.touched.srcWalletAcc && formik.errors.srcWalletAcc && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.srcWalletAcc}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Submitted Transaction ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Submitted Transaction ID</label>
            <input
              placeholder={
                formik.values.customerCd == '' ? 'EMPTY' : 'Enter Submitted Transaction ID'
              }
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('submittedTxnId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.submittedTxnId && formik.errors.submittedTxnId,
                },
                {
                  'is-valid': formik.touched.submittedTxnId && !formik.errors.submittedTxnId,
                }
              )}
            />
            {formik.touched.submittedTxnId && formik.errors.submittedTxnId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.submittedTxnId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Deposit Request ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Deposit Request ID</label>
            <input
              placeholder={formik.values.customerCd == '' ? 'EMPTY' : 'Enter Deposit Request ID'}
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('depositReqId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.depositReqId && formik.errors.depositReqId,
                },
                {
                  'is-valid': formik.touched.depositReqId && !formik.errors.depositReqId,
                }
              )}
            />
            {formik.touched.depositReqId && formik.errors.depositReqId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.depositReqId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Reference*/}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Reference</label>
            <input
              placeholder='Enter reference'
              type='text'
              disabled={true}
              autoComplete='off'
              {...formik.getFieldProps('reference')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.reference && formik.errors.reference,
                },
                {
                  'is-valid': formik.touched.reference && !formik.errors.reference,
                }
              )}
            />
            {formik.touched.reference && formik.errors.reference && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.reference}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          {/* <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-danger w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
              onClick={() => {
                setSubmission('reject')
              }}
            >
              {!loading && <span className='indicator-label'>REJECT</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div> */}
          {/* end::Form group */}
          <div className='row'>
            <div className='col'>
              {' '}
              <button
                type='button'
                className='btn btn-danger w-100 mb-5'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                disabled={formik.isSubmitting || !formik.isValid}
                onClick={() => {
                  setSubmission('reject')
                }}
                style={{
                  opacity: request.status === 'PENDING' ? '1' : '0',
                  visibility: request.status === 'PENDING' ? 'visible' : 'hidden',
                }}
              >
                REJECT
              </button>
            </div>
            <div className='col'>
              <button
                type='button'
                className='btn btn-success w-100 mb-5'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
                disabled={formik.isSubmitting || !formik.isValid}
                onClick={() => {
                  setSubmission('approve')
                }}
                style={{
                  opacity: request.status === 'PENDING' ? '1' : '0',
                  visibility: request.status === 'PENDING' ? 'visible' : 'hidden',
                }}
              >
                APROVE
              </button>
            </div>
          </div>

          <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>
                    {submission == 'approve' ? 'Approve Request' : 'Reject Request'}
                  </h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <h5>Remarks</h5>
                  <textarea
                    {...formik.getFieldProps('remark')}
                    className='w-100 h-200px'
                  ></textarea>
                </div>
                <div className='modal-footer'>
                  <button
                    type='submit'
                    className={submission == 'approve' ? 'btn btn-success' : 'btn btn-danger'}
                    data-bs-dismiss='modal'
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Remarks</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <textarea
                    {...formik.getFieldProps('remark')}
                    className='w-100 h-200px'
                  ></textarea>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-success' data-bs-dismiss='modal'>
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div> */}

          {/* begin::Form group */}
          {/* <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
              onClick={() => {
                setSubmission('approve')
              }}
            >
              {!loading && <span className='indicator-label'>APPROVE</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div> */}
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}

export default RequestManagementPage_View
