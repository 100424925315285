export function sortByName(filteredFormData: any, key: string) {
  var byName = filteredFormData.slice(0)
  byName.sort(function (a: any, b: any) {
    var x = a[key].toLowerCase()
    var y = b[key].toLowerCase()
    return x < y ? -1 : x > y ? 1 : 0
  })

  return byName
}
