import React, {useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'

const Whitelist_Source_Edit: React.FC = () => {
  const navigate = useNavigate()
  const location: any = useLocation()
  const [loading, setLoading] = useState(false)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  

  const initialValues = {
    source: '',
    walletCd: '',
    description: '',
    active: '',
  }

  const addUserSchema = Yup.object().shape({
    source: Yup.string().required('Source is required'),
    walletCd: Yup.string().required('Wallet Code is required'),
    description: Yup.string(),
    active: Yup.string().required('Active status is required'),
  })

  const formik = useFormik({
    initialValues,
    //enableReinitialize: true,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        axios
          .post(
            `${SISPAY_API}/whitelist/source/create`,
            {
              request: {
                source: values.source,
                walletCd: values.walletCd,
                description: values.description,
                active: values.active,
                session: session,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response) {
            
            setLoading(false)
            navigate(-1)
            alert('Whitelist Source Created!')
          })
          .catch(function (error) {
            
          })
        
      } catch (error) {
        // console.error(error)
        // saveAuth(undefined)
        // setStatus('The registration details is incorrect')
        // setSubmitting(false)
        // setLoading(false)
      }
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group Source */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Source</label>
            <input
              placeholder='Enter your source'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('source')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.source && formik.errors.source,
                },
                {
                  'is-valid': formik.touched.source && !formik.errors.source,
                }
              )}
            />
            {formik.touched.source && formik.errors.source && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.source}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Code */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Wallet Code</label>
            <select
              {...formik.getFieldProps('walletCd')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.walletCd && formik.errors.walletCd,
                },
                {
                  'is-valid': formik.touched.walletCd && !formik.errors.walletCd,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='BKASH'>BKASH</option>
              <option value='ROCKET'>ROCKET</option>
              <option value='NAGAD'>NAGAD</option>
            </select>
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group Description */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Description</label>
            <input
              placeholder='
              Input remark/desc of the user'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.description && formik.errors.description,
                },
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Active */}
          <div className='fv-row mb-8' id='active'>
            <label className='form-label required fw-bolder text-dark fs-6'>Active</label>
            <select
              {...formik.getFieldProps('active')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.active && formik.errors.active,
                },
                {
                  'is-valid': formik.touched.active && !formik.errors.active,
                }
              )}
              data-placeholder=''
            >
              {/* Here should be imported using API */}
              <option value=''>Please Select</option>
              <option value='true'>Yes</option>
              <option value='false'>No</option>
            </select>
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>SUBMIT</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}

export default Whitelist_Source_Edit
