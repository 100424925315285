import axios from 'axios'
import {stringify} from 'querystring'
import {useState, useEffect, useRef} from 'react'
//import {sortByName} from '../../../components/SortByName'
import Select from 'react-select'
import {customStyles} from '../../components/CustomStyles'

export default function Bulk_Withdrawal_Approval(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  let selectedArray: any = props.selectedArray
  let setSelectedArray: any = props.setSelectedArray
  let setFormData = props.setFormData
  let selectedWalletCdFilter: any = props.selectedWalletCdFilter
  let withdrawalFilterSubmit = props.withdrawalFilterSubmit

  // const [withdrawalRequests, setWithdrawalRequests] = useState([])
  const [walletCdList, setWalletCdList] = useState(Object)
  const [selectedWalletCd, setSelectedWalletCd] = useState('')
  const selectedWalletCdRef = useRef<any>()
  const approvalRemark = useRef<any>()

  function retrieveAgent() {
    axios
      .post(SISPAY_API + '/wallet/list', {
        request: {
          session: session,
        },
      })
      .then((response) => {
        let result = response.data.result['wallet']
        const walletList = Array.from(
          new Set(
            result
              .filter(
                (wallet: any) =>
                  wallet.status == 'ACTIVE' && wallet.walletCd == selectedWalletCdFilter
              )
              .map((wallet: any) => wallet.walletAccNo)
          )
        ).map((walletAccNo) => ({value: walletAccNo, label: walletAccNo}))

        setWalletCdList(walletList)
      })
      .catch((error) => {
        alert(error)
      })
  }

  useEffect(() => {
    retrieveAgent()
  }, [selectedWalletCdFilter])

  //ABSTRACT FUNCTION: Retrieve data from API
  // function retrieveData(SISPAY_API: string, url: string, location: string) {
  //   axios
  //     .post(
  //       `${SISPAY_API}${url}`,
  //       {
  //         request: {
  //           session: session,
  //         },
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     )
  //     .then(function (response) {
  //       setWithdrawalRequests(response.data.result[`${location}`])
  //       setFormData(response.data.result[`${location}`])
  //     })
  //     .catch(function (error) {})
  // }

  function bulkApprove(selectedArray: any) {
    if (selectedArray.length !== 0) {
      axios
        .post(
          `${SISPAY_API}/cash/withdrawalrequest/bulkapprove`,
          {
            request: {
              session: session,
              walletAccNo: selectedWalletCd,
              walletCd: selectedWalletCdFilter,
              approvalRejectionRemark: approvalRemark.current?.value,
              withdrawalReqs: selectedArray,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          withdrawalFilterSubmit.current.click()
          if (response.data.code == '200') {
            alert(response.data.message)
          } else {
            alert(response.data.message)
          }
        })
        .catch(function (error) {
          alert(error)
        })
    }
  }

  // useEffect(() => {
  //   retrieveData(SISPAY_API, '/cash/withdrawalrequest/list', 'depositRequestList')
  // }, [])

  return (
    <div
      className='modal fade'
      id='kt_modal_bulk_success'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              Bulk Approve Withdrawal Requests : {selectedWalletCdFilter}
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => {
                selectedWalletCdRef.current.setValue('')
                setSelectedWalletCd('')
              }}
            ></button>
          </div>
          <div className='modal-body '>
            <label className='form-label fw-bolder text-dark fs-6'>1. Select Agent Wallet: </label>
            <Select
              ref={selectedWalletCdRef}
              className='basic-single mb-5'
              classNamePrefix='select'
              options={walletCdList}
              styles={customStyles}
              name='walletCd'
              onChange={(e: any) => {
                setSelectedWalletCd(e.value)
              }}
            />
            <label className='form-label fw-bolder text-dark fs-6'>2. Remark: </label>
            <textarea
              name='approvalRemark'
              ref={approvalRemark}
              id='approvalRemark'
              className='w-100 mb-5'
              rows={5}
            ></textarea>
            <label className='form-label fw-bolder text-dark fs-6'>3. Selected Requests: </label>

            <div className='d-flex flex-wrap'>
              {selectedArray.length > 0
                ? selectedArray.map((data: any, index: number) => {
                    return (
                      <div
                        className='d-flex d-flex-column me-5 mb-5'
                        style={{
                          width: 'auto',
                        }}
                      >
                        <div
                          style={{
                            width: 'auto',
                            backgroundColor: 'grey',
                            padding: '0 10px 0 10px',
                            color: 'white',
                            textAlign: 'center',
                            borderRadius: '5px 0 0 5px',
                          }}
                        >
                          {' '}
                          ID
                        </div>
                        <div
                          style={{
                            width: 'auto',
                            padding: '0 10px 0 10px',
                            backgroundColor: 'white',
                            color: 'black',
                            textAlign: 'center',
                            borderRadius: '0 5px 5px 0',
                          }}
                        >
                          {data.id}
                        </div>
                      </div>
                    )
                  })
                : 'No Wallet Selected'}
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary'
              data-bs-dismiss='modal'
              onClick={() => {
                selectedWalletCdRef.current.setValue('')
                setSelectedWalletCd('')
              }}
            >
              Close
            </button>
            {selectedArray.length > 0 && (
              <button
                type='button'
                className='btn btn-success'
                data-bs-dismiss='modal'
                disabled={selectedWalletCd == '' ? true : false}
                onClick={() => {
                  bulkApprove(selectedArray)
                  selectedWalletCdRef.current.setValue('')
                  setSelectedWalletCd('')
                  setSelectedArray([])
                }}
              >
                Approve
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
