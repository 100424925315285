import axios from 'axios'
import {useRef} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers/components/KTSVG'
import {sortByDate} from '../../../components/SortByDate'
import {getCurrentDateInput} from '../../../components/GetCurrentDateInput'

export function Cancel_Settlement(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  let setRemarks = props.setRemarks
  let remarks = props.remarks
  let id = props.currentSettlement
  let setCurrentSettlement = props.setCurrentSettlement
  let setFormData = props.setFormData
  const textarea = useRef<any>(null)

  function retrieveData() {
    axios
      .post(
        `${SISPAY_API}/merchant/settlementtopup/list`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let result = response.data.result.merchantSettTopup
        result = result.filter((data: {settTopupSubmissionDT: string}) => {
          const settTopupSubmissionDT = data.settTopupSubmissionDT.substring(0, 10)
          return (
            settTopupSubmissionDT >= getCurrentDateInput() &&
            settTopupSubmissionDT <= getCurrentDateInput()
          )
        })
        setFormData(sortByDate(result, 'settTopupSubmissionDT'))
      })
      .catch(function (error) {
        alert(error)
      })
  }

  function approveRequest() {
    axios
      .post(
        `${SISPAY_API}/merchant/settlementtopup/cancel`,
        {
          request: {
            id: id,
            notesRemarks: remarks,
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        setRemarks('') // Reset remarks to an empty string
        if (textarea.current) {
          textarea.current.value = '' // Clear the textarea's value
        }
        if (response.data.code == '200') {
          alert('Settlement Request Cancelled!')
        } else {
          alert(response.data.message)
        }
        retrieveData()
      })
      .catch(function (error) {
        alert(error)
      })
  }

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_cancel_settlement'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Settlement Approval: {id}</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <textarea
              className='w-100 h-200px'
              ref={textarea}
              onChange={(e) => {
                setRemarks(e.target.value)
              }}
            ></textarea>
          </div>
          <div className='modal-footer'>
            <button
              type='submit'
              className='btn btn-danger'
              data-bs-dismiss='modal'
              onClick={() => {
                approveRequest()
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
