import React, {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'
import Select from 'react-select'
import {customStyles} from '../components/CustomStyles'
import {Overlay} from '../components/Overlay'
import {sortByName} from '../components/SortByName'

const Settlement_Create: React.FC = () => {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const navigate = useNavigate()
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const merchantCdStored = localStorage.getItem('merchantCd')

  const [loading, setLoading] = useState(false)
  const [merchantCodeList, setmerchantCodeList] = useState()
  const [multi, setMulti] = useState([])
  const selectedMerchant = useRef<any>()

  //ABSTRACT FUNCTION: Retrieve data from API
  function retrieveData(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let result = response.data.result[`${location}`]
        let merchantCode: object
        let merchantCodeList: any = []

        for (let i = 0; i < result.length; i++) {
          merchantCode = {
            value: result[i].merchantCd,
            label: result[i].merchantName + ' - ' + result[i].merchantCd,
          }
          merchantCodeList.push(merchantCode)
        }

        merchantCodeList = Array.from(new Set(merchantCodeList))

        setmerchantCodeList(sortByName(merchantCodeList, 'label'))
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    retrieveData(SISPAY_API, '/merchant/list', 'merchant')
  }, [])

  const initialValues = {
    requestType: '',
    merchantCd: merchantCdStored == '' ? '' : merchantCdStored,
    bankName: '',
    accHolderName: '',
    bankAccNo: '',
    amount: '',
    requestDate: '',
    notesRemarks: '',
  }

  const addUserSchema = Yup.object().shape({
    requestType: Yup.string().required('Request Type is required'),
    merchantCd: Yup.string().required('Merchant Code is required'),
    bankName: Yup.string().required('Bank Name is required'),
    accHolderName: Yup.string().required('Account Holder Name is required'),
    bankAccNo: Yup.string()
      .required('Account Number is required')
      .matches(/^\d+$/, 'Only Numbers.'),
    amount: Yup.string()
      .required('Amount is required')
      .matches(/^[0-9]+(\.[0-9][0-9]?)?$/, 'Maximum 2 decimals'),
    requestDate: Yup.string().required('Request Date is required'),
    notesRemarks: Yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      function extractValues(merchants: {value: string; label: string}[]): string {
        return merchants.map((merchant) => merchant.value).join('|')
      }

      let multiString = extractValues(multi)

      try {
        axios
          .post(
            `${SISPAY_API}/merchant/settlementtopup/create`,
            {
              request: {
                session: session,
                requestType: values.requestType,
                merchantCd: values.merchantCd,
                bankName: values.bankName,
                accHolderName: values.accHolderName,
                bankAccNo: values.bankAccNo,
                amount: values.amount,
                requestDate: values.requestDate,
                notesRemarks: values.notesRemarks,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response) {
            if (response.data.code == '200') {
              setLoading(false)
              navigate(-1)
              alert(response.data.message)
            } else {
              alert(response.data.message)
            }
          })
          .catch(function (error) {})
      } catch (error) {}
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
        <span className='text-gray-800 fs-1 fw-bolder ms-5 d-flex align-items-center'>
          Create Settlement & Top Up
        </span>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          <div className='row'>
            {/* begin::Form group*/}
            <div className='fv-row mb-8'>
              <label className='form-label required fw-bolder text-dark fs-6'>Request Type</label>
              <select
                {...formik.getFieldProps('requestType')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.requestType && formik.errors.requestType,
                  },
                  {
                    'is-valid': formik.touched.requestType && !formik.errors.requestType,
                  }
                )}
                data-placeholder=''
              >
                <option value=''>Please Select</option>
                <option value='SETTLEMENT'>SETTLEMENT</option>
                <option value='TOPUP'>TOPUP</option>
              </select>
              {formik.touched.requestType && formik.errors.requestType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.requestType}</span>
                  </div>
                </div>
              )}
            </div>{' '}
            {/* end::Form group */}
            {/* begin::Form group*/}
            <div className='fv-row mb-8'>
              <label className='form-label required fw-bolder text-dark fs-6'>Merchant</label>
              {merchantCdStored == '' ? (
                <Select
                  ref={selectedMerchant}
                  className='basic-single'
                  classNamePrefix='select'
                  options={merchantCodeList}
                  styles={customStyles}
                  name='merchantCd'
                  onChange={(e: any) => formik.setFieldValue('merchantCd', e.value)}
                />
              ) : (
                <input
                  placeholder='Enter Merchant'
                  disabled={true}
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('merchantCd')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.merchantCd && formik.errors.merchantCd,
                    },
                    {
                      'is-valid': formik.touched.merchantCd && !formik.errors.merchantCd,
                    }
                  )}
                />
              )}
              {formik.touched.merchantCd && formik.errors.merchantCd && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.merchantCd}</span>
                  </div>
                </div>
              )}
            </div>{' '}
            {/* begin::Form group Search */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>Bank Name</label>
              <input
                placeholder='Enter bank name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('bankName')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.bankName && formik.errors.bankName,
                  },
                  {
                    'is-valid': formik.touched.bankName && !formik.errors.bankName,
                  }
                )}
              />
              {formik.touched.bankName && formik.errors.bankName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.bankName}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
            {/* begin::Form group  */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>Account Holder Name</label>
              <input
                placeholder='Enter account holder name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('accHolderName')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.accHolderName && formik.errors.accHolderName,
                  },
                  {
                    'is-valid': formik.touched.accHolderName && !formik.errors.accHolderName,
                  }
                )}
              />
              {formik.touched.accHolderName && formik.errors.accHolderName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.accHolderName}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>Account Number</label>
              <input
                placeholder='Enter account Number'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('bankAccNo')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.bankAccNo && formik.errors.bankAccNo,
                  },
                  {
                    'is-valid': formik.touched.bankAccNo && !formik.errors.bankAccNo,
                  }
                )}
              />
              {formik.touched.bankAccNo && formik.errors.bankAccNo && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.bankAccNo}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>Amount</label>
              <input
                placeholder='Enter Amount'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('amount')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.amount && formik.errors.amount,
                  },
                  {
                    'is-valid': formik.touched.amount && !formik.errors.amount,
                  }
                )}
              />
              {formik.touched.amount && formik.errors.amount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.amount}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>Request Date</label>
              <input
                placeholder='Enter Request Date'
                type='date'
                autoComplete='off'
                {...formik.getFieldProps('requestDate')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.requestDate && formik.errors.requestDate,
                  },
                  {
                    'is-valid': formik.touched.requestDate && !formik.errors.requestDate,
                  }
                )}
              />
              {formik.touched.requestDate && formik.errors.requestDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.requestDate}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>Notes/ Remarks</label>
              <textarea
                {...formik.getFieldProps('notesRemarks')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.notesRemarks && formik.errors.notesRemarks,
                  },
                  {
                    'is-valid': formik.touched.notesRemarks && !formik.errors.notesRemarks,
                  }
                )}
                name='notesRemarks'
                id='notesRemarks'
                rows={3}
              ></textarea>
              {formik.touched.notesRemarks && formik.errors.notesRemarks && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.notesRemarks}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
            <div className='col'>
              {/* begin::Form group */}
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                >
                  {!loading && <span className='indicator-label'>SUBMIT</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Form group */}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Settlement_Create
