const Modal = (loading: any) => {
  // Keyframes for spin animation
  const spinKeyframes = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

  const overlayStyle: any = {
    display: loading ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  }

  const modalStyle: any = {
    display: loading ? 'block' : 'none',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    backgroundColor: '#1e1e2d',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    zIndex: 1000,
    borderRadius: '0.625rem',
  }

  const spinnerStyle = {
    border: '4px solid rgba(0, 0, 0, 0.3)',
    borderTop: '4px solid #3498db',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
  }

  return (
    <>
      <style>{spinKeyframes}</style>
      <div style={overlayStyle}>
        <div style={modalStyle}>
          <div style={spinnerStyle}></div>
        </div>
      </div>
    </>
  )
}

export default Modal
