import {saveAs} from 'file-saver'
import {modifyKeys} from './ModifyKeys'
import {modifyDT} from './ModifyDT'

// Function to rearrange the dataset based on keyMap
function rearrangeDataset(originalData: any, keyMap: any) {
  return originalData.map((item: any) => {
    const rearrangedItem: any = {}
    for (const key in keyMap) {
      rearrangedItem[keyMap[key]] = item[key]
    }
    return rearrangedItem
  })
}

export function Export_Excel(
  formData: any,
  keyMap: any,
  startDate: string,
  endDate: string,
  fileName: string
) {
  const XLSX = require('xlsx')
  let dataset = rearrangeDataset(formData, keyMap) // Use rearrangeDataset instead of modifyKeys
  dataset = dataset.map((obj: any) => {
    const updatedObj = {...obj}
    // Replace commas in merchantName with a different character (e.g., semicolon)
    if (updatedObj['Merchant Name']) {
      updatedObj['Merchant Name'] = obj['Merchant Name'].replace(/,/g, '; ')
    }

    if (updatedObj['Agent Name']) {
      updatedObj['Agent Name'] = obj['Agent Name'].replace(/,/g, '; ')
    }

    if (updatedObj['Request DateTime']) {
      updatedObj['Request DateTime'] = obj['Request DateTime']
        .replace('T', ' ')
        .replace(/\.\d+/, '')
    }

    if (updatedObj['Created Date']) {
      updatedObj['Created Date'] = obj['Created Date'].replace('T', ' ').replace(/\.\d+/, '')
    }

    if (updatedObj['Deactivated Date']) {
      updatedObj['Deactivated Date'] = obj['Deactivated Date']
        .replace('T', ' ')
        .replace(/\.\d+/, '')
    }

    if (updatedObj['Completed DateTime']) {
      updatedObj['Completed DateTime'] = obj['Completed DateTime']
        .replace('T', ' ')
        .replace(/\.\d+/, '')
    }

    if (updatedObj['Received DateTime']) {
      updatedObj['Received DateTime'] = obj['Received DateTime']
        .replace('T', ' ')
        .replace(/\.\d+/, '')
    }

    delete updatedObj.merchantCd
    return updatedObj
  })
  const worksheet = XLSX.utils.json_to_sheet(dataset)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
  const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'buffer'})
  const fileData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  saveAs(fileData, `${fileName} ${startDate} - ${endDate}.xlsx`) // Requires the FileSaver library or a similar method
}
