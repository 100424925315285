import {getCurrentDateTime} from '../../components/GetCurrentDatetime'
import {modifyDT} from '../../components/ModifyDT'

function escapeCsvValue(value: string): string {
  // If the value contains a comma or line breaks, wrap it in double quotes and escape existing double quotes and line breaks
  if (value.includes(',') || value.includes('\n') || value.includes('\r')) {
    return `"${value.replace(/"/g, '""').replace(/[\n\r]+/g, ' ')}"`
  }
  return value
}

export default function Request_Export_CSV(data: any): void {
  let dataset = data.map((obj: any) => {
    const updatedObj = {...obj}
    // delete updatedObj.transId
    // delete updatedObj.reqId
    return updatedObj
  })

  //Modify receivedDT
  dataset = modifyDT(dataset, 'Received DateTime')
  dataset = modifyDT(dataset, 'SMS Transaction DateTime')

  const header = Object.keys(dataset[0]).join(',')
  const rows = dataset.map((transaction: any) =>
    Object.values(transaction)
      .map((value: any) => escapeCsvValue(value.toString()))
      .join(',')
  )

  const csvContent = `${header}\n${rows.join('\n')}`
  const blob = new Blob([csvContent], {type: 'text/csv'})

  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `request_${getCurrentDateTime()}.csv`
  a.click()

  URL.revokeObjectURL(url)
}
