// Function to rearrange the dataset based on keyMap
function rearrangeDataset(originalData: any, keyMap: any) {
  return originalData.map((item: any) => {
    const rearrangedItem: any = {}
    for (const key in keyMap) {
      rearrangedItem[keyMap[key]] = item[key]
    }
    return rearrangedItem
  })
}

export function Export_CSV(
  formData: any,
  keyMap: any,
  startDate: string,
  endDate: string,
  fileName: string
) {
  let dataset = rearrangeDataset(formData, keyMap) // Use rearrangeDataset instead of modifyKeys

  dataset = dataset.map((obj: any) => {
    const updatedObj = {...obj}
    // Replace commas in merchantName with a different character (e.g., semicolon)
    if (updatedObj['Merchant Name']) {
      updatedObj['Merchant Name'] = obj['Merchant Name'].replace(/,/g, '; ')
    }

    if (updatedObj['Agent Name']) {
      updatedObj['Agent Name'] = obj['Agent Name'].replace(/,/g, '; ')
    }

    if (updatedObj['Request DateTime']) {
      updatedObj['Request DateTime'] = obj['Request DateTime']
        .replace('T', ' ')
        .replace(/\.\d+/, '')
    }

    if (updatedObj['Created Date']) {
      updatedObj['Created Date'] = obj['Created Date'].replace('T', ' ').replace(/\.\d+/, '')
    }

    if (updatedObj['Deactivated Date']) {
      updatedObj['Deactivated Date'] = obj['Deactivated Date']
        .replace('T', ' ')
        .replace(/\.\d+/, '')
    }

    if (updatedObj['Completed DateTime']) {
      updatedObj['Completed DateTime'] = obj['Completed DateTime']
        .replace('T', ' ')
        .replace(/\.\d+/, '')
    }

    if (updatedObj['Received DateTime']) {
      updatedObj['Received DateTime'] = obj['Received DateTime']
        .replace('T', ' ')
        .replace(/\.\d+/, '')
    }

    delete updatedObj.merchantCd
    return updatedObj
  })

  const csvData = dataset.map((row: any) => Object.values(row).join(','))
  const csvHeaders = Object.keys(dataset[0]).join(',')

  const csvContent = `data:text/csv;charset=utf-8,${csvHeaders}\n${csvData.join('\n')}`
  const encodedURI = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedURI)
  link.setAttribute('download', `${fileName} ${startDate} - ${endDate}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
