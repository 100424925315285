import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

export function Export_PDF_Monthly_Report(formData: any, startDate: string, endDate: string) {
  // Register the fonts with pdfMake
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  const documentDefinition = {
    pageOrientation: 'landscape' as 'landscape', // Cast the pageOrientation value explicitly
    pageSize: 'A2' as 'A2',
    content: [
      {text: `Exported Data ${startDate} - ${endDate}`, style: 'header'},
      '\n', // Add a line break
      {
        table: {
          //widths: ['*', '*', '*'], // Adjust column widths as needed
          body: [
            [
              'Merchant Name',
              'Merchant Code',
              'Month',
              'Opening Balance',
              'Commission Fee',
              'Total Deposit',
              'Deposit Fee',
              'Total Withdrawal',
              'Withdrawal Fee',
              'Total Topup',
              'Top up Fee',
              'Total Settlement',
              'Settlement Fee',
              'Closing Balance',
            ], // Add headers as a separate row
            ...formData.map((item: any) => [
              item.merchantName,
              item.merchantCd,
              item.month,
              item.openingBalance,
              item.commissionFee,
              item.totalDeposit,
              item.tDepositFee,
              item.totalWithdrawal,
              item.tWithdrawalFee,
              item.totalTopup,
              item.tTopUpFee,
              item.totalSettlement,
              item.tSettlementFee,
              item.closingBalance,
            ]),
          ],
        },
        layout: 'lightHorizontalLines', // Add a layout to handle long tables
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
    },
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
  pdfDocGenerator.download(`Exported Data ${startDate} - ${endDate}.pdf`)
}
