import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_metronic/helpers'
import axios from 'axios'
import Select from 'react-select'
import {ErrorHandling} from '../../components/ErrorHandling'
import {useAuth} from '../../auth'
import {customStyles} from '../../components/CustomStyles'
import {sortByName} from '../../components/SortByName'

const WalletListPage_Add: React.FC = () => {
  const {currentUser, logout} = useAuth()
  const navigate = useNavigate()
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  const [loading, setLoading] = useState(false)
  const [navAssign, setNavAssign] = useState(Boolean)

  const [merchantCodes, setMerchantCodes] = useState()
  const [multi, setMulti] = useState([])
  const [walletAssign, setWalletAssign] = useState(false)
  const [agentCdOptions, setAgentCdOptions] = useState([])

  const initialValues = {
    walletAccName: '',
    walletAccNo: '',
    walletCd: '',
    status: '',
    walletType: '',
    dailyDepositLmt: '',
    dailyWithdrawalLmt: '',
    mobileNo: '',
    minDeposit: '',
    maxDeposit: '',
    minWithdrawal: '',
    maxWithdrawal: '',
    prefAcc: 'false',
    weightage: '',
    startBal: '',
    balDiff: '',
    walletBal: '',
    session: '',
    isAgentWallet: '',
    agentCd: '',

    //Wallet Assignment
    merchantCd: '',
    walletAssignmentStatus: '',
  }

  const addUserSchema = Yup.object().shape({
    walletAccName: Yup.string(),
    walletAccNo: Yup.string().required('Wallet Account Number is required'),
    walletCd: Yup.string().required('Wallet Code is required'),
    status: Yup.string().required('Status is required'),
    walletType: Yup.string().required('Wallet Type is required'),
    dailyDepositLmt: Yup.string().required('Daily Deposit Limit is required'),
    dailyWithdrawalLmt: Yup.string().required('Daily Withdrawal Limit is required'),
    mobileNo: Yup.string().required('Mobile Number is required'),
    minDeposit: Yup.string().required('Minimum Deposit is required'),
    maxDeposit: Yup.string().required('Maximum Deposit is required'),
    minWithdrawal: Yup.string().required('Minimum Withdrawal is required'),
    maxWithdrawal: Yup.string().required('Maximum Withdrawal is required'),
    prefAcc: Yup.boolean().required('Preferred Account is required'),
    weightage: Yup.number().when('prefAcc', {
      is: true,
      then: Yup.number().required('Weightage is required'),
    }),
    startBal: Yup.string().required('Starting Balance is required'),
    balDiff: Yup.string().required('Balance Different is required'),
    walletBal: Yup.string(),
    isAgentWallet: Yup.boolean().required('Is Agent Wallet is required'),
    agentCd: Yup.number().when('isAgentWallet', {
      is: true,
      then: Yup.number().required('Agent Code is required'),
    }),

    //Wallet Assignment
    assigningWallet: Yup.boolean(),
    walletAssignmentStatus: Yup.string().when('assigningWallet', {
      is: true,
      then: Yup.string().required('Status is required'),
    }),
  })

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let sb: any = values.startBal
        let bd: any = values.balDiff
        let different: any = sb - bd
        values.walletBal = different
        values.session = session

        axios
          .post(
            `${SISPAY_API}/wallet/create`,
            {
              request: {
                walletAccName: values.walletAccName.trim(),
                walletAccNo: values.walletAccNo.trim(),
                walletCd: values.walletCd,
                status: values.status,
                walletType: values.walletType,
                dailyDepositLmt: values.dailyDepositLmt,
                dailyWithdrawalLmt: values.dailyWithdrawalLmt,
                mobileNo: values.mobileNo,
                minDeposit: values.minDeposit,
                maxDeposit: values.maxDeposit,
                minWithdrawal: values.minWithdrawal,
                maxWithdrawal: values.maxWithdrawal,
                prefAcc: values.prefAcc,
                weightage: values.weightage,
                startBal: values.startBal,
                balDiff: values.balDiff,
                walletBal: values.walletBal,
                isAgentWallet: values.isAgentWallet,
                agentCd: values.agentCd,
                session: values.session,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response) {
            if (response.data.code == '200') {
              if (walletAssign == true) {
                if (multi.length !== 0) {
                  function extractValues(merchants: {value: string; label: string}[]): string {
                    return merchants.map((merchant) => merchant.value).join('|')
                  }

                  let multiString = extractValues(multi)
                  axios
                    .post(`${SISPAY_API}/wallet/assignment`, {
                      request: {
                        walletCd: values.walletCd,
                        walletAccNo: values.walletAccNo,
                        merchantCd: multiString,
                        status: values.walletAssignmentStatus,
                        session: session,
                      },
                    })
                    .then(function (response) {
                      alert('Wallet Created and Assigned!')
                      setLoading(false)
                      navigate(-1)
                    })
                    .catch(function (error) {
                      alert(error)
                    })
                } else {
                  alert('Merchant field is required.')
                  setLoading(false)
                }
              } else {
                if (navAssign === false) {
                  setLoading(false)
                  navigate(-1)
                  alert('Wallet Created!')
                } else {
                  setLoading(false)
                  alert('Wallet Created!')
                  navigate('/wallet/assign')
                }
              }
            } else {
              alert(response.data.message)
            }
          })
          .catch(function (error) {})
      } catch (error) {}
    },
  })

  //ABSTRACT FUNCTION: Retrieve data from API
  function retrieveData(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let result = response.data.result[`${location}`]
        let merchantCode: object
        let merchantCodes: any = []

        for (let i = 0; i < result.length; i++) {
          merchantCode = {
            value: result[i].merchantCd,
            label: result[i].merchantName + ' - ' + result[i].merchantCd,
          }
          merchantCodes.push(merchantCode)
        }

        merchantCodes = Array.from(new Set(merchantCodes))

        setMerchantCodes(sortByName(merchantCodes, 'label'))
      })
      .catch(function (error) {})
  }

  //ABSTRACT FUNCTION: Retrieve Wallet Account from API
  function retrieveUser(SISPAY_API: string, url: string, location: string) {
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let result = response.data.result[`${location}`]

        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]
          let user: object
          let users: any = []

          for (let i = 0; i < result.length; i++) {
            if (result[i].userType == 'Agent') {
              user = {
                value: result[i].id,
                label: result[i].username,
              }
              users.push(user)
            }
          }

          users = Array.from(new Set(users))
          setAgentCdOptions(sortByName(users, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    retrieveData(SISPAY_API, '/merchant/list', 'merchant')
    retrieveUser(SISPAY_API, '/user/list', 'users')
  }, [])

  const multiOptions = merchantCodes

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate('/wallet/list')}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
        <span className='text-gray-800 fs-1 fw-bolder ms-5 d-flex align-items-center'>
          Add Wallet
        </span>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group Wallet Account Name */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Wallet Account Name
            </label>
            <input
              placeholder='Enter your Wallet Account Name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletAccName')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.walletAccName && formik.errors.walletAccName,
                },
                {
                  'is-valid': formik.touched.walletAccName && !formik.errors.walletAccName,
                }
              )}
            />
            {formik.touched.walletAccName && formik.errors.walletAccName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account Number */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Wallet Account Number
            </label>
            <input
              placeholder='Enter your Wallet Account Number'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletAccNo')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.walletAccNo && formik.errors.walletAccNo,
                },
                {
                  'is-valid': formik.touched.walletAccNo && !formik.errors.walletAccNo,
                }
              )}
            />
            {formik.touched.walletAccNo && formik.errors.walletAccNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Code */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Wallet Code</label>
            <select
              {...formik.getFieldProps('walletCd')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.walletCd && formik.errors.walletCd,
                },
                {
                  'is-valid': formik.touched.walletCd && !formik.errors.walletCd,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='BKASH'>BKASH</option>
              <option value='ROCKET'>ROCKET</option>
              <option value='NAGAD'>NAGAD</option>
            </select>
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group Status */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Status</label>
            <select
              {...formik.getFieldProps('status')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.status && formik.errors.status,
                },
                {
                  'is-valid': formik.touched.status && !formik.errors.status,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='ACTIVE'>ACTIVE</option>
              <option value='INACTIVE'>INACTIVE</option>
              <option value='DE-ACTIVE'>DE-ACTIVE</option>
            </select>
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group Wallet Type */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Wallet Type</label>
            <select
              {...formik.getFieldProps('walletType')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.walletType && formik.errors.walletType,
                },
                {
                  'is-valid': formik.touched.walletType && !formik.errors.walletType,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='DEPOSIT'>Deposit</option>
              <option value='WITHDRAWAL'>Withdrawal</option>
              <option value='DEPOSITWITHDRAWAL'>Deposit & Withdrawal</option>
            </select>
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group Daily Deposit Limit */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Daily Deposit Limit
            </label>
            <span>
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className='svg-icon-muted svg-icon-1hx'
              />
            </span>
            <input
              placeholder='Enter Daily Deposit Limit'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('dailyDepositLmt')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.dailyDepositLmt && formik.errors.dailyDepositLmt,
                },
                {
                  'is-valid': formik.touched.dailyDepositLmt && !formik.errors.dailyDepositLmt,
                }
              )}
            />
            {formik.touched.dailyDepositLmt && formik.errors.dailyDepositLmt && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dailyDepositLmt}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Daily Withdrawal Limit */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Daily Withdrawal Limit
            </label>
            <span>
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className='svg-icon-muted svg-icon-1hx'
              />
            </span>
            <input
              placeholder='Enter Daily Withdrawal Limit'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('dailyWithdrawalLmt')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid':
                    formik.touched.dailyWithdrawalLmt && formik.errors.dailyWithdrawalLmt,
                },
                {
                  'is-valid':
                    formik.touched.dailyWithdrawalLmt && !formik.errors.dailyWithdrawalLmt,
                }
              )}
            />
            {formik.touched.dailyWithdrawalLmt && formik.errors.dailyWithdrawalLmt && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dailyWithdrawalLmt}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Mobile Number */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Mobile Number</label>
            <span>
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className='svg-icon-muted svg-icon-1hx'
              />
            </span>
            <input
              placeholder='Enter Mobile Number'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('mobileNo')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.mobileNo && formik.errors.mobileNo,
                },
                {
                  'is-valid': formik.touched.mobileNo && !formik.errors.mobileNo,
                }
              )}
            />
            {formik.touched.mobileNo && formik.errors.mobileNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.mobileNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Minimum Deposit */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Minimum Deposit</label>
            <span>
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className='svg-icon-muted svg-icon-1hx'
              />
            </span>
            <input
              placeholder='Enter Minimum Deposit'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('minDeposit')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.minDeposit && formik.errors.minDeposit,
                },
                {
                  'is-valid': formik.touched.minDeposit && !formik.errors.minDeposit,
                }
              )}
            />
            {formik.touched.minDeposit && formik.errors.minDeposit && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.minDeposit}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Maximum Deposit */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Maximum Deposit</label>
            <span>
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className='svg-icon-muted svg-icon-1hx'
              />
            </span>
            <input
              placeholder='Enter Maximum Deposit'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('maxDeposit')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.maxDeposit && formik.errors.maxDeposit,
                },
                {
                  'is-valid': formik.touched.maxDeposit && !formik.errors.maxDeposit,
                }
              )}
            />
            {formik.touched.maxDeposit && formik.errors.maxDeposit && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.maxDeposit}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Minimum Withdrawal */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Minimum Withdrawal
            </label>
            <span>
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className='svg-icon-muted svg-icon-1hx'
              />
            </span>
            <input
              placeholder='Enter Minimum Withdrawal'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('minWithdrawal')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.minWithdrawal && formik.errors.minWithdrawal,
                },
                {
                  'is-valid': formik.touched.minWithdrawal && !formik.errors.minWithdrawal,
                }
              )}
            />
            {formik.touched.minWithdrawal && formik.errors.minWithdrawal && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.minWithdrawal}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Maximum Withdrawal */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Maximum Withdrawal
            </label>
            <span>
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className='svg-icon-muted svg-icon-1hx'
              />
            </span>
            <input
              placeholder='Enter Minimum Withdrawal'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('maxWithdrawal')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.maxWithdrawal && formik.errors.maxWithdrawal,
                },
                {
                  'is-valid': formik.touched.maxWithdrawal && !formik.errors.maxWithdrawal,
                }
              )}
            />
            {formik.touched.maxWithdrawal && formik.errors.maxWithdrawal && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.maxWithdrawal}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Preferred Account */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Preferred Account
            </label>
            <select
              {...formik.getFieldProps('prefAcc')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.prefAcc && formik.errors.prefAcc,
                },
                {
                  'is-valid': formik.touched.prefAcc && !formik.errors.prefAcc,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='false'>NO</option>
              <option value='true'>YES</option>
            </select>
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group Weightage */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Weightage</label>
            <span>
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className='svg-icon-muted svg-icon-1hx'
              />
            </span>
            <input
              placeholder='Enter Weightage'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('weightage')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.weightage && formik.errors.weightage,
                },
                {
                  'is-valid': formik.touched.weightage && !formik.errors.weightage,
                }
              )}
            />
            {formik.touched.weightage && formik.errors.weightage && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.weightage}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Starting Balance */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Starting Balance</label>
            <span>
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className='svg-icon-muted svg-icon-1hx'
              />
            </span>
            <input
              placeholder='Enter Starting Balance'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('startBal')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.startBal && formik.errors.startBal,
                },
                {
                  'is-valid': formik.touched.startBal && !formik.errors.startBal,
                }
              )}
            />
            {formik.touched.startBal && formik.errors.startBal && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.startBal}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Balance Different */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Balance Different
            </label>
            <span>
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className='svg-icon-muted svg-icon-1hx'
              />
            </span>
            <input
              placeholder='Enter Balance Different'
              type='number'
              onWheel={(event) => event.currentTarget.blur()}
              autoComplete='off'
              {...formik.getFieldProps('balDiff')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.balDiff && formik.errors.balDiff,
                },
                {
                  'is-valid': formik.touched.balDiff && !formik.errors.balDiff,
                }
              )}
            />
            {formik.touched.balDiff && formik.errors.balDiff && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.balDiff}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Is Agent Wallet */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Is Agent Wallet?</label>
            <select
              {...formik.getFieldProps('isAgentWallet')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.isAgentWallet && formik.errors.isAgentWallet,
                },
                {
                  'is-valid': formik.touched.isAgentWallet && !formik.errors.isAgentWallet,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='false'>NO</option>
              <option value='true'>YES</option>
            </select>
            {formik.touched.isAgentWallet && formik.errors.isAgentWallet && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.isAgentWallet}</span>
                </div>
              </div>
            )}
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group Agent Code */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Agent Code</label>
            <Select
              //ref={agentCdSelectBtn}
              isDisabled={formik.getFieldProps('isAgentWallet').value == 'false' ? true : false}
              className='basic-single'
              classNamePrefix='select'
              options={agentCdOptions}
              styles={customStyles}
              isClearable={true}
              name='agentCd'
              onChange={(e: any) =>
                e !== null
                  ? formik.setFieldValue('agentCd', e.value)
                  : formik.setFieldValue('agentCd', '')
              }
            />
            {formik.touched.agentCd && formik.errors.agentCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.agentCd}</span>
                </div>
              </div>
            )}
          </div>{' '}
          {/* end::Form group */}
          <div className='mb-10'>
            <div className='form-check form-switch form-check-custom form-check-solid aligns-item-center'>
              <label
                className='form-label fw-bolder text-dark fs-6 m-0 p-0'
                htmlFor='flexSwitchDefault'
              >
                Do you wish to assign wallet?
              </label>
              <input
                className='form-check-input ms-5'
                type='checkbox'
                id='flexSwitchDefault'
                onChange={() => {
                  setWalletAssign(!walletAssign)
                  formik.setFieldValue('assigningWallet', !walletAssign)
                }}
              />
            </div>
          </div>
          {/* begin::Form group Merchant Code */}
          <div
            className='fv-row mb-8'
            style={{
              color: 'black',
              display: walletAssign ? 'block' : 'none',
            }}
          >
            <label className='form-label required fw-bolder text-dark fs-6'>Merchant</label>
            <Select
              isMulti
              name='merchantCd'
              options={multiOptions}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(choice: any) => setMulti(choice)}
              styles={customStyles}
            />
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group Wallet Assignment Status */}
          <div
            className='fv-row mb-8'
            style={{
              display: walletAssign ? 'block' : 'none',
            }}
          >
            <label className='form-label required fw-bolder text-dark fs-6'>Status</label>
            <select
              {...formik.getFieldProps('walletAssignmentStatus')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid':
                    formik.touched.walletAssignmentStatus && formik.errors.walletAssignmentStatus,
                },
                {
                  'is-valid':
                    formik.touched.walletAssignmentStatus && !formik.errors.walletAssignmentStatus,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
            {formik.touched.walletAssignmentStatus && formik.errors.walletAssignmentStatus && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAssignmentStatus}</span>
                </div>
              </div>
            )}
          </div>{' '}
          {/* end::Form group */}
          <div className='row'>
            <div className='col'>
              {/* begin::Form group */}
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                  onClick={() => {
                    setNavAssign(false)
                  }}
                >
                  {!loading && <span className='indicator-label'>CREATE</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Form group */}
            </div>
            {/* <div className='col'>
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                  onClick={() => {
                    setNavAssign(true)
                  }}
                >
                  {!loading && <span className='indicator-label'>CREATE & ASSIGN</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  )
}

export default WalletListPage_Add
