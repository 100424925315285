import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

export function CommissionWithdrawal_ExportPDF(formData: any, startDate: string, endDate: string) {
  // Register the fonts with pdfMake
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  const documentDefinition = {
    pageOrientation: 'landscape' as 'landscape', // Cast the pageOrientation value explicitly
    pageSize: 'A2' as 'A2',
    content: [
      {text: `Exported Data ${startDate} - ${endDate}`, style: 'header'},
      '\n', // Add a line break
      {
        table: {
          //widths: ['*', '*', '*'], // Adjust column widths as needed
          body: [
            [
              'CW Request ID',
              'CW Request Submission DateTime',
              'CW Type',
              'CW Status',
              'Agent',
              'Amount',
              'Remark',
              'Success/Reject By',
            ], // Add headers as a separate row
            ...formData.map((item: any) => [
              item.id,
              item.requestDT,
              item.transType,
              item.status,
              item.username,
              item.amount,
              item.statUpdateRemark,
              item.successRejectBy,
            ]),
          ],
        },
        layout: 'lightHorizontalLines', // Add a layout to handle long tables
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
    },
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
  pdfDocGenerator.download(`Exported Data ${startDate} - ${endDate}.pdf`)
}
