import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useAuth} from '../../../auth'

const PreviousDayAgentSummary_Details: React.FC = () => {
  const navigate = useNavigate()
  const {logout} = useAuth()
  const location: any = useLocation()

  let initialValues = {
    agentNickname: location.state.agentNickname || 'N/A',
    agentUsername: location.state.agentUsername || 'N/A',
    depositAmount: location.state.depositAmount.toLocaleString(),
    depositCount: location.state.depositCount.toLocaleString(),
    depositComm: location.state.depositComm.toLocaleString(),
    withdrawalAmount: location.state.withdrawalAmount.toLocaleString(),
    withdrawalCount: location.state.withdrawalCount.toLocaleString(),
    withdrawalComm: location.state.withdrawalComm.toLocaleString(),
    nagadDepositAmount: location.state.nagadDepositAmount.toLocaleString(),
    bkashDepositAmount: location.state.bkashDepositAmount.toLocaleString(),
    rocketDepositAmount: location.state.rocketDepositAmount.toLocaleString(),
    nagadWithdrawalAmount: location.state.nagadWithdrawalAmount.toLocaleString(),
    bkashWithdrawalAmount: location.state.bkashWithdrawalAmount.toLocaleString(),
    rocketWithdrawalAmount: location.state.rocketWithdrawalAmount.toLocaleString(),
    agentCredit: location.state.agentCredit.toLocaleString(),
    agentAvailableCredit: location.state.agentAvailableCredit.toLocaleString(),
    agentAvailableComm: location.state.agentAvailableComm.toLocaleString(),
  }

  const addUserSchema = Yup.object().shape({})

  const formik = useFormik({
    initialValues,
    validationSchema: addUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {},
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
        <span className='text-gray-800 fs-1 fw-bolder ms-5 d-flex align-items-center'>
          Agent Details
        </span>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Agent Nickname</label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('agentNickname')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.agentNickname && formik.errors.agentNickname,
                },
                {
                  'is-valid': formik.touched.agentNickname && !formik.errors.agentNickname,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Agent Username</label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('agentUsername')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.agentUsername && formik.errors.agentUsername,
                },
                {
                  'is-valid': formik.touched.agentUsername && !formik.errors.agentUsername,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Total Cash-In (Withdrawal) Count
            </label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('withdrawalCount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.withdrawalCount && formik.errors.withdrawalCount,
                },
                {
                  'is-valid': formik.touched.withdrawalCount && !formik.errors.withdrawalCount,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Total Cash-In (Withdrawal) Amount
            </label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('withdrawalAmount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.withdrawalAmount && formik.errors.withdrawalAmount,
                },
                {
                  'is-valid': formik.touched.withdrawalAmount && !formik.errors.withdrawalAmount,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Total Cash-In (Deposit) Count
            </label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('depositCount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.depositCount && formik.errors.depositCount,
                },
                {
                  'is-valid': formik.touched.depositCount && !formik.errors.depositCount,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Total Cash-In (Deposit) Amount
            </label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('depositAmount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.depositAmount && formik.errors.depositAmount,
                },
                {
                  'is-valid': formik.touched.depositAmount && !formik.errors.depositAmount,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Total Credit</label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('agentCredit')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.agentCredit && formik.errors.agentCredit,
                },
                {
                  'is-valid': formik.touched.agentCredit && !formik.errors.agentCredit,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Available Credit</label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('agentAvailableCredit')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid':
                    formik.touched.agentAvailableCredit && formik.errors.agentAvailableCredit,
                },
                {
                  'is-valid':
                    formik.touched.agentAvailableCredit && !formik.errors.agentAvailableCredit,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Available Commission
            </label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('agentAvailableComm')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid':
                    formik.touched.agentAvailableComm && formik.errors.agentAvailableComm,
                },
                {
                  'is-valid':
                    formik.touched.agentAvailableComm && !formik.errors.agentAvailableComm,
                }
              )}
            />
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              BKASH Cash-Out (Deposit) Amount
            </label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('bkashDepositAmount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid':
                    formik.touched.bkashDepositAmount && formik.errors.bkashDepositAmount,
                },
                {
                  'is-valid':
                    formik.touched.bkashDepositAmount && !formik.errors.bkashDepositAmount,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              NAGAD Cash-Out (Deposit) Amount
            </label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('nagadDepositAmount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid':
                    formik.touched.nagadDepositAmount && formik.errors.nagadDepositAmount,
                },
                {
                  'is-valid':
                    formik.touched.nagadDepositAmount && !formik.errors.nagadDepositAmount,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Rocket Cash-Out (Deposit) Amount
            </label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('rocketDepositAmount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid':
                    formik.touched.rocketDepositAmount && formik.errors.rocketDepositAmount,
                },
                {
                  'is-valid':
                    formik.touched.rocketDepositAmount && !formik.errors.rocketDepositAmount,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              BKASH Cash-In (Withdrawal) Amount
            </label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('bkashWithdrawalAmount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid':
                    formik.touched.bkashWithdrawalAmount && formik.errors.bkashWithdrawalAmount,
                },
                {
                  'is-valid':
                    formik.touched.bkashWithdrawalAmount && !formik.errors.bkashWithdrawalAmount,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              NAGAD Cash-In (Withdrawal) Amount
            </label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('nagadWithdrawalAmount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid':
                    formik.touched.nagadWithdrawalAmount && formik.errors.nagadWithdrawalAmount,
                },
                {
                  'is-valid':
                    formik.touched.nagadWithdrawalAmount && !formik.errors.nagadWithdrawalAmount,
                }
              )}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Rocket Cash-In (Withdrawal) Amount
            </label>
            <input
              placeholder='N/A'
              type='text'
              autoComplete='off'
              disabled
              {...formik.getFieldProps('rocketWithdrawalAmount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid':
                    formik.touched.rocketWithdrawalAmount && formik.errors.rocketWithdrawalAmount,
                },
                {
                  'is-valid':
                    formik.touched.rocketWithdrawalAmount && !formik.errors.rocketWithdrawalAmount,
                }
              )}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default PreviousDayAgentSummary_Details
