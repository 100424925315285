import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import {customStyles} from '../../../components/CustomStyles'
import {filterArrayByMultipleKeys} from '../../../components/Filter'
import {getCurrentDateInput} from '../../../components/GetCurrentDateInput'
import {searching} from '../../../components/Searching'
import {sortByDate} from '../../../components/SortByDate'
import {Overlay} from '../../../components/Overlay'
import {request} from 'http'
import React from 'react'
import {ErrorHandling} from '../../../components/ErrorHandling'
import {useAuth} from '../../../auth'

export function Filter(props: any) {
  const {logout} = useAuth()
  let setFormData = props.setFormData
  let setSelectedArray = props.setSelectedArray
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const [loading, setLoading] = useState(false)
  const [merchantCdList, setmerchantCdList] = useState(Object)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const merchantCdStored = localStorage.getItem('merchantCd')
  const selectedWalletCd = useRef<any>(null)

  const initialValues = {
    searchBankDetails: '',
    requestType: '',
    requestStatus: '',
    dateTimeFrom: getCurrentDateInput(),
    dateTimeTo: getCurrentDateInput(),
  }

  const filterSchema = Yup.object().shape({
    searchBankDetails: Yup.string(),
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const keysSearchsearchCustomer = ['customerCd', 'destinationWalletAcc', 'destinationWalletName']
  //const keyssearchBankDetails = ['merchantTxnId']

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      let searchBankDetails = values.searchBankDetails
      let requestType = values.requestType
      let requestStatus = values.requestStatus
      let dateTimeFrom = values.dateTimeFrom
      let dateTimeTo = values.dateTimeTo

      axios
        .post(
          `${SISPAY_API}/merchant/settlementtopup/list`,
          {
            request: {
              session: session,
              merchantCd: merchantCdStored,
              toDate: dateTimeTo,
              fromDate: dateTimeFrom,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 200) {
            let filteredFormData = filterArrayByMultipleKeys(
              response.data.result.merchantSettTopup,
              ['requestType', 'settTopupStatus'],
              [requestType, requestStatus]
            )

            if (searchBankDetails !== '') {
              filteredFormData = searching(
                filteredFormData,
                searchBankDetails.toLowerCase(),
                keysSearchsearchCustomer
              )
            }

            setFormData(sortByDate(filteredFormData, 'settTopupSubmissionDT'))
            setLoading(false)
          } else {
            ErrorHandling(response, logout)
          }
        })
        .catch(function (error) {})
    },
  })

  //GET USER LIST
  useEffect(() => {}, [])

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-2 py-1'>
          <div className='mb-10'>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='fv-row mb-5'>
                {' '}
                <div className='row'>
                  <div className='col'>
                    {/* begin::Form group*/}
                    <div className='fv-row mb-5'>
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Search Bank Details
                        </label>
                        {Overlay('SEARCH Bank Name, Account Holder Name, Account Number')}
                      </div>
                      <input
                        placeholder='SEARCH Bank Name, Account Holder Name, Account Number'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('searchBankDetails')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid':
                              formik.touched.searchBankDetails && formik.errors.searchBankDetails,
                          },
                          {
                            'is-valid':
                              formik.touched.searchBankDetails && !formik.errors.searchBankDetails,
                          }
                        )}
                      />
                      {formik.touched.searchBankDetails && formik.errors.searchBankDetails && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.searchBankDetails}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Request Type</label>
                      <select
                        {...formik.getFieldProps('requestType')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        <option value=''>ALL</option>
                        <option value='SETTLEMENT'>SETTLEMENT</option>
                        <option value='TOPUP'>TOP UP</option>
                      </select>{' '}
                    </div>{' '}
                  </div>
                  <div className='col'>
                    {' '}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Request Status</label>
                      <select
                        {...formik.getFieldProps('requestStatus')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        <option value=''>ALL</option>
                        <option value='PENDING'>PENDING</option>
                        <option value='APPROVED'>APPROVED</option>
                        <option value='REJECTED'>REJECTED</option>
                        <option value='CANCELLED'>CANCELLED</option>
                      </select>{' '}
                    </div>{' '}
                  </div>

                  <div className='row'>
                    <div className='col'></div>
                    <div className='col'></div>
                    <div className='col'></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    {/* begin::Form group DateTime From */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>From</label>
                      <input
                        placeholder='Enter your start time'
                        type='date'
                        autoComplete='off'
                        {...formik.getFieldProps('dateTimeFrom')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                          },
                          {
                            'is-valid': formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                          }
                        )}
                      />
                      {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.dateTimeFrom}</span>
                          </div>
                        </div>
                      )}
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group DateTime To */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>To</label>
                      <input
                        placeholder='Enter your end time'
                        type='date'
                        autoComplete='off'
                        {...formik.getFieldProps('dateTimeTo')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                          },
                          {
                            'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                          }
                        )}
                      />
                      {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.dateTimeTo}</span>
                          </div>
                        </div>
                      )}
                    </div>{' '}
                    {/* end::Form group */}
                    <div className='col'></div>
                    <div className='col'></div>
                    <div className='col'></div>
                  </div>
                  <div className='row'>
                    <div className='col'></div>
                    <div className='col'></div>
                    <div className='col'></div> <div className='col'></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'></div>
                  <div className='col'></div>
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='text-center'>
                      <button
                        className='btn btn-lg btn-light w-100 mb-5'
                        //disabled={formik.isSubmitting}
                        onClick={() => {
                          setSelectedArray([])
                          selectedWalletCd.current.setValue('')
                          formik.resetForm()
                        }}
                      >
                        {<span className='indicator-label'>Reset</span>}
                      </button>
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='text-center'>
                      <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {/* end::Form group */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
