export function getCurrentDateTime() {
  const currentDate = new Date()

  // Get individual date components
  const year = currentDate.getFullYear().toString()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')
  const hours = currentDate.getHours().toString().padStart(2, '0')
  const minutes = currentDate.getMinutes().toString().padStart(2, '0')

  // Concatenate the components to form the desired format
  const formattedDateTime = `${year}${month}${day}_${hours}${minutes}`
  return formattedDateTime
}
