import axios from 'axios'
import {useEffect, useState} from 'react'
import Select from 'react-select'
import {customStyles} from '../../components/CustomStyles'
import {useQuery} from 'react-query'
import {usePendingWithdrawalStore} from './PendingWithdrawal_Store'
import {useWithdrawalStore} from './Withdrawal_Store'
import {useBulkWithdrawalStore} from './BulkWithdrawal_Store'

export function ApprovalModal(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const API_URL = String(process.env.REACT_APP_SISPAY_API)

  //Props
  let currentRejectedId = props.currentRejectedId
  let approvalRemark = props.approvalRemark
  let withdrawalFilterSubmit = props.withdrawalFilterSubmit
  let currentWalletCd = props.currentWalletCd

  //States
  const [walletAccNo, setWalletAccNo] = useState('')
  const [walletAccNoList, setWalletAccNoList] = useState(Object)

  //Zustand Store
  const setPendingGetData = usePendingWithdrawalStore((state: any) => state.setGetData)
  const setGetData = useWithdrawalStore((state: any) => state.setGetData)
  const setBulkGetData = useBulkWithdrawalStore((state: any) => state.setGetData)

  // Get the current URL
  var currentUrl = window.location.href

  function verifyCurrentUrl(currentUrl: string) {
    if (currentUrl.includes('withdrawal/list')) {
      setGetData(true)
    } else if (currentUrl.includes('/withdrawal/pending')) {
      setPendingGetData(true)
    } else if (currentUrl.includes('/withdrawal/bulk-approval')) {
      setBulkGetData(true)
    } else {
      console.log('Current URL does not match the specified patterns')
    }
  }

  function approveWithdrawal() {
    axios
      .post(API_URL + '/cash/withdrawalrequest/approve', {
        request: {
          id: currentRejectedId,
          session: session,
          walletAccNo: walletAccNo,
          approvalRejectionRemark: approvalRemark.current?.value,
        },
      })
      .then((response) => {
        withdrawalFilterSubmit.current.click()
        verifyCurrentUrl(currentUrl)
        alert(response.data.message)
      })
      .catch((error) => {
        verifyCurrentUrl(currentUrl)
        alert(error)
      })
  }

  function retrieveAgent() {
    axios
      .post(API_URL + '/wallet/list', {
        request: {
          session: session,
        },
      })
      .then((response) => {
        let result = response.data.result['wallet']
        const walletList = Array.from(
          new Set(
            result
              .filter(
                (wallet: any) => wallet.status == 'ACTIVE' && wallet.walletCd == currentWalletCd
              )
              .map((wallet: any) => wallet.walletAccNo)
          )
        ).map((walletAccNo) => ({value: walletAccNo, label: walletAccNo}))

        setWalletAccNoList(walletList)
      })
      .catch((error) => {
        alert(error)
      })
  }

  useEffect(() => {
    retrieveAgent()
  }, [currentWalletCd])

  return (
    <div
      className='modal fade'
      id='staticBackdrop_approve'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              Do you want to approve withdrawal ID: {currentRejectedId}
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <div className='d-flex flex-column'>
              <label className='form-label fw-bolder text-dark fs-6'>
                Select Wallet : {currentWalletCd}
              </label>
              <div>
                <Select
                  className='basic-single mb-3'
                  classNamePrefix='select'
                  options={walletAccNoList}
                  styles={customStyles}
                  name='merchantCd'
                  onChange={(e: any) => setWalletAccNo(e.value)}
                />
              </div>
              <label className='form-label fw-bolder text-dark fs-6'>Remark</label>
              <textarea
                name='approvalRemark'
                ref={approvalRemark}
                id='approvalRemark'
                className='w-100'
                rows={5}
              ></textarea>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
              Close
            </button>
            <button
              type='button'
              className='btn btn-success'
              data-bs-dismiss='modal'
              onClick={approveWithdrawal}
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
