export function modifyKeys(formData: any, keyMap: any) {
  const modifiedArr = formData.map((obj: any) => {
    const modifiedObj: any = {}
    Object.entries(obj).forEach(([key, value]) => {
      const modifiedKey = keyMap[key] || key
      modifiedObj[modifiedKey] = value
    })
    return modifiedObj
  })

  return modifiedArr
}
