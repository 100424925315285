import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import {customStyles} from '../../../../components/CustomStyles'
import {getCurrentDateInput} from '../../../../components/GetCurrentDateInput'
import {sortByDate} from '../../../../components/SortByDate'
import {getCurrentYearMonth} from '../../../../components/GetCurrentMonth'

export function Merchant_Monthly_Filter(props: any) {
  let setFormData = props.setFormData
  let setSelectedArray = props.setSelectedArray
  let setStartDate = props.setStartDate
  let setEndDate = props.setEndDate
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const [loading, setLoading] = useState(false)
  const [merchantCdList, setMerchantCdList] = useState(Object)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const merchantCd: any = localStorage.getItem('merchantCd')
  const selectedMerchantCd = useRef<any>(null)

  const initialValues = {
    merchantCd: merchantCd,
    timeZone: 'GMT+6',
    basedOn: 'transCompleted',
    dateTimeFrom: getCurrentYearMonth(),
    dateTimeTo: getCurrentYearMonth(),
  }

  const filterSchema = Yup.object().shape({
    dateTimeFrom: Yup.date().test(
      'dateRange',
      'The gap between dates cannot exceed 60 days',
      function (value: any) {
        const {dateTimeTo} = this.parent
        if (value && dateTimeTo) {
          const diffInDays = Math.ceil((dateTimeTo - value) / (1000 * 60 * 60 * 24))
          return diffInDays <= 61
        }
        return true
      }
    ),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const keysSearchsearchCustomer = ['bankName', 'AccHolderName', 'AccNo']
  //const keysSearchTransaction = ['merchantTxnId']

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      let merchantCd = values.merchantCd
      let timeZone = values.timeZone
      let basedOn = values.basedOn
      let dateTimeFrom = values.dateTimeFrom
      let dateTimeTo = values.dateTimeTo

      setStartDate(dateTimeFrom)
      setEndDate(dateTimeTo)

      axios
        .post(
          `${SISPAY_API}/merchant/portal/monthlyreport`,
          {
            request: {
              session: session,
              merchantCd: merchantCd,
              timezone: timeZone,
              basedOn: basedOn,
              fromDate: dateTimeFrom,
              toDate: dateTimeTo,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          setFormData(sortByDate(response.data.result.merchantMonthlyReport, 'date'))
          setLoading(false)
        })
        .catch(function (error) {})
    },
  })

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-2 py-1'>
          <div className='mb-10'>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='fv-row mb-5'>
                {' '}
                <div className='row'>
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Timezone</label>
                      <select
                        {...formik.getFieldProps('timeZone')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        <option value='GMT+6'>GMT+6</option>
                        <option value='GMT+7'>GMT+7</option>
                        <option value='GMT+8'>GMT+8</option>
                      </select>{' '}
                    </div>{' '}
                  </div>
                  {/* end::Form group */}
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Based On</label>
                      <select
                        {...formik.getFieldProps('basedOn')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        {/* <option value='transCreated'>Transaction Created</option> */}
                        <option value='transCompleted'>Transaction Completed</option>
                      </select>{' '}
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group DateTime From */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>From</label>
                      <input
                        placeholder='Enter your start time'
                        type='month'
                        autoComplete='off'
                        {...formik.getFieldProps('dateTimeFrom')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                          },
                          {
                            'is-valid': formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                          }
                        )}
                      />
                      {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.dateTimeFrom}</span>
                          </div>
                        </div>
                      )}
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group DateTime To */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>To</label>
                      <input
                        placeholder='Enter your end time'
                        type='month'
                        autoComplete='off'
                        {...formik.getFieldProps('dateTimeTo')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                          },
                          {
                            'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                          }
                        )}
                      />
                      {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.dateTimeTo}</span>
                          </div>
                        </div>
                      )}
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      //disabled={formik.isSubmitting}
                      onClick={() => {
                        formik.resetForm()
                      }}
                    >
                      {<span className='indicator-label'>Reset</span>}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
