import React, {Ref, useState} from 'react'
import {Column, useTable, useResizeColumns, useFlexLayout} from 'react-table'
import {Filter} from './components/Filter'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {Styles} from '../../components/Table_Style'
import {useLayout} from '../../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import {Cancel_Settlement} from './components/Cancel_Settlement'

const Settlement_Pending_List: React.FC = () => {
  const {config} = useLayout()
  const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const approvalRejectRemark = React.useRef<HTMLTextAreaElement>(null)
  //Select Metrics
  const API_URL = String(process.env.REACT_APP_SISPAY_API)
  const [formData, setFormData] = useState([])
  const [selectedArray, setSelectedArray] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [currentSettlement, setCurrentSettlement] = useState('')
  const [isAction, setIsAction] = useState(false)
  const [checkedAll, setCheckedAll] = useState(false)
  const [test, setTest] = useState(Boolean)
  const [remarks, setRemarks] = useState('')

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  let items = formData

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = Math.min(firstIndex + itemsPerPage, items.length)
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : []

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(e.target.value)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset the current page to 1
  }
  //Pagincation

  //Table::START
  interface Data {
    id: string
    settTopupSubmissionDT: string
    requestType: string
    settTopupStatus: string
    merchantName: string
    merchantCd: string
    bankName: string
    AccHolderName: string
    AccNo: string
    amount: string
    requestDate: string
    remarks: string
  }

  const [checked, setChecked] = useState(false)
  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: 'Request ID',
        accessor: 'id',
      },
      {
        Header: 'Request Submission DateTime',
        accessor: 'settTopupSubmissionDT',
      },
      {
        Header: 'Request Type',
        accessor: 'requestType',
      },
      {
        Header: 'ST Status',
        accessor: 'settTopupStatus',
      },
      {
        Header: 'Merchant',
        accessor: (row) => {
          return row.merchantName + ' - ' + row.merchantCd
        },
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
      },
      {
        Header: 'Account Holder Name',
        accessor: 'AccHolderName',
      },
      {
        Header: 'Account Holder No',
        accessor: 'AccNo',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Request Date',
        accessor: 'requestDate',
      },
      {
        Header: 'Remarks',
        accessor: 'remarks',
      },
      {
        Header: 'Action',
        accessor: (row: any) => {
          return (
            <div className='d-flex'>
              <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_cancel_settlement'
                onClick={() => setCurrentSettlement(row.id)}
              >
                {' '}
                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='svg-icon-3' />
              </button>
              {/* <button
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_reject_settlement'
                onClick={() => setCurrentSettlement(row.id)}
              >
                {' '}
                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='svg-icon-3' />
              </button> */}
            </div>
          )
        },
        width: 150,
      },
    ],
    [test, selectedArray]
  )

  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter(
                    (column) =>
                      column.Header !== ' ' &&
                      column.Header !== 'Action' &&
                      column.id !== 'selection'
                  )
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex justify-content-center align-items-center m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.Header)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0 d-flex'>
          <Link
            to='/settlement/create'
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-primary ms-3 me-5'
          >
            Create Settlement
          </Link>{' '}
          {/* <div
            className='position-relative me-5'
            style={{
              width: '150px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-warning w-100'
              onClick={() => {
                setIsAction(!isAction)
              }}
            >
              Action
            </div>{' '}
            {isAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_bulk_active'
                  disabled={selectedArray.length > 0 ? false : true}
                  onClick={() => {
                    setIsAction(!isAction)
                  }}
                >
                  BULK ACTIVE
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_bulk_deactive'
                  disabled={selectedArray.length > 0 ? false : true}
                  onClick={() => {
                    setIsAction(!isAction)
                  }}
                >
                  BULK DEACTIVE
                </button>{' '}
              </div>
            )}
          </div> */}
          {/* <button
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-primary me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_export'
            // style={{
            //   color: '#50cd89',
            // }}
            onClick={() => {
              //setIsAction(!isAction)
            }}
          >
            Export Withdrawal
          </button>{' '} */}
          {/* <div
            className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-primary me-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_deactive_all'
            // style={{
            //   color: '#f1416c',
            // }}
            onClick={() => {
              //setIsAction(!isAction)
            }}
          >
            DEACTIVATE ALL
          </div>{' '} */}
        </div>

        <div className='m-0 d-flex '>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Filter setFormData={setFormData} setSelectedArray={setSelectedArray} />
      <Styles>
        <Table columns={columns} data={currentItems == undefined ? [] : currentItems} />
      </Styles>
      <Cancel_Settlement
        remarks={remarks}
        setRemarks={setRemarks}
        currentSettlement={currentSettlement}
        setCurrentSettlement={setCurrentSettlement}
        setFormData={setFormData}
      />
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {formData.length}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={previousPage}
        >
          Previous
        </button>
        {currentPage} of {lastPage}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage === lastPage}
          onClick={nextPage}
        >
          Next
        </button>
        <select
          onChange={(e) => changePage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: lastPage}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          <option value={5}>5 per page</option>
          <option value={10}>10 per page</option>
          <option value={20}>20 per page</option>
          <option value={50}>50 per page</option>
        </select>
      </div>
      {/* Pagination END */}
      {/* Modal */}
    </div>
  )
}

export default Settlement_Pending_List
