export function filterPartialMatch(array: any[], keys: string[] = [], values: any[] = []) {
  if (keys.length === 0 || keys.every((key) => key === '')) {
    return array
  }
  return array.filter((item) => {
    let match = true
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      const filterValue = String(values[i]).toLowerCase()
      const itemValue = String(item[key]).toLowerCase()

      if (filterValue !== '' && itemValue.indexOf(filterValue) === -1) {
        match = false
        break
      }
    }
    return match
  })
}
