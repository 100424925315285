import {KTSVG} from '../../../_metronic/helpers'

export default function sortFormDataIcon(sortHeader: any, sortColumn: any) {
  if (sortHeader.sortColumn === '' || sortHeader.sortColumn !== sortColumn) {
    return <KTSVG path='/media/icons/duotune/arrows/arr032.svg' className='svg-icon-1' />
  }

  if (sortHeader.sortColumn === sortColumn && sortHeader.sortSequence === 'asc')
    return (
      <KTSVG
        path='/media/icons/duotune/arrows/arr045.svg'
        className='svg-icon-1 svg-icon-success'
      />
    )
  else {
    return (
      <KTSVG path='/media/icons/duotune/arrows/arr044.svg' className='svg-icon-1 svg-icon-danger' />
    )
  }
}
