import {create} from 'zustand'

export const useSettlementStore = create((set) => ({
  //Data
  getData: false,
  filterOptions: {
    searchBankDetails: '',
    merchantCd: '',
    requestType: '',
    settTopupStatus: '',
    dateTimeFrom: '',
    dateTimeTo: '',
  },

  //Functions
  setGetData: (value: boolean) => set({getData: value}),
  setFilterOptions: (value: any) => set({filterOptions: value}),
}))
