export function metricsArray(metrics: string, variable: string) {
  let arr: any = localStorage.getItem(metrics)

  if (arr == null) {
    arr = []
  } else {
    arr = JSON.parse(arr)
  }

  const index = arr.indexOf(variable)

  if (index === -1) {
    // Variable not found in the array, so add it.
    arr.push(variable)
  } else {
    // Variable found in the array, so remove it.
    arr.splice(index, 1)
  }

  localStorage.setItem(metrics, JSON.stringify(arr))
}
