export function UniqueWalletAccNo(result: any) {
  // Extract walletAccNo and form a new array of JSON objects
  const uniqueSet = new Set()

  const walletAccNoList = result.reduce((result: any, item: any) => {
    if (!uniqueSet.has(item.walletAccNo)) {
      uniqueSet.add(item.walletAccNo)
      result.push({
        value: item.walletAccNo,
        label: `${item.walletAccName} - ${item.walletAccNo}`,
      })
    }
    return result
  }, [])

  return walletAccNoList
}
