import {useRef, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import axios from 'axios'
import {useCreditReloadStore} from './CreditReload_Store'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'

export default function CreditReload_ApproveModal(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //States
  const [loading, setLoading] = useState(false)

  //Props
  let currentCreditReload = props.currentCreditReload
  let setCurrentCreditReload = props.setCurrentCreditReload

  //Zustand Store
  const setGetData = useCreditReloadStore((state: any) => state.setGetData)

  let initialValues = {
    remark: '',
  }

  const validationSchema = Yup.object().shape({
    remark: Yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      axios
        .post(`${SISPAY_API}/agent/creditreload/approve`, {
          request: {
            id: currentCreditReload.id,
            statUpdateRemark: values.remark,
            session: session,
          },
        })
        .then((res) => {
          setLoading(false)
          formik.resetForm()
          setGetData(true)
          alert(res.data.message)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} className=''>
      <div className='modal fade' tabIndex={-1} id='creditReloadApprove'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>Approve Credit Reload</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => {
                  setCurrentCreditReload({
                    id: '',
                    requestDT: '',
                    status: '',
                    username: '',
                  })
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row mb-5'>
                <div className='col fw-bold'>CR Request Submission DateTime </div>
                <div className='col'>
                  {currentCreditReload.requestDT.replace('T', ' ').replace(/\.\d+/, '')}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col fw-bold'>CR Status</div>
                <div className='col'>{currentCreditReload.status}</div>
              </div>
              <div className='row mb-5'>
                <div className='col fw-bold'>Agent</div>
                <div className='col'>{currentCreditReload.username}</div>
              </div>
              <div className='row mb-5'>
                <div className='col fw-bold'>Request Type</div>
                <div className='col'>{currentCreditReload.reqType}</div>
              </div>
              <div className='row mb-5'>
                <div className='col fw-bold'>Amount</div>
                <div className='col'>
                  {parseFloat(currentCreditReload.amount)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col fw-bold'>Create By</div>
                <div className='col'>{currentCreditReload.createBy}</div>
              </div>
              <div className='row mb-5'>
                <div className='col fw-bold'>Remark</div>
                <div className='col'>
                  <textarea
                    placeholder='Enter remark'
                    cols={30}
                    rows={3}
                    autoComplete='off'
                    {...formik.getFieldProps('remark')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.remark && formik.errors.remark,
                      },
                      {
                        'is-valid': formik.touched.remark && !formik.errors.remark,
                      }
                    )}
                  />
                  {formik.touched.remark && formik.errors.remark && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.remark}</span>
                      </div>
                    </div>
                  )}{' '}
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              {/* begin::Form group */}
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-success'
                  data-bs-dismiss='modal'
                  disabled={formik.isSubmitting || !formik.isValid || loading}
                >
                  {!loading && <span className='indicator-label'>Approve Credit Reload</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>{' '}
              {/* end::Form group */}
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
