import axios from 'axios'
import {getCurrentDateTime} from '../../components/GetCurrentDatetime'
import {modifyDT} from '../../components/ModifyDT'
import {modifyKeys} from '../../components/ModifyKeys'

const keyMap = {
  smsId: 'SMS ID',
  smsTxnDT: 'SMS Transaction DateTime',
  receivedDT: 'Received DateTime',
  source: 'Source',
  walletAccName: 'Wallet Account Name',
  walletAccNo: 'Wallet Account No',
  walletCd: 'Wallet Code',
  txnId: 'TxnID',
  senderReceiver: 'Sender/Receiver',
  serviceCenter: 'Service Center',
  amount: 'Amount',
  smsBody: 'SMS Content',
  smsTemplate: 'SMS Template',
  txnFee: 'Transaction Fee',
  smsBal: 'SMS Balance',
  walletBalance: 'Wallet Balance',
  balDiff: 'Balance Different',
  smsType: 'SMS Type',
  // transId: 'Transaction ID',
  // reqId: 'Request ID',
}

function escapeCsvValue(value: string): string {
  // If the value contains a comma or line breaks, wrap it in double quotes and escape existing double quotes and line breaks
  if (value.includes(',') || value.includes('\n') || value.includes('\r')) {
    return `"${value.replace(/"/g, '""').replace(/[\n\r]+/g, ' ')}"`
  }
  return value
}

// modifyKeys(formData, keyMap)

function generateFile(formData: any) {
  let dataset = formData.map((obj: any) => {
    const updatedObj = {...obj}
    delete updatedObj.transId
    delete updatedObj.reqId
    return updatedObj
  })

  //Modify receivedDT
  dataset = modifyDT(dataset, 'Received DateTime')
  dataset = modifyDT(dataset, 'SMS Transaction DateTime')

  const header = Object.keys(dataset[0]).join(',')
  const rows = dataset.map((transaction: any) =>
    Object.values(transaction)
      .map((value: any) => escapeCsvValue(value.toString()))
      .join(',')
  )

  const csvContent = `${header}\n${rows.join('\n')}`
  const blob = new Blob([csvContent], {type: 'text/csv'})

  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `sms_management_${getCurrentDateTime()}.csv`
  a.click()

  URL.revokeObjectURL(url)
}

export default function SMS_Export_CSV(filterValues: any, setDownloadLoading: any): void {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  setDownloadLoading(true)

  let walletAccNo = filterValues.walletAccNo.trim()
  let smsType: string = filterValues.smsType.trim()
  let smsTemplate = filterValues.smsTemplate.trim()
  let dateTimeFrom = filterValues.dateTimeFrom.trim()
  let dateTimeTo = filterValues.dateTimeTo.trim()
  let search = filterValues.search.trim()
  let txnId = filterValues.txnId.trim()

  axios
    .post(
      `${SISPAY_API}/sms/listmessage`,
      {
        request: {
          action: 'download',
          fromDate: dateTimeFrom,
          toDate: dateTimeTo,
          session: session,
          // page: '1',
          //Filter
          ...(search && {bySearch: search}),
          ...(txnId && {byTxnId: txnId}),
          ...(walletAccNo && {byWalletAccNo: walletAccNo}),
          ...(smsType && {bySmsType: smsType}),
          ...(smsTemplate && {bySmsTemplate: smsTemplate}),
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function (response) {
      let formData = modifyKeys(response.data.result.smsList, keyMap)

      generateFile(formData)

      setDownloadLoading(false)
    })
    .catch(function (error) {})
}
