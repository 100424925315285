import axios from 'axios'
import clsx from 'clsx'
import React, {Ref, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Column, useTable, useBlockLayout, useResizeColumns, useFlexLayout} from 'react-table'
import {useLayout} from '../../../../_metronic/layout/core'
import {metricsArray} from '../../components/MetricsArray'
import {Styles} from '../../components/Table_Style'
import {KTSVG} from '../../../../_metronic/helpers'
import {OverlayTrigger} from 'react-bootstrap'
import popover from '../../components/Popover'
import {Export_CSV} from '../../components/Export_CSV'
import {Export_Excel} from '../../components/Export_Excel'
import FormatNumber from '../../components/FormatNumber'
import {PreviousDayAgentSummary_Filter} from './components/PreviousDayAgentSummary_Filter'
import {PreviousDayAgentSummary_ExportPDF} from './components/PreviousDayAgentSummary_ExportPDF'
import {usePreviousDayAgentSummaryStore} from './components/PreviousDayAgentSummary_Store'

const PreviousDayAgentSummary_List: React.FC = () => {
  //States
  const [formData, setFormData] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [isAction, setIsAction] = useState(false)
  const [metrics, setMatrics] = useState<any>(undefined)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  //Refs
  const creditReloadFilterBtn = useRef<any>()

  //Zustand Store
  const setGetData = usePreviousDayAgentSummaryStore((state: any) => state.setGetData)

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  let items = formData

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = Math.min(firstIndex + itemsPerPage, items.length)
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : []

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = Number(e.target.value)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset the current page to 1
  }
  //Pagincation

  //Table::START

  useEffect(() => {
    let metrics = localStorage.getItem('creditReloadMetrics')
    if (metrics) {
      setMatrics(JSON.parse(metrics))
    } else {
      setMatrics([])
    }
  }, [formData, showMetrics])

  interface Data {
    depositAmount: string
    nagadDepositAmount: string
    agentNickname: string
    depositCount: string
    withdrawalCount: string
    agentAvailableCredit: string
    agentCredit: string
    rocketDepositAmount: string
    rocketWithdrawalAmount: string
    nagadWithdrawalAmount: string
    bkashWithdrawalAmount: string
    depositComm: string
    bkashDepositAmount: string
    agentUsername: string
    withdrawalAmount: string
    withdrawalComm: string
    agentAvailableComm: string
  }

  const keyMap = {
    agentUsername: 'Agent Username',
    agentNickname: 'Agent Nickname',
    depositAmount: 'Total Cash-Out (Deposit) Amount',
    nagadDepositAmount: 'Nagad Cash-Out (Deposit) Amount',
    depositCount: 'Total Cash-Out (Deposit) Count',
    withdrawalCount: 'Total Cash-In (Withdrawal) Count',
    agentAvailableCredit: 'Available Credit',
    agentCredit: 'Total Credit',
    rocketDepositAmount: 'Rocket Cash-Out (Deposit) Amount',
    rocketWithdrawalAmount: 'Rocket Cash-In (Withdrawal) Amount',
    nagadWithdrawalAmount: 'Nagad Cash-In (Withdrawal) Amount',
    bkashWithdrawalAmount: 'Bkash Cash-In (Withdrawal) Amount',
    depositComm: 'Total Cash-Out (Deposit) Commission',
    bkashDepositAmount: 'Bkash Cash-Out (Deposit) Amount',
    withdrawalAmount: 'Total Cash-In (Withdrawal) Amount',
    withdrawalComm: 'Total Cash-In (Withdrawal) Commission',
    agentAvailableComm: 'Available Commission',
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: 'Agent Nickname',
        accessor: 'agentNickname',
        width: 100,
      },
      {
        Header: 'Agent Username',
        accessor: 'agentUsername',
      },
      {
        Header: 'Holding Credit',
        accessor: (row: any) => {
          return row.earmarkDepositCredit == undefined ? '' : FormatNumber(row.earmarkDepositCredit)
        },
      },
      {
        Header: 'Total Cash-In (Withdrawal) Count',
        accessor: (row: any) => {
          return row.withdrawalCount
        },
      },
      {
        Header: 'Total Cash-In (Withdrawal) Amount',
        accessor: (row: any) => {
          return FormatNumber(row.withdrawalAmount)
        },
      },
      {
        Header: 'Total Cash-Out (Deposit) Count',
        accessor: (row: any) => {
          return row.depositCount
        },
      },
      {
        Header: 'Total Cash-Out (Deposit) Amount',
        accessor: (row: any) => {
          return FormatNumber(row.depositAmount)
        },
      },
      {
        Header: 'Total Credit',
        accessor: (row: any) => {
          return FormatNumber(row.agentCredit)
        },
      },
      {
        Header: 'Available Credit',
        accessor: (row: any) => {
          return FormatNumber(row.agentAvailableCredit)
        },
      },
      {
        Header: 'Available Commission',
        accessor: (row: any) => {
          return FormatNumber(row.agentAvailableComm)
        },
      },
      {
        Header: 'BKASH Cash-Out (Deposit) Amount',
        accessor: (row: any) => {
          return FormatNumber(row.bkashDepositAmount)
        },
      },
      {
        Header: 'NAGAD Cash-Out (Deposit) Amount',
        accessor: (row: any) => {
          return FormatNumber(row.nagadDepositAmount)
        },
      },
      {
        Header: 'Rocket Cash-Out (Deposit) Amount',
        accessor: (row: any) => {
          return FormatNumber(row.rocketDepositAmount)
        },
      },
      {
        Header: 'BKASH Cash-In (Withdrawal) Amount',
        accessor: (row: any) => {
          return FormatNumber(row.bkashWithdrawalAmount)
        },
      },
      {
        Header: 'NAGAD Cash-In (Withdrawal) Amount',
        accessor: (row: any) => {
          return FormatNumber(row.nagadWithdrawalAmount)
        },
      },
      {
        Header: 'Rocket Cash-In (Withdrawal) Amount',
        accessor: (row: any) => {
          return FormatNumber(row.rocketWithdrawalAmount)
        },
      },
      {
        Header: 'Action',
        width: 100,
        accessor: (row: any) => {
          return (
            <Link
              to={`/agent/previous-day-summary/${row.agentUsername}`}
              state={row}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setGetData(true)}
            >
              <OverlayTrigger trigger='hover' placement='top' overlay={popover('View Details')}>
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen038.svg' className='svg-icon-3' />
                </div>
              </OverlayTrigger>{' '}
            </Link>
          )
        },
      },
    ],
    []
  )
  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {hiddenColumns: metrics ? metrics : []},
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <>
        <div>
          <div
            className='card card-custom card-flush mb-5'
            id='selectMetricsWrapper'
            style={{
              display: showMetrics ? 'block' : 'none',
            }}
          >
            <div className='card-body py-5'>
              <div className='row row-cols-4 ms-3 justify-content-center'>
                <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                  <div className='form-check-label d-flex m-5 fw-bold'>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                  </div>
                </div>
                {allColumns
                  .filter((column) => column.Header !== ' ' && column.Header !== 'Action')
                  .map((column) => (
                    <div className='col form-check form-check-custom form-check-solid form-check-sm w-200px'>
                      <div key={column.id}>
                        <label className='form-check-label d-flex justify-content-center align-items-center m-5'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            onClick={() => {
                              metricsArray('creditReloadMetrics', column.id)
                            }}
                            {...column.getToggleHiddenProps()}
                          />{' '}
                          {String(column.Header)}
                        </label>
                      </div>
                    </div>
                  ))}
                <br />
              </div>
            </div>
          </div>
          <div className='card card-custom p-5'>
            {formData.length > 0 ? (
              <div className='table-responsive mb-5'>
                <div {...getTableProps()} className='table' style={{width: '100%'}}>
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div {...column.getHeaderProps()} className='th'>
                            {column.render('Header')}
                            {/* Use column.getResizerProps to hook up the events correctly */}
                            <div
                              {...(column as any).getResizerProps()}
                              className={`resizer ${
                                (column as any).isResizing ? 'isResizing' : ''
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <div {...row.getRowProps()} className='tr'>
                          {row.cells.map((cell: any) => {
                            return (
                              <div {...cell.getCellProps()} className='td'>
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className='text-center'>No Data Found</div>
            )}
          </div>
        </div>
      </>
    )
  }
  //Table::END

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        <div className='m-0'>
          <div
            className='position-relative me-5'
            style={{
              width: '180px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-info w-100'
              onClick={() => {
                setIsAction(!isAction)
              }}
            >
              Download Record As:
            </div>{' '}
            {isAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_CSV(formData, keyMap, startDate, endDate, 'Previous_Day_Agent_Summary')
                    setIsAction(!isAction)
                  }}
                >
                  CSV
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_Excel(formData, keyMap, startDate, endDate, 'Previous_Day_Agent_Summary')
                    setIsAction(!isAction)
                  }}
                >
                  Excel WorkBook
                </button>{' '}
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    PreviousDayAgentSummary_ExportPDF(formData, startDate, endDate)
                    setIsAction(!isAction)
                  }}
                >
                  PDF
                </button>{' '}
              </div>
            )}
          </div>
        </div>
        <button
          type='button'
          className='btn btn-sm btn-flex fw-bold rotate'
          onClick={(e) => {
            setShowMetrics(!showMetrics)
            e.currentTarget.classList.toggle('active')
          }}
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr004.svg'
            className='svg-icon-6 svg-icon-muted me-1 rotate-180'
          />{' '}
          Select Metrics
        </button>
      </div>

      <PreviousDayAgentSummary_Filter
        setFormData={setFormData}
        creditReloadFilterBtn={creditReloadFilterBtn}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      <Styles>
        <Table columns={columns} data={currentItems == undefined ? [] : currentItems} />
      </Styles>
      {/* Pagination START */}
      <div className='mt-5 d-flex justify-content-center align-items-center'>
        <span className='me-5'>Total: {formData.length}</span>
        <button
          className='btn btn-secondary p-2 me-2 w-100px h-30px'
          disabled={currentPage === 1}
          onClick={previousPage}
        >
          Previous
        </button>
        {currentPage} of {lastPage}
        <button
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
          disabled={currentPage === lastPage}
          onClick={nextPage}
        >
          Next
        </button>
        <select
          onChange={(e) => changePage(Number(e.target.value))}
          value={currentPage}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          {Array.from({length: lastPage}, (_, i) => (
            <option key={i + 1} value={i + 1}>
              Page {i + 1}
            </option>
          ))}
        </select>
        <select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          className='btn btn-secondary p-2 ms-2 w-100px h-30px'
        >
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
          <option value={250}>250 per page</option>
          <option value={500}>500 per page</option>
        </select>
      </div>
      {/* Pagination END */}
    </div>
  )
}

export default PreviousDayAgentSummary_List
