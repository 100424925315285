export function filterArrayByMultipleKeys(array: any[], keys: string[] = [], values: any[] = []) {
  if (keys.length === 0 || keys.every((key) => key === '')) {
    return array
  }
  return array.filter((item) => {
    let match = true
    for (let i = 0; i < keys.length; i++) {
      if (values[i] !== '' && item[keys[i]] !== values[i]) {
        match = false
        break
      }
    }
    return match
  })
}
