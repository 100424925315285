import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import axios from 'axios'

const WalletAssignmentPage_Edit: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  let state: any = location.state

  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  const [loading, setLoading] = useState(false)

  const initialValues = {
    id: String(state.id),
    walletCd: String(state.walletCd),
    walletAccNo: String(state.walletAccNo),
    merchantCd: String(state.merchantCd),
    status: String(state.status),
  }

  const walletAssignmentEditSchema = Yup.object().shape({
    walletCd: Yup.string(),
    walletAccNo: Yup.string(),
    merchantCd: Yup.string(),
    status: Yup.string().required('Status is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: walletAssignmentEditSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        axios
          .post(
            `${SISPAY_API}/wallet/assignment/update`,
            {
              request: {
                session: session,
                id: values.id,
                walletCd: values.walletCd,
                walletAccNo: values.walletAccNo,
                merchantCd: values.merchantCd,
                status: values.status,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function (response) {
            if (response.data.code == '200') {
              setLoading(false)
              navigate(-1)
              alert('Wallet Assignment Successfully Updated!')
            } else {
              alert(response.data.message)
            }
          })
          .catch(function (error) {})
      } catch (error) {}
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-start'>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-flex fw-bold btn-color-white-700 btn-danger ms-3'
        >
          Back
        </a>
        <span className='text-gray-800 fs-1 fw-bolder ms-5 d-flex align-items-center'>
          Edit Wallet Assignment
        </span>
      </div>{' '}
      <div className='card card-custom card-flush mt-5 ms-3'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group ID */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>ID</label>
            <input
              placeholder='Enter your ID'
              type='text'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('id')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.id && formik.errors.id,
                },
                {
                  'is-valid': formik.touched.id && !formik.errors.id,
                }
              )}
            />
            {formik.touched.id && formik.errors.id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.id}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account Code */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Wallet Code</label>
            <input
              placeholder='Enter your Wallet Code'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletCd')}
              disabled={true}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletCd && formik.errors.walletCd,
                },
                {
                  'is-valid': formik.touched.walletCd && !formik.errors.walletCd,
                }
              )}
            />
            {formik.touched.walletCd && formik.errors.walletCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Wallet Account Number */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>
              Wallet Account Number
            </label>
            <input
              placeholder='Enter your Wallet Account Number'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('walletAccNo')}
              disabled={true}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.walletAccNo && formik.errors.walletAccNo,
                },
                {
                  'is-valid': formik.touched.walletAccNo && !formik.errors.walletAccNo,
                }
              )}
            />
            {formik.touched.walletAccNo && formik.errors.walletAccNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.walletAccNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Merchant Code */}
          <div className='fv-row mb-5'>
            <label className='form-label required fw-bolder text-dark fs-6'>Merchant Code</label>
            <input
              type='text'
              placeholder='Enter your merchant code'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('merchantCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.merchantCd && formik.errors.merchantCd,
                },
                {
                  'is-valid': formik.touched.merchantCd && !formik.errors.merchantCd,
                }
              )}
            />
            {formik.touched.merchantCd && formik.errors.merchantCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.merchantCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Status */}
          <div className='fv-row mb-8'>
            <label className='form-label required fw-bolder text-dark fs-6'>Status</label>
            <select
              {...formik.getFieldProps('status')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.status && formik.errors.status,
                },
                {
                  'is-valid': formik.touched.status && !formik.errors.status,
                }
              )}
              data-placeholder=''
            >
              <option value=''>Please Select</option>
              <option value='ACTIVE'>Active</option>
              <option value='INACTIVE'>Inactive</option>
            </select>
          </div>{' '}
          {/* end::Form group */}
          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Update</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}

export default WalletAssignmentPage_Edit
