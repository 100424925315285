import {create} from 'zustand'

export const useTransactionStore = create((set) => ({
  //Data
  getData: false,
  filterOptions: {
    search: '',
    txnId: '',
    walletAccNo: '',
    merchant: '',
    transSts: '',
    transType: '',
    dateTimeFrom: '',
    dateTimeTo: '',
  },

  //Functions
  setGetData: (value: boolean) => set({getData: value}),
  setFilterOptions: (value: any) => set({filterOptions: value}),
}))
