export function pushUniqueValue(selectedArray: any, value: any, setSelectedArray: any) {
  const id = value.id
  const index = selectedArray.findIndex((item: any) => item.id === id)

  if (index === -1) {
    selectedArray.push({id})
  } else {
    selectedArray.splice(index, 1)
  }

  setSelectedArray([...selectedArray])
  //setSelectedArray(selectedArray)
}
