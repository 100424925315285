import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useAuth} from '../../auth/core/Auth'
import Select from 'react-select'
import {customStyles} from '../../components/CustomStyles'
import axios from 'axios'
import {sortByName} from '../../components/SortByName'
import {ErrorHandling} from '../../components/ErrorHandling'
import getTimestampInSec from '../../components/GetTimeInSeconds'
import getTimestamp from '../../components/GetTimestamp'
import sha1 from 'crypto-js/sha1'
import qs from 'qs'

const ManualWithdrawal_Merchant: React.FC = () => {
  const {logout} = useAuth()
  const navigate = useNavigate()
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //States
  const [loading, setLoading] = useState(false)
  const [merchants, setMerchants] = useState([])
  const [merchantCdList, setMerchantCdList] = useState([])

  //Get Data
  function retrieveMerchant(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          let merchantCdList: any = Array.from(
            new Set(
              result
                .filter((item: any) => item.merchantCd !== '')
                .map((item: any) => {
                  return {
                    value: item.merchantCd,
                    label: item.merchantName + ' - ' + item.merchantCd,
                  }
                })
            )
          )
          setMerchants(result)
          setMerchantCdList(sortByName(merchantCdList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  function W_Generate() {
    let MerchantCode = formik.values.merchantCd
    let CustomerCode = formik.values.customerCd
    let CurrencyCode = formik.values.currency
    let SecureCode = formik.values.secureCd
    let BankCode = formik.values.bankCode
    let Amount: any = formik.values.amount
    let DestinationAccNo = formik.values.destinationAccNo
    let TimeStamp = formik.values.timestamp

    let combinedString = `${MerchantCode}${TimeStamp}${CustomerCode}${DestinationAccNo}${SecureCode}${Amount}${CurrencyCode}${BankCode}`
    let signature = sha1(combinedString).toString()
    formik.setFieldValue('signature', signature)
    return signature
  }

  useEffect(() => {
    retrieveMerchant(SISPAY_API, '/merchant/list', 'merchant')
  }, [])

  const initialValues = {
    merchantCd: '',
    secureCd: '',
    customerCd: 'CC' + getTimestampInSec(),
    currency: 'BDT',
    bankCode: '',
    amount: '',
    transId: 'TID' + getTimestampInSec(),
    reference: '',
    timestamp: getTimestamp(),
    clientIp: '0.0.0.0',
    signature: '',
    resultUrl: 'www.google.com',
    responseUrl: 'www.google.com',
    destinationAccNo: '',
    destinationAccName: 'Withdrawal Test Dest',
  }

  const ManualDepositCreateSchema = Yup.object().shape({})

  const formik = useFormik({
    initialValues,
    // enableReinitialize: true,
    validationSchema: ManualDepositCreateSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      let data: any = {
        MerchantCode: values.merchantCd,
        CustomerCode: values.customerCd,
        Currency: values.currency,
        BankCode: values.bankCode,
        Amount: values.amount,
        TransactionId: values.transId,
        Reference: values.reference,
        TransactionTimeStamp: values.timestamp,
        ClientIP: values.clientIp,
        Signature: W_Generate(),
        ResultURL: values.resultUrl,
        ResponseURL: values.responseUrl,
        DestinationAccountNo: values.destinationAccNo,
        DestinationAccountName: values.destinationAccName,
      }

      data = qs.stringify(data)

      // Create a form element
      const form = document.createElement('form')
      form.method = 'POST'
      form.action = `${SISPAY_API}/cash/withdrawalrequest/create`
      form.style.display = 'none' // Hide the form

      // Helper function to create and append hidden input fields
      const addHiddenField = (name: string, value: string) => {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = value
        form.appendChild(input)
      }

      // Add hidden input fields for each parameter
      addHiddenField('MerchantCode', values.merchantCd)
      addHiddenField('CustomerCode', values.customerCd)
      addHiddenField('Currency', values.currency)
      addHiddenField('BankCode', values.bankCode)
      addHiddenField('Amount', values.amount)
      addHiddenField('TransactionId', values.transId)
      addHiddenField('Reference', values.reference)
      addHiddenField('TransactionTimeStamp', values.timestamp)
      addHiddenField('ClientIP', values.clientIp)
      addHiddenField('Signature', W_Generate())
      addHiddenField('ResultURL', values.resultUrl)
      addHiddenField('ResponseURL', values.responseUrl)
      addHiddenField('DestinationAccountNo', values.destinationAccNo)
      addHiddenField('DestinationAccountName', values.destinationAccName)

      // Append the form to the document body
      document.body.appendChild(form)

      // Submit the form asynchronously using fetch
      const formData = new FormData(form)
      const urlSearchParams = new URLSearchParams(formData as any)

      fetch(form.action, {
        method: form.method,
        body: urlSearchParams,
      })
        .then((response) => response.text())
        .then((result: any) => {
          // Handle the response here
          result = JSON.parse(result)
          alert(result.message)
          setLoading(false)
          if (result.code == 200) {
            navigate(-1)
          }
        })
        .catch((error) => {
          // Handle errors
          alert(error)
          setLoading(false)
        })

      // Optionally, you can remove the form from the document after submission
      document.body.removeChild(form)
    },
  })

  return (
    <div>
      <div className='d-flex justify-content-between'></div>{' '}
      <div className='card card-custom card-flush mt-5'>
        <form onSubmit={formik.handleSubmit} className='m-10'>
          {/* begin::Form group Merchant Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Merchant Code</label>
            <Select
              className='basic-single'
              classNamePrefix='select'
              options={merchantCdList}
              styles={customStyles}
              isClearable={true}
              name='merchantCd'
              onChange={(e: any) => {
                if (e !== null) {
                  formik.setFieldValue('merchantCd', e.value)
                  const secureCd = merchants
                    .filter((item: any) => item.merchantCd === e.value)
                    .map((item: any) => item.secureCd)
                  formik.setFieldValue('secureCd', secureCd)
                } else {
                  formik.setFieldValue('merchantCd', '')
                  formik.setFieldValue('secureCd', '')
                }
              }}
            />
            {formik.touched.merchantCd && formik.errors.merchantCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.merchantCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Secure Code */}
          <div className='fv-row mb-8 d-none'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Secure Code</label>
            <input
              placeholder='Enter Secure Code'
              autoComplete='off'
              disabled={true}
              {...formik.getFieldProps('secureCd')}
              value={merchants
                .filter((item: any) => item.merchantCd === formik.values.merchantCd)
                .map((item: any) => item.secureCd)}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.secureCd && formik.errors.secureCd,
                },
                {
                  'is-valid': formik.touched.secureCd && !formik.errors.secureCd,
                }
              )}
            />
            {formik.touched.secureCd && formik.errors.secureCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.secureCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Customer Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Customer Code</label>
            <input
              placeholder='Enter Customer Code'
              autoComplete='off'
              //   disabled={true}
              {...formik.getFieldProps('customerCd')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.customerCd && formik.errors.customerCd,
                },
                {
                  'is-valid': formik.touched.customerCd && !formik.errors.customerCd,
                }
              )}
            />
            {formik.touched.customerCd && formik.errors.customerCd && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.customerCd}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Currency */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Currency</label>
            <input
              placeholder='Enter Currency'
              autoComplete='off'
              //   disabled={true}
              {...formik.getFieldProps('currency')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.currency && formik.errors.currency,
                },
                {
                  'is-valid': formik.touched.currency && !formik.errors.currency,
                }
              )}
            />
            {formik.touched.currency && formik.errors.currency && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.currency}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Bank Code */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Bank Code</label>
            <select
              {...formik.getFieldProps('bankCode')}
              className={clsx('form-control bg-transparent')}
              data-placeholder=''
            >
              <option value=''>Select Bank Code</option>
              <option value='NAGAD'>NAGAD</option>
              <option value='BKASH'>BKASH</option>
              <option value='ROCKET'>ROCKET</option>
            </select>
            {formik.touched.bankCode && formik.errors.bankCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.bankCode}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Amount */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Amount</label>
            <input
              placeholder='Enter Amount'
              autoComplete='off'
              type='number'
              //   disabled={true}
              {...formik.getFieldProps('amount')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.amount && formik.errors.amount,
                },
                {
                  'is-valid': formik.touched.amount && !formik.errors.amount,
                }
              )}
            />
            {formik.touched.amount && formik.errors.amount && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.amount}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Merchant Transaction ID */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Merchant Transaction ID
            </label>
            <input
              placeholder='Enter Merchant Transaction ID'
              autoComplete='off'
              {...formik.getFieldProps('transId')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.transId && formik.errors.transId,
                },
                {
                  'is-valid': formik.touched.transId && !formik.errors.transId,
                }
              )}
            />
            {formik.touched.transId && formik.errors.transId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.transId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Reference */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Reference</label>
            <input
              placeholder='Enter Reference'
              autoComplete='off'
              {...formik.getFieldProps('reference')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.reference && formik.errors.reference,
                },
                {
                  'is-valid': formik.touched.reference && !formik.errors.reference,
                }
              )}
            />
            {formik.touched.reference && formik.errors.reference && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.reference}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Transaction Timestamp */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Transaction Timestamp
            </label>
            <input
              placeholder='Enter Transaction Timestamp'
              autoComplete='off'
              {...formik.getFieldProps('timestamp')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.timestamp && formik.errors.timestamp,
                },
                {
                  'is-valid': formik.touched.timestamp && !formik.errors.timestamp,
                }
              )}
            />
            {formik.touched.timestamp && formik.errors.timestamp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.timestamp}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Client IP */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Client IP</label>
            <input
              placeholder='Enter Client IP'
              autoComplete='off'
              {...formik.getFieldProps('clientIp')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.clientIp && formik.errors.clientIp,
                },
                {
                  'is-valid': formik.touched.clientIp && !formik.errors.clientIp,
                }
              )}
            />
            {formik.touched.clientIp && formik.errors.clientIp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.clientIp}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Result URL */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Result URL</label>
            <input
              placeholder='Enter Result URL'
              autoComplete='off'
              {...formik.getFieldProps('resultUrl')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.resultUrl && formik.errors.resultUrl,
                },
                {
                  'is-valid': formik.touched.resultUrl && !formik.errors.resultUrl,
                }
              )}
            />
            {formik.touched.resultUrl && formik.errors.resultUrl && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.resultUrl}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Response URL */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Response URL</label>
            <input
              placeholder='Enter Response URL'
              autoComplete='off'
              {...formik.getFieldProps('responseUrl')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.responseUrl && formik.errors.responseUrl,
                },
                {
                  'is-valid': formik.touched.responseUrl && !formik.errors.responseUrl,
                }
              )}
            />
            {formik.touched.responseUrl && formik.errors.responseUrl && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.responseUrl}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Destination Account Number */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Destination Account Number
            </label>
            <input
              placeholder='0000000'
              autoComplete='off'
              {...formik.getFieldProps('destinationAccNo')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid': formik.touched.destinationAccNo && formik.errors.destinationAccNo,
                },
                {
                  'is-valid': formik.touched.destinationAccNo && !formik.errors.destinationAccNo,
                }
              )}
            />
            {formik.touched.destinationAccNo && formik.errors.destinationAccNo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.destinationAccNo}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group Destination Account Name */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Destination Account Name
            </label>
            <input
              placeholder='Enter Destination Account Name'
              autoComplete='off'
              {...formik.getFieldProps('destinationAccName')}
              className={clsx(
                'form-control bg-grey',
                {
                  'is-invalid':
                    formik.touched.destinationAccName && formik.errors.destinationAccName,
                },
                {
                  'is-valid':
                    formik.touched.destinationAccName && !formik.errors.destinationAccName,
                }
              )}
            />
            {formik.touched.destinationAccName && formik.errors.destinationAccName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.destinationAccName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-success w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}

export default ManualWithdrawal_Merchant
