import {Ref, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import React from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {Column, useTable, useFlexLayout, useResizeColumns} from 'react-table'
import {checkValueExists} from '../../components/CheckValueExists'
import popover from '../../components/Popover'
import {pushUniqueValue} from '../../components/PushUniqueValue'
import styled from 'styled-components'
import axios from 'axios'

export default function BulkApproval(props: any) {
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const requestFilterBtn = props.requestFilterBtn
  const [amount, setAmount] = useState('')
  const [searchedResult, setSearchResult] = useState([])

  //Pagination
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const items = searchedResult

  const lastPage = Math.ceil(items.length / itemsPerPage)

  const changePage = (page: number) => {
    setCurrentPage(page)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const firstIndex = (currentPage - 1) * itemsPerPage
  const lastIndex = firstIndex + itemsPerPage
  let currentItems = items.length > 0 ? items.slice(firstIndex, lastIndex) : []
  //Pagincation

  function getSearchResult(amount: string) {
    axios
      .post(
        `${SISPAY_API}/sms/request/bulkapprovallist`,
        {
          request: {
            session: session,
            amount: amount,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        setSearchResult(response.data.result.smsRequestList)
      })
      .catch(function (error) {})
  }

  function bulkApprovalSubmission(amount: string) {
    if (amount != '') {
      axios
        .post(
          `${SISPAY_API}/sms/request/bulkapprove`,
          {
            request: {
              session: session,
              amount: amount,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          requestFilterBtn.current.click()
          if (response.data.code == 200) {
            alert('Bulk Approval Successfuly.')
          } else {
            alert(response.data.message)
          }
        })
        .catch(function (error) {
          alert(error)
        })
    } else {
      alert('Amount cannot be empty.')
    }
  }

  //Table
  const Styles = styled.div`
    padding: 1rem;
    .table {
      display: inline-block;

      .tr {
        :last-child {
          .td {
            border-bottom: 0;
          }
        }
      }
      .th,
      .td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid rgba(182, 173, 178, 0.2);
        border-right: 1px solid rgba(182, 173, 178, 0.2);

        position: relative;

        :last-child {
          border-right: 0;
        }
        .resizer {
          display: inline-block;

          width: 10px;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(50%);
          z-index: 1;
          touch-action: none;

          &.isResizing {
          }
        }
      }
    }
  `

  interface Data {
    smsId: string
    smsTxnDT: string
    receivedDT: string
    source: string
    walletAccName: string
    walletAccNo: string
    walletCd: string
    txnId: string
    senderReceiver: string
    serviceCenter: string
    amount: string
    smsBody: string
    smsType: string
    smsTemplate: string
    txnFee: string
    suspectedReason: string
    smsBal: string
    walletBal: string
    balDiff: string
    merchantName: string
    merchantCd: string
    customerCd: string
    srcWalletAcc: string
    submittedTxnId: string
    depositReqId: string
    reference: string
    remark: string
  }

  const columns = React.useMemo<Column<Data>[]>(
    () => [
      {
        Header: 'SMS ID',
        accessor: 'smsId',
      },
      {
        Header: 'SMS Transaction DateTime',
        accessor: (row: any) => {
          return row.smsTxnDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        Header: 'Received DateTime',
        accessor: (row: any) => {
          return row.receivedDT.replace('T', ' ').replace(/\.\d+/, '')
        },
      },
      {
        Header: 'Source',
        accessor: 'source',
      },
      {
        Header: 'Wallet Account Name',
        accessor: 'walletAccName',
      },
      {
        Header: 'Wallet Account No',
        accessor: 'walletAccNo',
      },
      {
        Header: 'Wallet Code',
        accessor: 'walletCd',
      },
      {
        Header: 'TxnID',
        accessor: 'txnId',
      },
      {
        Header: 'Sender Receiver',
        accessor: 'senderReceiver',
      },
      {
        Header: 'Service Center',
        accessor: 'serviceCenter',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'SMS Content',
        accessor: (row: any) => {
          return (
            <OverlayTrigger trigger='hover' placement='top' overlay={popover(row.smsBody)}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {row.smsBody}
              </div>
            </OverlayTrigger>
          )
        },
      },
      {
        Header: 'SMS Type',
        accessor: 'smsType',
      },
      {
        Header: 'SMS Template',
        accessor: 'smsTemplate',
      },
      {
        Header: 'Transaction Fee',
        accessor: 'txnFee',
      },
      {
        Header: 'Suspected Reason',
        accessor: 'suspectedReason',
      },
      {
        Header: 'Balance',
        accessor: 'smsBal',
      },
      {
        Header: 'Wallet Balance',
        accessor: 'walletBal',
      },
      {
        Header: 'Balance Different',
        accessor: 'balDiff',
      },
      {
        Header: 'Merchant',
        accessor: (row: any) => {
          return row.merchantName + ' - ' + row.merchantCd
        },
      },
      {
        Header: 'Customer Code',
        accessor: 'customerCd',
      },
      {
        Header: 'Source Wallet Account',
        accessor: 'srcWalletAcc',
      },
      {
        Header: 'Submitted Transaction ID',
        accessor: 'submittedTxnId',
      },
      {
        Header: 'Deposit Request ID',
        accessor: 'depositReqId',
      },
      {
        Header: 'Reference',
        accessor: (row: any) => {
          return (
            <OverlayTrigger trigger='hover' placement='top' overlay={popover(row.reference)}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {row.reference}
              </div>
            </OverlayTrigger>
          )
        },
      },
      {
        Header: 'Remark',
        accessor: 'remark',
      },
    ],
    []
  )

  interface TableProps {
    columns: Column<Data>[]
    data: Data[]
  }

  interface CheckboxProps {
    indeterminate?: boolean
    [x: string]: any
  }

  type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    indeterminate?: boolean
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({indeterminate, ...rest}: Props, ref: Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef<HTMLInputElement>(null)
      const resolvedRef: any = ref || defaultRef

      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate || false
        }
      }, [resolvedRef, indeterminate])

      return <input className='form-check-input me-3' type='checkbox' ref={resolvedRef} {...rest} />
    }
  )

  function Table({columns, data}: TableProps) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 400,
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      allColumns,
      getToggleHideAllColumnsProps,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFlexLayout,
      useResizeColumns
    )

    return (
      <div className='card card-custom p-5'>
        <div className='table-responsive mb-5'>
          <div {...getTableProps()} className='table' style={{width: '100%'}}>
            <div>
              {headerGroups.map((headerGroup) => (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  className='tr fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                >
                  {headerGroup.headers.map((column) => (
                    <div {...column.getHeaderProps()} className='th'>
                      {column.render('Header')}
                      {/* Use column.getResizerProps to hook up the events correctly */}
                      <div
                        {...(column as any).getResizerProps()}
                        className={`resizer ${(column as any).isResizing ? 'isResizing' : ''}`}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <div {...row.getRowProps()} className='tr'>
                    {row.cells.map((cell) => {
                      return (
                        <div {...cell.getCellProps()} className='td'>
                          {cell.render('Cell')}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
  //Table::END

  return (
    <div
      className='modal fade'
      id='kt_modal_bulk_approval'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered mw-75'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              Bulk Approval Accounts
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => {
                setAmount('')
                setSearchResult([])
              }}
            ></button>
          </div>
          <div className='modal-body'>
            <div className='d-flex flew-row justify-content-between align-items-center'>
              <div className='d-flex flex-row align-items-center'>
                <h5 className='m-0'>Amount less than : </h5>{' '}
                <input
                  className='form-control form-control-solid w-100px bg-white ms-2'
                  type='number'
                  value={amount}
                  onChange={(e: any) => {
                    setAmount(e.target.value)
                    if (e.target.value == '') {
                      setSearchResult([])
                    }
                  }}
                />
              </div>
              <div className='d-flex flex-row align-items-center'>
                <button
                  className='btn btn-lg btn-primary'
                  disabled={String(amount) == '' ? true : false}
                  onClick={() => {
                    getSearchResult(amount)
                  }}
                >
                  Search
                </button>
              </div>
            </div>
            <Styles>
              <Table columns={columns} data={currentItems == undefined ? [] : currentItems} />
            </Styles>
            {/* Pagination START */}
            <div className='mt-5 d-flex justify-content-center align-items-center'>
              <span className='me-5'>Total: {searchedResult.length}</span>
              <button
                className='btn btn-secondary p-2 me-2 w-100px h-30px'
                disabled={currentPage === 1}
                onClick={previousPage}
              >
                Previous
              </button>
              {currentPage} of {lastPage}
              <button
                className='btn btn-secondary p-2 ms-2 w-100px h-30px'
                disabled={currentPage === lastPage}
                onClick={nextPage}
              >
                Next
              </button>
              <select
                onChange={(e) => changePage(Number(e.target.value))}
                className='btn btn-secondary p-2 ms-2 w-100px'
              >
                {Array.from({length: lastPage}, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    Page {i + 1}
                  </option>
                ))}
              </select>
            </div>
            {/* Pagination END */}
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary'
              data-bs-dismiss='modal'
              onClick={() => {
                setAmount('')
                setSearchResult([])
              }}
            >
              Close
            </button>
            <button
              type='button'
              className='btn btn-primary'
              data-bs-dismiss='modal'
              disabled={currentItems.length > 0 && Number(amount) !== 0 ? false : true}
              onClick={() => bulkApprovalSubmission(amount)}
            >
              Approval
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
