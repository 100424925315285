import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

export function WalletUtilization_ExportPDF(formData: any, startDate: string, endDate: string) {
  // Register the fonts with pdfMake
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  const documentDefinition = {
    pageOrientation: 'landscape' as 'landscape', // Cast the pageOrientation value explicitly
    pageSize: 'A2' as 'A2',
    content: [
      {text: `Exported Data ${startDate} - ${endDate}`, style: 'header'},
      '\n', // Add a line break
      {
        table: {
          //widths: ['*', '*', '*'], // Adjust column widths as needed
          body: [
            [
              'Wallet Account Name',
              'Wallet Account Number',
              'Wallet Code',
              'Status',
              'Wallet Balance',
              'Daily Deposit Limit',
              'Current Deposit Amount',
              'Current Deposit Count',
              'Deposit Utilization',
              'Daily Withdrawal Limit',
              'Current Withdrawal Amount',
              'Current Withdrawal Count',
              'Withdrawal Utilization',
            ], // Add headers as a separate row
            ...formData.map((item: any) => [
              item.walletAccName,
              item.walletAccNo,
              item.walletCd,
              item.status,
              item.walletBal,
              item.dailyDepositLmt,
              item.depositTotal,
              item.depositCount,
              item.depositUtilise,
              item.dailyWithdrawalLmt,
              item.withdrawalTotal,
              item.withdrawalCount,
              item.withdrawalUtilise,
            ]),
          ],
        },
        layout: 'lightHorizontalLines', // Add a layout to handle long tables
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
    },
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
  pdfDocGenerator.download(`Exported Data ${startDate} - ${endDate}.pdf`)
}
