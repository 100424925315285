import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import {customStyles} from '../../../components/CustomStyles'
import {filterArrayByMultipleKeys} from '../../../components/Filter'
import {getCurrentDateInput} from '../../../components/GetCurrentDateInput'
import {searching} from '../../../components/Searching'
import {sortByDate} from '../../../components/SortByDate'
import {Overlay} from '../../../components/Overlay'
import {useMerchantTransactionHisotryStore} from './MerchantTransactionHistory_Store'
import Modal from '../../../components/Modal'

export function Filter(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const merchantCdStored = localStorage.getItem('merchantCd')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)

  //Props
  let setFormData = props.setFormData
  let setStartDate = props.setStartDate
  let setEndDate = props.setEndDate
  let currentPage = props.currentPage
  let setCurrentPage = props.setCurrentPage
  let setTotalFormData = props.setTotalFormData
  let setFilterValues = props.setFilterValues
  let downloadLoading = props.downloadLoading

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  //Zustand Store
  const filterOptions = useMerchantTransactionHisotryStore((state: any) => state.filterOptions)
  const getData = useMerchantTransactionHisotryStore((state: any) => state.getData)
  const setFilterOptions = useMerchantTransactionHisotryStore(
    (state: any) => state.setFilterOptions
  )
  const setGetData = useMerchantTransactionHisotryStore((state: any) => state.setGetData)

  const initialValues = {
    searchTransaction: filterOptions.searchTransaction
      ? String(filterOptions.searchTransaction)
      : '',
    searchCustomer: filterOptions.searchCustomer ? String(filterOptions.searchCustomer) : '',
    searchAmount: filterOptions.searchAmount ? String(filterOptions.searchAmount) : '',
    transactionType: filterOptions.transactionType ? String(filterOptions.transactionType) : 'ALL',
    transactionStatus: filterOptions.transactionStatus
      ? String(filterOptions.transactionStatus)
      : '',
    walletCd: filterOptions.walletCd ? String(filterOptions.walletCd) : '',
    dateTimeFrom: filterOptions.dateTimeFrom
      ? String(filterOptions.dateTimeFrom)
      : getCurrentDateInput(),
    dateTimeTo: filterOptions.dateTimeTo ? String(filterOptions.dateTimeTo) : getCurrentDateInput(),
  }

  const filterSchema = Yup.object().shape({
    searchTransaction: Yup.string(),
    searchAmount: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .nullable(),
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      let searchTransaction = values.searchTransaction
      let searchCustomer = values.searchCustomer
      let searchAmount = values.searchAmount
      let transactionType = values.transactionType
      let transactionStatus = values.transactionStatus
      let walletCd = values.walletCd
      let dateTimeFrom = values.dateTimeFrom
      let dateTimeTo = values.dateTimeTo

      setFilterValues(values)
      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/merchant/portal/transactionhistory`,
          {
            request: {
              session: session,
              fromDate: dateTimeFrom,
              toDate: dateTimeTo,
              transType: transactionType,
              merchantCd: merchantCdStored,
              action: 'list', //list or download
              page: '1', // required only when action = list

              ...(transactionStatus && {byStatus: transactionStatus}),
              ...(searchTransaction && {byTransaction: searchTransaction}),
              ...(searchCustomer && {byCustomer: searchCustomer}),
              ...(searchAmount && {byAmount: searchAmount}),
              ...(walletCd && {byWalletCd: walletCd}),
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let formData = response.data.result
          setFormData(sortByDate(formData.merchantTransHistory, 'receivedDT'))
          setTotalFormData(formData.totalCount)

          setLoading(false)
          setDisabled(false)

          if (currentPage !== 0) {
            setCurrentPage(1)
          }
        })
        .catch(function (error) {})
    },
  })

  useEffect(() => {
    if (getData == false && currentPage == 0) return

    if (getData == true) {
      setCurrentPage(1)
    }

    setDisabled(true)
    setLoading(true)

    let searchTransaction = formik.values.searchTransaction
    let searchCustomer = formik.values.searchCustomer
    let searchAmount = formik.values.searchAmount
    let transactionType = formik.values.transactionType
    let transactionStatus = formik.values.transactionStatus
    let walletCd = formik.values.walletCd
    let dateTimeFrom = formik.values.dateTimeFrom
    let dateTimeTo = formik.values.dateTimeTo

    axios
      .post(
        `${SISPAY_API}/merchant/portal/transactionhistory`,
        {
          request: {
            session: session,
            fromDate: dateTimeFrom,
            toDate: dateTimeTo,
            transType: transactionType,
            merchantCd: merchantCdStored,
            action: 'list', //list or download
            page: currentPage, // required only when action = list

            ...(transactionStatus && {byStatus: transactionStatus}),
            ...(searchTransaction && {byTransaction: searchTransaction}),
            ...(searchCustomer && {byCustomer: searchCustomer}),
            ...(searchAmount && {byAmount: searchAmount}),
            ...(walletCd && {byWalletCd: walletCd}),
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let formData = response.data.result
        setFormData(sortByDate(formData.merchantTransHistory, 'receivedDT'))
        setTotalFormData(formData.totalCount)

        setGetData(false)
        setDisabled(false)
        setLoading(false)
      })
      .catch(function (error) {})
  }, [currentPage, getData])

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-2 py-1'>
          <div className='mb-10'>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='fv-row mb-5'>
                {' '}
                <div className='row'>
                  <div className='col'>
                    {/* begin::Form group*/}
                    <div className='fv-row mb-5'>
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Search Transaction
                        </label>
                        {Overlay('Search Submitted Transaction ID, Transaction ID, Deposit ID')}
                      </div>
                      <input
                        placeholder='Search Submitted Transaction ID, Transaction ID, Deposit ID'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('searchTransaction')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid':
                              formik.touched.searchTransaction && formik.errors.searchTransaction,
                          },
                          {
                            'is-valid':
                              formik.touched.searchTransaction && !formik.errors.searchTransaction,
                          }
                        )}
                      />
                      {formik.touched.searchTransaction && formik.errors.searchTransaction && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.searchTransaction}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group*/}
                    <div className='fv-row mb-5'>
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Search Customer
                        </label>
                        {Overlay('Search Customer Code, Account No')}
                      </div>
                      <input
                        placeholder='Search Customer Code, Account No'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('searchCustomer')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid':
                              formik.touched.searchCustomer && formik.errors.searchCustomer,
                          },
                          {
                            'is-valid':
                              formik.touched.searchCustomer && !formik.errors.searchCustomer,
                          }
                        )}
                      />
                      {formik.touched.searchCustomer && formik.errors.searchCustomer && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.searchCustomer}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group*/}
                    <div className='fv-row mb-5'>
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>Search Amount</label>
                        {Overlay('SEARCH Amount')}
                      </div>
                      <input
                        placeholder='SEARCH Amount'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('searchAmount')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.searchAmount && formik.errors.searchAmount,
                          },
                          {
                            'is-valid': formik.touched.searchAmount && !formik.errors.searchAmount,
                          }
                        )}
                      />
                      {formik.touched.searchAmount && formik.errors.searchAmount && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.searchAmount}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='row'>
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Transaction Type
                        </label>

                        <div
                          style={{
                            color: 'black',
                          }}
                        >
                          <select
                            {...formik.getFieldProps('transactionType')}
                            className={clsx('form-control bg-transparent')}
                            data-placeholder=''
                          >
                            <option value='ALL'>ALL</option>
                            <option value='DEPOSIT'>DEPOSIT</option>
                            <option value='WITHDRAWAL'>WITHDRAWAL</option>
                          </select>{' '}
                        </div>
                      </div>{' '}
                    </div>
                    <div className='col'>
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Transaction Status
                        </label>

                        <div
                          style={{
                            color: 'black',
                          }}
                        >
                          <select
                            {...formik.getFieldProps('transactionStatus')}
                            className={clsx('form-control bg-transparent')}
                            data-placeholder=''
                          >
                            <option value=''>ALL</option>
                            <option value='PENDING'>PENDING</option>
                            <option value='APPROVED'>APPROVED</option>
                            <option value='REJECTED'>REJECTED</option>
                            <option value='CANCELLED'>CANCELLED</option>
                          </select>{' '}
                        </div>
                      </div>{' '}
                    </div>
                    {/* end::Form group */}
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Wallet Code</label>
                        <select
                          {...formik.getFieldProps('walletCd')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value=''>ALL</option>
                          <option value='NAGAD'>NAGAD</option>
                          <option value='ROCKET'>ROCKET</option>
                          <option value='BKASH'>BKASH</option>
                        </select>{' '}
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                  </div>
                </div>
                <div className='col'></div>
              </div>
              <div className='row'>
                <div className='col'>
                  {/* begin::Form group DateTime From */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>From</label>
                    <input
                      placeholder='Enter your start time'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('dateTimeFrom')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                        },
                        {
                          'is-valid': formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                        }
                      )}
                    />
                    {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateTimeFrom}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group DateTime To */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>To</label>
                    <input
                      placeholder='Enter your end time'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('dateTimeTo')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                        },
                        {
                          'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                        }
                      )}
                    />
                    {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateTimeTo}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                  {/* end::Form group */}
                  <div className='col'></div>
                  <div className='col'></div>
                  <div className='col'></div>
                </div>
                <div className='row'>
                  <div className='col'></div>
                  <div className='col'></div>
                  <div className='col'></div> <div className='col'></div>
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      //disabled={formik.isSubmitting}
                      onClick={() =>
                        formik.resetForm({
                          values: {
                            searchTransaction: '',
                            searchCustomer: '',
                            searchAmount: '',
                            transactionType: 'ALL',
                            transactionStatus: '',
                            walletCd: '',
                            dateTimeFrom: getCurrentDateInput(),
                            dateTimeTo: getCurrentDateInput(),
                          },
                        })
                      }
                    >
                      {<span className='indicator-label'>Reset</span>}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {Modal(loading)}
    </div>
  )
}
