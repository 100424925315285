import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import {getCurrentDateInput} from '../../components/GetCurrentDateInput'
import {sortByDate} from '../../components/SortByDate'
import Select from 'react-select'
import {customStyles} from '../../components/CustomStyles'
import {ErrorHandling} from '../../components/ErrorHandling'
import {useAuth} from '../../auth/core/Auth'
import {sortByName} from '../../components/SortByName'
import {UniqueWalletAccNo} from '../../components/UniqueWalletAccNo'
import Modal from '../../components/Modal'
import scrollToTop from '../../components/ScrollToTop'
import {Overlay} from '../../components/Overlay'
import {useSMSManagementStore} from './SmsManagemt_Store'

export function Filter(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const {logout} = useAuth()

  //Props
  let setFormData = props.setFormData
  let currentPage = props.currentPage
  let setCurrentPage = props.setCurrentPage
  let setTotalFormData = props.setTotalFormData
  let setFilterValues = props.setFilterValues
  let downloadLoading = props.downloadLoading

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [wallets, setWallets] = useState(Object)

  //Refs
  const selectedWalletAccNo = useRef<any>(null)

  //Zustand Store
  const filterOptions = useSMSManagementStore((state: any) => state.filterOptions)
  const getData = useSMSManagementStore((state: any) => state.getData)
  const setFilterOptions = useSMSManagementStore((state: any) => state.setFilterOptions)
  const setGetData = useSMSManagementStore((state: any) => state.setGetData)

  const initialValues = {
    search: filterOptions.search ? String(filterOptions.search) : '',
    txnId: filterOptions.txnId ? String(filterOptions.txnId) : '',
    walletAccNo: filterOptions.walletAccNo ? String(filterOptions.walletAccNo) : '',
    smsType: filterOptions.smsType ? String(filterOptions.smsType) : '',
    smsTemplate: filterOptions.smsTemplate ? String(filterOptions.smsTemplate) : '',
    dateTimeFrom: filterOptions.dateTimeFrom
      ? String(filterOptions.dateTimeFrom)
      : getCurrentDateInput(),
    dateTimeTo: filterOptions.dateTimeTo ? String(filterOptions.dateTimeTo) : getCurrentDateInput(),
  }

  const filterSchema = Yup.object().shape({
    serviceCenter: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setDisabled(true)
      let walletAccNo = values.walletAccNo.trim()
      let smsType: string = values.smsType.trim()
      let smsTemplate = values.smsTemplate.trim()
      let dateTimeFrom = values.dateTimeFrom.trim()
      let dateTimeTo = values.dateTimeTo.trim()
      let search = values.search.trim()
      let txnId = values.txnId.trim()

      setFilterValues(values)
      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/sms/listmessage`,
          {
            request: {
              action: 'list',
              fromDate: dateTimeFrom,
              toDate: dateTimeTo,
              session: session,
              page: '1',
              //Filter
              ...(search && {bySearch: search}),
              ...(txnId && {byTxnId: txnId}),
              ...(walletAccNo && {byWalletAccNo: walletAccNo}),
              ...(smsType && {bySmsType: smsType}),
              ...(smsTemplate && {bySmsTemplate: smsTemplate}),
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          let formData = response.data.result.smsList

          setFormData(sortByDate(formData, 'receivedDT'))
          setTotalFormData(response.data.result.totalCount)
          setDisabled(false)
          setLoading(false)

          if (currentPage !== 0) {
            setCurrentPage(1)
          }
        })
        .catch(function (error) {})
    },
  })

  useEffect(() => {
    if (getData == false && currentPage == 0) return

    if (getData == true) {
      setCurrentPage(1)
    }

    let walletAccNo = formik.values.walletAccNo.trim()
    let smsType: string = formik.values.smsType.trim()
    let smsTemplate = formik.values.smsTemplate.trim()
    let dateTimeFrom = formik.values.dateTimeFrom.trim()
    let dateTimeTo = formik.values.dateTimeTo.trim()
    let search = formik.values.search.trim()
    let txnId = formik.values.txnId.trim()

    setLoading(true)
    scrollToTop()

    axios
      .post(
        `${SISPAY_API}/sms/listmessage`,
        {
          request: {
            action: 'list',
            fromDate: dateTimeFrom,
            toDate: dateTimeTo,
            session: session,
            page: currentPage,
            //Filter
            ...(search && {bySearch: search}),
            ...(txnId && {byTxnId: txnId}),
            ...(walletAccNo && {byWalletAccNo: walletAccNo}),
            ...(smsType && {bySmsType: smsType}),
            ...(smsTemplate && {bySmsTemplate: smsTemplate}),
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        let formData = response.data.result.smsList

        setFormData(sortByDate(formData, 'receivedDT'))
        setGetData(false)
        setDisabled(false)
        setLoading(false)
      })
      .catch(function (error) {})
  }, [currentPage, getData])

  //ABSTRACT FUNCTION: Retrieve data from API
  function getWallets(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          // Extract walletAccNo and form a new array of JSON objects
          let walletAccNoList = UniqueWalletAccNo(result)

          setWallets(sortByName(walletAccNoList, 'label'))
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {})
  }

  //GET USER LIST
  useEffect(() => {
    getWallets(SISPAY_API, '/wallet/list', 'wallet')
  }, [])

  return (
    <>
      <div className='card card-custom card-flush mb-5'>
        <div className='menu d-flex flex-column'>
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          </div>

          <div className='separator border-gray-200'></div>

          <div className='px-2 py-1'>
            <div className='mb-10'>
              <form onSubmit={formik.handleSubmit} className='m-5'>
                {' '}
                <div className='fv-row mb-5'>
                  {' '}
                  <div className='row'>
                    <div className='col'>
                      {/* begin::Form group Search */}
                      <div className='fv-row mb-5'>
                        <div className='d-flex'>
                          <label className='form-label fw-bolder text-dark fs-6'>Search</label>
                          {Overlay('SEARCH Source, Service Center, Sender/Receiver')}
                        </div>
                        <input
                          placeholder='Enter Search'
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('search')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': formik.touched.search && formik.errors.search,
                            },
                            {
                              'is-valid': formik.touched.search && !formik.errors.search,
                            }
                          )}
                        />
                        {formik.touched.search && formik.errors.search && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.search}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group Search */}
                      <div className='fv-row mb-5'>
                        <div className='d-flex'>
                          <label className='form-label fw-bolder text-dark fs-6'>TxnId</label>
                          {/* {Overlay('SEARCH Source, Service Center, Sender/Receiver')} */}
                        </div>
                        <input
                          placeholder='Enter TxnId'
                          type='text'
                          autoComplete='off'
                          {...formik.getFieldProps('txnId')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': formik.touched.txnId && formik.errors.txnId,
                            },
                            {
                              'is-valid': formik.touched.txnId && !formik.errors.txnId,
                            }
                          )}
                        />
                        {formik.touched.txnId && formik.errors.txnId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.txnId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group Wallet Account Number */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          Wallet Account Number
                        </label>

                        <Select
                          ref={selectedWalletAccNo}
                          className='basic-single'
                          classNamePrefix='select'
                          isClearable={true}
                          name='walletAccNo'
                          options={wallets}
                          styles={customStyles}
                          value={
                            formik.values.walletAccNo
                              ? {value: formik.values.walletAccNo, label: formik.values.walletAccNo}
                              : null
                          }
                          onChange={(e: any) =>
                            e !== null
                              ? formik.setFieldValue('walletAccNo', e.value)
                              : formik.setFieldValue('walletAccNo', '')
                          }
                        />
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group SMS TYPE */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>SMS Type</label>
                        <select
                          {...formik.getFieldProps('smsType')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value=''>ALL</option>
                          <option value='JUNK'>JUNK</option>
                          <option value='DEPOSIT'>DEPOSIT</option>
                          <option value='WITHDRAWAL'>WITHDRAWAL</option>
                          <option value='B2B SEND'>B2B SEND</option>
                          <option value='B2B RECEIVE'>B2B RECEIVE</option>
                        </select>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      {/* begin::Form group SMS Template */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>SMS Template</label>
                        <select
                          {...formik.getFieldProps('smsTemplate')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value=''>ALL</option>
                          <option value='BKASH'>BKASH</option>
                          <option value='NAGAD'>NAGAD</option>
                          <option value='ROCKET'>ROCKET</option>
                        </select>
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group DateTime From */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>From</label>
                        <input
                          placeholder='Enter your start time'
                          type='date'
                          autoComplete='off'
                          {...formik.getFieldProps('dateTimeFrom')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid':
                                formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                            },
                            {
                              'is-valid':
                                formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                            }
                          )}
                        />
                        {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.dateTimeFrom}</span>
                            </div>
                          </div>
                        )}
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                    <div className='col'>
                      {/* begin::Form group DateTime To */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>To</label>
                        <input
                          placeholder='Enter your end time'
                          type='date'
                          autoComplete='off'
                          {...formik.getFieldProps('dateTimeTo')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                            },
                            {
                              'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                            }
                          )}
                        />
                        {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.dateTimeTo}</span>
                            </div>
                          </div>
                        )}
                      </div>{' '}
                      {/* end::Form group */}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'></div>
                  <div className='col'></div>
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='text-center'>
                      <button
                        className='btn btn-lg btn-light w-100 mb-5'
                        disabled={disabled || downloadLoading}
                        onClick={() => {
                          selectedWalletAccNo.current.setValue('')
                          setCurrentPage(1)
                          formik.resetForm({
                            values: {
                              walletAccNo: '',
                              dateTimeFrom: getCurrentDateInput(),
                              dateTimeTo: getCurrentDateInput(),
                              smsType: '',
                              smsTemplate: '',
                              txnId: '',
                              search: '',
                            },
                          })
                        }}
                      >
                        {<span className='indicator-label'>Reset</span>}
                      </button>
                    </div>
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='text-center'>
                      <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={
                          formik.isSubmitting || !formik.isValid || disabled || downloadLoading
                        }
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {/* end::Form group */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {Modal(loading)}
    </>
  )
}
