export function pushAllRow(selectedArray: any, formData: any, setSelectedArray: any) {
  if (selectedArray.length == 0 || selectedArray.length < formData.length) {
    const walletAccountIds: any[] = formData.map((item: {id: any; depositStatus: any}) => {
      return {
        id: item.id,
        status: item.depositStatus,
        //selected: true
      }
    })

    setSelectedArray(walletAccountIds)
  } else {
    setSelectedArray([])
  }
}
