export const ColumnFilter = ({column}: any) => {
  const {filterValue, setFilter} = column
  return (
    <span className='w-100'>
      <input
        className='w-100'
        value={filterValue || ''}
        onChange={(e) => setFilter(e.target.value)}
      />
    </span>
  )
}
