import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import Select from 'react-select'
import {useAuth} from '../../../../auth'
import {customStyles} from '../../../../components/CustomStyles'
import {ErrorHandling} from '../../../../components/ErrorHandling'
import {getCurrentDateInput} from '../../../../components/GetCurrentDateInput'
import {sortByDate} from '../../../../components/SortByDate'

export function Detailed_Filter(props: any) {
  const {currentUser, logout} = useAuth()
  const merchantCd: any = localStorage.getItem('merchantCd')
  let setFormData = props.setFormData
  let setSelectedArray = props.setSelectedArray
  let setStartDate = props.setStartDate
  let setEndDate = props.setEndDate
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const [loading, setLoading] = useState(false)
  const [merchantCdList, setMerchantCdList] = useState(Object)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const selectedMerchantCd = useRef<any>(null)

  const initialValues = {
    merchantCd: merchantCd,
    timeZone: 'GMT+6',
    basedOn: 'transCompleted',
    transType: 'ALL',
    dateTimeFrom: getCurrentDateInput(),
    dateTimeTo: getCurrentDateInput(),
  }

  const filterSchema = Yup.object().shape({
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const keysSearchsearchCustomer = ['bankName', 'AccHolderName', 'AccNo']
  //const keysSearchTransaction = ['merchantTxnId']

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      let merchantCd = values.merchantCd
      let timeZone = values.timeZone
      let basedOn = values.basedOn
      let transType = values.transType
      let dateTimeFrom = values.dateTimeFrom
      let dateTimeTo = values.dateTimeTo

      setStartDate(dateTimeFrom)
      setEndDate(dateTimeTo)

      let submissionData = {}

      if (values.merchantCd !== '') {
        submissionData = {
          session: session,
          merchantCd: merchantCd,
          timezone: timeZone,
          basedOn: basedOn,
          transType: transType,
          fromDate: dateTimeFrom,
          toDate: dateTimeTo,
        }
      }

      axios
        .post(
          `${SISPAY_API}/merchant/portal/detailedreport`,
          {
            request: submissionData,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 200) {
            setFormData(sortByDate(response.data.result.merchantDetailedReport, 'completedDT'))
            setLoading(false)
          } else {
            ErrorHandling(response, logout)
          }
        })
        .catch(function (error) {})
    },
  })

  //ABSTRACT FUNCTION: Retrieve data from API
  function retrieveData(SISPAY_API: string, url: string, location: string) {
    let result
    axios
      .post(
        `${SISPAY_API}${url}`,
        {
          request: {
            session: session,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result[`${location}`]

          // Extract merchantCd and form a new array of JSON objects
          let merchantCdList: any = Array.from(
            new Set(result.map((request: any) => request.merchantCd))
          )
            .filter((merchantCd) => merchantCd !== '')
            .map((request) => {
              return {value: request, label: request}
            })
          setMerchantCdList(merchantCdList)
        } else {
          ErrorHandling(response, logout)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  //GET USER LIST
  useEffect(() => {
    //retrieveData(SISPAY_API, '/merchant/list', 'merchant')
  }, [])

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-2 py-1'>
          <div className='mb-10'>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='fv-row mb-5'>
                {' '}
                <div className='row'>
                  {/* <div className='col'>
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Merchant Code</label>

                      <div>
                        <Select
                          ref={selectedMerchantCd}
                          className='basic-single'
                          classNamePrefix='select'
                          options={merchantCdList}
                          styles={customStyles}
                          name='merchantCd'
                          onChange={(e: any) => formik.setFieldValue('merchantCd', e.value)}
                        />
                      </div>
                    </div>{' '}
                  </div> */}
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Timezone</label>
                      <select
                        {...formik.getFieldProps('timeZone')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        <option value='GMT+6'>GMT+6</option>
                        <option value='GMT+7'>GMT+7</option>
                        <option value='GMT+8'>GMT+8</option>
                      </select>{' '}
                    </div>{' '}
                  </div>
                  {/* end::Form group */}
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Based On</label>
                      <select
                        {...formik.getFieldProps('basedOn')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        {/* <option value='transCreated'>Transaction Created</option> */}
                        <option value='transCompleted'>Transaction Completed</option>
                        <option value='transCreated'>Transaction Created</option>
                      </select>{' '}
                    </div>{' '}
                    {/* end::Form group */}
                  </div>
                  <div className='col'>
                    {/* begin::Form group */}
                    <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>Report Type</label>
                      <select
                        {...formik.getFieldProps('transType')}
                        className={clsx('form-control bg-transparent')}
                        data-placeholder=''
                      >
                        <option value='ALL'>All</option>
                        <option value='WITHDRAWAL'>Withdrawal</option>
                        <option value='DEPOSIT'>Deposit</option>
                      </select>{' '}
                    </div>{' '}
                  </div>
                </div>
                <div className='col'></div>
              </div>
              <div className='row'>
                <div className='col'>
                  {/* begin::Form group DateTime From */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>From</label>
                    <input
                      placeholder='Enter your start time'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('dateTimeFrom')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                        },
                        {
                          'is-valid': formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                        }
                      )}
                    />
                    {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateTimeFrom}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group DateTime To */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>To</label>
                    <input
                      placeholder='Enter your end time'
                      type='date'
                      autoComplete='off'
                      {...formik.getFieldProps('dateTimeTo')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                        },
                        {
                          'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                        }
                      )}
                    />
                    {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.dateTimeTo}</span>
                        </div>
                      </div>
                    )}
                  </div>{' '}
                  {/* end::Form group */}
                  <div className='col'></div>
                  <div className='col'>
                    {/* begin::Form group */}
                    {/* <div className='fv-row mb-5'>
                      <label className='form-label fw-bolder text-dark fs-6'>
                        Wallet Account No
                      </label>

                      <div
                        style={{
                          color: 'black',
                        }}
                      >
                        <Select
                          className='basic-single'
                          classNamePrefix='select'
                          name='walletAccNo'
                          options={walletAccNoList}
                          styles={customStyles}
                          onChange={(e: any) => formik.setFieldValue('walletAccNo', e.value)}
                        />
                      </div>
                    </div>{' '} */}
                    {/* end::Form group */}
                  </div>
                  <div className='col'></div>
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      //disabled={formik.isSubmitting}
                      onClick={() => {
                        selectedMerchantCd.current.setValue('')
                        formik.resetForm()
                      }}
                    >
                      {<span className='indicator-label'>Reset</span>}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
