import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

export function AgentSummary_ExportPDF(formData: any, startDate: string, endDate: string) {
  // Register the fonts with pdfMake
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  const documentDefinition = {
    pageOrientation: 'landscape' as 'landscape', // Cast the pageOrientation value explicitly
    pageSize: 'A2' as 'A2',
    content: [
      {text: `Exported Data ${startDate} - ${endDate}`, style: 'header'},
      '\n', // Add a line break
      {
        table: {
          //widths: ['*', '*', '*'], // Adjust column widths as needed
          body: [
            [
              'Agent Nickname',
              'Agent Username',
              'Total Credit',
              'Holding Deposit',
              'Available Credit',
              'Total Cash-Out (Deposit) Count',
              'Total Cash-Out (Deposit) Amount',
              'Total Cash-In (Withdrawal) Count',
              'Total Cash-In (Withdrawal) Amount',
              'Total Cash-Out (Deposit) Commission',
              'Total Cash-In (Withdrawal) Commission',
              'Pending Withdrawal Commission',
              'Available Commission',
              'NAGAD Cash-Out (Deposit) Amount',
              'NAGAD Cash-In (Withdrawal) Amount',
              'BKASH Cash-Out (Deposit) Amount',
              'BKASH Cash-In (Withdrawal) Amount',
              'Rocket Cash-Out (Deposit) Amount',
              'ROCKET Cash-In (Withdrawal) Amount',
            ], // Add headers as a separate row
            ...formData.map((item: any) => [
              item.agentNickname || 'N/A',
              item.agentUsername || 'N/A',
              item.agentCredit,
              item.earmarkDepositCredit || 'N/A',
              item.agentAvailableCredit,
              item.depositCount,
              item.depositAmount,
              item.withdrawalCount,
              item.withdrawalAmount,
              item.depositComm,
              item.withdrawalComm,
              item.agentPendingWithdrawalComm,
              item.agentAvailableComm,
              item.nagadDepositAmount,
              item.nagadWithdrawalAmount,
              item.bkashDepositAmount,
              item.bkashWithdrawalAmount,
              item.rocketDepositAmount,
              item.rocketWithdrawalAmount,
            ]),
          ],
        },
        layout: 'lightHorizontalLines', // Add a layout to handle long tables
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
    },
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
  pdfDocGenerator.download(`Exported Data ${startDate} - ${endDate}.pdf`)
}
