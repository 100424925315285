import {create} from 'zustand'

export const usePreviousDayAgentSummaryStore = create((set) => ({
  //Data
  getData: false,
  filterOptions: {
    username: '',
    queryDate: '',
  },

  //Functions
  setGetData: (value: boolean) => set({getData: value}),
  setFilterOptions: (value: any) => set({filterOptions: value}),
}))
