import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

export function WalletActivity_ExportPDF(formData: any, startDate: string, endDate: string) {
  // Register the fonts with pdfMake
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  const documentDefinition = {
    pageOrientation: 'landscape' as 'landscape', // Cast the pageOrientation value explicitly
    pageSize: 'A0' as 'A0',
    content: [
      {text: `Exported Data ${startDate} - ${endDate}`, style: 'header'},
      '\n', // Add a line break
      {
        table: {
          //widths: ['*', '*', '*'], // Adjust column widths as needed
          body: [
            [
              'ID',
              'Wallet Account Name',
              'Wallet Account Number',
              'Wallet Code',
              'Alive',
              'Last SMS Received',
              'Last Acknowledged',
              'Agent Name',
              'Merchant Name',
            ], // Add headers as a separate row
            ...formData.map((item: any) => [
              item.id,
              item.walletAccName,
              item.walletAccNo,
              item.walletCd,
              item.alive,
              item.lastSmsRecv,
              item.lastAck,
              item.agentName,
              item.merchantName,
            ]),
          ],
        },
        layout: 'lightHorizontalLines', // Add a layout to handle long tables
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
    },
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
  pdfDocGenerator.download(`Exported Data ${startDate} - ${endDate}.pdf`)
}
