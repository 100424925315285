import axios from 'axios'

export default function CommissionWithdrawal_BulkApprovalModal(props: any) {
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  let selectedArray: any = props.selectedArray
  let setSelectedArray: any = props.setSelectedArray
  let commWithdrawalFilterBtn: any = props.commWithdrawalFilterBtn

  function isCommissionWithdrawal() {
    var pattern = /^\/commission-withdrawal\/\d+$/
    var url = window.location.pathname // Get the current URL path

    return pattern.test(url)
  }

  function eraseText() {
    const element: any = document.getElementById('cwRemark')
    if (element) {
      element.value = ''
    }
  }

  function filterPotentialDeposit(selectedArray: any) {
    if (selectedArray.length > 0) {
      let filteredArray: any = selectedArray.filter((data: any) => {
        return data.status == 'PENDING'
      })
      return filteredArray
    } else {
      return []
    }
  }

  function filterNonPotentialDeposit(selectedArray: any) {
    if (selectedArray.length > 0) {
      let filteredArray: any = selectedArray.filter((data: any) => {
        return data.status !== 'PENDING'
      })
      return filteredArray
    } else {
      return []
    }
  }

  async function bulkApproval(selectedArray: any) {
    let remark: string = (document.getElementById('cwRemark') as HTMLInputElement)?.value
    let successArray: any = []
    let failedArray: any = []

    const requests = selectedArray.map((item: any) => {
      return axios
        .post(`${SISPAY_API}/agent/commrequest/approve`, {
          request: {
            id: item.id,
            statUpdateRemark: remark,
            session: session,
          },
        })
        .then((response: any) => {
          if (response.data.code === '200') {
            successArray.push(item.id)
          } else {
            failedArray.push(item.id)
          }
        })
        .catch((error: any) => {})
    })

    await Promise.all(requests)

    if (isCommissionWithdrawal() !== true) {
      commWithdrawalFilterBtn.current.click()
      eraseText()
    }

    alert(
      `Bulk Approval Successful: ${
        successArray.length > 0 ? successArray : 'No Successful Record'
      }\nBulk Approval Failed: ${failedArray.length > 0 ? failedArray : 'No Failed Record'}`
    )
  }

  return (
    <div
      className='modal fade'
      id='kt_modal_cw_bulk_approval'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex={-1}
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              Bulk Approval
            </h1>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body d-flex flex-column'>
            <h5 className='mb-3'>Commission requests that could be approved:</h5>
            <div className='d-flex flex-wrap mb-5 '>
              {filterPotentialDeposit(selectedArray).length > 0
                ? filterPotentialDeposit(selectedArray).map((data: any, index: number) => {
                    return (
                      <div
                        className='d-flex d-flex-column me-5 mb-5'
                        style={{
                          width: 'auto',
                        }}
                      >
                        <div
                          style={{
                            width: 'auto',
                            backgroundColor: 'green',
                            padding: '0 10px 0 10px',
                            color: 'white',
                            textAlign: 'center',
                            borderRadius: '5px',
                          }}
                        >
                          {' '}
                          {data.id} | {data.status}
                        </div>
                      </div>
                    )
                  })
                : 'No Comission Record Selected.'}
            </div>
            <div className='separator border-5 mb-5'></div>
            <h5 className='mb-3'>Commission requests that could not be approved:</h5>
            <div className='d-flex flex-wrap mb-5'>
              {filterNonPotentialDeposit(selectedArray).length > 0
                ? filterNonPotentialDeposit(selectedArray).map((data: any, index: number) => {
                    return (
                      <div
                        className='d-flex d-flex-column me-5 mb-5'
                        style={{
                          width: 'auto',
                        }}
                      >
                        <div
                          style={{
                            width: 'auto',
                            backgroundColor: 'red',
                            padding: '0 10px 0 10px',
                            color: 'white',
                            textAlign: 'center',
                            borderRadius: '5px',
                          }}
                        >
                          {' '}
                          {data.id} | {data.status}
                        </div>
                      </div>
                    )
                  })
                : 'No Comission Record Selected.'}
            </div>
            <div className='separator border-5 mb-5'></div>
            <div>
              <h5>Remark:</h5>
              <textarea
                name='cwRemark'
                id='cwRemark'
                className='w-100'
                rows={3}
                style={{resize: 'none'}}
              ></textarea>
              <p>*The remark will be applied to all selected comission requests.</p>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
              Close
            </button>
            {selectedArray.length > 0 && (
              <button
                type='button'
                className='btn btn-success'
                data-bs-dismiss='modal'
                onClick={() => {
                  bulkApproval(filterPotentialDeposit(selectedArray))
                  setSelectedArray([])
                }}
              >
                Bulk Approval
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
